import Vue from 'vue'
import App from './page/App.vue'
import veform from '@verified/veform'
import { validateUppercase} from './validations'
import Financial from './components/financial'
import Salary from './components/salary'
import Accounting from './components/accounting'
import PreviewAccounting from './components/previewaccounting'
import PreviewFinancial from './components/previewfinancial'
import PreviewSalary from './components/previewsalary'
import Preview from './components/preview'
import PreviewBilaga from './components/previewBilaga'
import PreviewAttachment from './components/previewAttachment'

Vue.config.productionTip = false

import "./page/style.scss"

veform.addCustomValidation('uppercase', validateUppercase)

Vue.use(veform)
Vue.use(Financial)
Vue.use(Salary)
Vue.use(Accounting)
Vue.use(Preview)
Vue.use(PreviewAccounting)
Vue.use(PreviewFinancial)
Vue.use(PreviewSalary)
Vue.use(PreviewBilaga)
Vue.use(PreviewAttachment)

new Vue({
    render: h => h(App),
}).$mount('#app')