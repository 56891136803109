<template>
    <div>
        <div class="card border-0 shadow-sm mt-4">
                      <div class="card-body pb-0">
                        <div class="row">
                          <ve-col xs12 sm2>
                            <ve-form-group>
                              <ve-label class="bold" :tip="{text:'verifyDetails'}">{{ __('moment') }}</ve-label>
                            </ve-form-group>
                          </ve-col>
                          <ve-col xs12 sm2>
                            <ve-form-group>
                              <ve-label class="bold" :tip="{text:'frequencyInfo'}">{{ __('frequency') }}</ve-label>
                            </ve-form-group>
                          </ve-col>
                          <ve-col xs12 sm2>
                            <ve-form-group>
                              <ve-label class="bold" :tip="{text:'stateResponsible'}">{{ __('responsibilities') }}</ve-label>
                            </ve-form-group>
                          </ve-col>
                          <ve-col xs12 sm2>
                            <ve-form-group>
                              <ve-label class="bold" :tip="{text:'deliveryCommitmentInfo2'}">{{ __('deliveryCommitment') }}</ve-label>
                            </ve-form-group>
                          </ve-col>
                          <ve-col xs12 sm2>
                            <ve-form-group>
                              <ve-label class="bold" :tip="{text:'estimateAmountInfo'}">{{ __('amount') }}</ve-label>
                            </ve-form-group>
                          </ve-col>
                          <ve-col xs12 sm2>
                            <ve-form-group>
                              <ve-label class="bold">{{ __('comments') }}</ve-label>
                            </ve-form-group>
                          </ve-col>
                        </div>
                      </div>
                    </div>
                      <h4 class="mt-1">{{__('tab3Sec1Head')}}</h4>
                      <div class="card border-0 shadow-sm">
                        <div class="card-body pb-0">
                          <div class="row">
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-label>{{ __('tab3Sec1Op1') }}</ve-label>
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                  <ve-select
                                  :searchable="false"
                                  :placeholder="__('empty')"
                                  v-if="frequency"
                                  @on-select="function(option){ud.t3.s1r1.frequency = option.value}"
                                  :options="frequency"
                                  :readonly="true"
                                />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-select
                                  :searchable="false"
                                  :placeholder="__('empty')"
                                  v-if="responsible"
                                  @on-select="function(option){ud.t3.s1r1.responsible = option.value}"
                                  :options="responsible"
                                  :readonly="true"
                                />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-input 
                                  v-model="ud.t3.s1r1.delivery"
                                  :placeholder="__('deliveryDates')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                <ve-input
                                  v-model="ud.t3.s1r1.amount"
                                  type="number"                                   
                                  :placeholder="__('amount')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                <ve-input
                                  v-model="ud.t3.s1r1.comments"
                                  :placeholder="__('comments')"
                                  />
                              </ve-form-group>
                            </ve-col>
                          </div>
                          <div class="row">
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-label>{{ __('tab3Sec1Op2') }}</ve-label>
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                  <ve-select
                                  :searchable="false"
                                  :placeholder="__('empty')"
                                  v-if="frequency"
                                  @on-select="function(option){ud.t3.s1r2.frequency = option.value}"
                                  :options="frequency"
                                  :readonly="true"
                                />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-select
                                  :searchable="false"
                                  :placeholder="__('empty')"
                                  v-if="responsible"
                                  @on-select="function(option){ud.t3.s1r2.responsible = option.value}"
                                  :options="responsible"
                                  :readonly="true"
                                />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-input 
                                  v-model="ud.t3.s1r2.delivery"
                                  :placeholder="__('deliveryDates')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                <ve-input
                                  v-model="ud.t3.s1r2.amount"
                                  type="number"                                   
                                  :placeholder="__('amount')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                <ve-input
                                  v-model="ud.t3.s1r2.comments"
                                  :placeholder="__('comments')"
                                  />
                              </ve-form-group>
                            </ve-col>
                          </div>
                          <div class="row">
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-label>{{ __('tab3Sec1Op3') }}</ve-label>
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                  <ve-select
                                  :searchable="false"
                                  :placeholder="__('empty')"
                                  v-if="frequency"
                                  @on-select="function(option){ud.t3.s1r3.frequency = option.value}"
                                  :options="frequency"
                                  :readonly="true"
                                />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-select
                                  :searchable="false"
                                  :placeholder="__('empty')"
                                  v-if="responsible"
                                  @on-select="function(option){ud.t3.s1r3.responsible = option.value}"
                                  :options="responsible"
                                  :readonly="true"
                                />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-input 
                                  v-model="ud.t3.s1r3.delivery"
                                  :placeholder="__('deliveryDates')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                <ve-input
                                  v-model="ud.t3.s1r3.amount"
                                  type="number"                                   
                                  :placeholder="__('amount')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                <ve-input
                                  v-model="ud.t3.s1r3.comments"
                                  :placeholder="__('comments')"
                                  />
                              </ve-form-group>
                            </ve-col>
                          </div>
                          <div class="row">
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-label>{{ __('tab3Sec1Op4') }}</ve-label>
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                  <ve-select
                                  :searchable="false"
                                  :placeholder="__('empty')"
                                  v-if="frequency"
                                  @on-select="function(option){ud.t3.s1r4.frequency = option.value}"
                                  :options="frequency"
                                  :readonly="true"
                                />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-select
                                  :searchable="false"
                                  :placeholder="__('empty')"
                                  v-if="responsible"
                                  @on-select="function(option){ud.t3.s1r4.responsible = option.value}"
                                  :options="responsible"
                                  :readonly="true"
                                />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-input 
                                  v-model="ud.t3.s1r4.delivery"
                                  :placeholder="__('deliveryDates')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                <ve-input
                                  v-model="ud.t3.s1r4.amount"
                                  type="number"                                   
                                  :placeholder="__('amount')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                <ve-input
                                  v-model="ud.t3.s1r4.comments"
                                  :placeholder="__('comments')"
                                  />
                              </ve-form-group>
                            </ve-col>
                          </div>
                          <div class="row">
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-label>{{ __('tab3Sec1Op5') }}</ve-label>
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                  <ve-select
                                  :searchable="false"
                                  :placeholder="__('empty')"
                                  v-if="frequency"
                                  @on-select="function(option){ud.t3.s1r5.frequency = option.value}"
                                  :options="frequency"
                                  :readonly="true"
                                />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-select
                                  :searchable="false"
                                  :placeholder="__('empty')"
                                  v-if="responsible"
                                  @on-select="function(option){ud.t3.s1r5.responsible = option.value}"
                                  :options="responsible"
                                  :readonly="true"
                                />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-input 
                                  v-model="ud.t3.s1r5.delivery"
                                  :placeholder="__('deliveryDates')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                <ve-input
                                  v-model="ud.t3.s1r5.amount"
                                  type="number"                                   
                                  :placeholder="__('amount')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                <ve-input
                                  v-model="ud.t3.s1r5.comments"
                                  :placeholder="__('comments')"
                                  />
                              </ve-form-group>
                            </ve-col>
                          </div>
                          <div class="row" v-for="(s3Ot, index) in ud.t3.s1Other" v-bind:key="index">
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-input 
                                  v-model="s3Ot.moment"
                                  :placeholder="__('otherSpecify')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                  <ve-select
                                  :searchable="false"
                                  :placeholder="__('empty')"
                                  v-if="frequency"
                                  @on-select="function(option){s3Ot.frequency = option.value}"
                                  :options="frequency"
                                  :readonly="true"
                                />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-select
                                  :searchable="false"
                                  :placeholder="__('empty')"
                                  v-if="responsible"
                                  @on-select="function(option){s3Ot.responsible = option.value}"
                                  :options="responsible"
                                  :readonly="true"
                                />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-input 
                                  v-model="s3Ot.delivery"
                                  :placeholder="__('deliveryDates')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                <ve-input
                                  v-model="s3Ot.amount"
                                  type="number"
                                  :placeholder="__('amount')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                <ve-input
                                  v-model="s3Ot.comments"
                                  :placeholder="__('comments')"
                                  />
                              </ve-form-group>
                            </ve-col>
                          </div>
                          <ve-col md6 xs12 >
                            <div  class="" style="padding:20px 40%;">
                              <div @click="removeOther('t3','s1Other')" class="btn-minus1">-</div>
                              <div @click="addOther('t3','s1Other')" class="btn-plus1">+</div>
                            </div>
                          </ve-col>
                        </div>
                      </div>
                      <h5 class="mt-3">{{__('tab3Sec2Head')}}</h5>
                      <div class="card border-0 shadow-sm">
                        <div class="card-body pb-0">
                          <div class="row">
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-label>{{ __('tab3Sec2Op1') }}</ve-label>
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                  <ve-select
                                  :searchable="false"
                                  :placeholder="__('empty')"
                                  v-if="frequency"
                                  @on-select="function(option){ud.t3.s2r1.frequency = option.value}"
                                  :options="frequency"
                                  :readonly="true"
                                />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-select
                                  :searchable="false"
                                  :placeholder="__('empty')"
                                  v-if="responsible"
                                  @on-select="function(option){ud.t3.s2r1.responsible = option.value}"
                                  :options="responsible"
                                  :readonly="true"
                                />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-input 
                                  v-model="ud.t3.s2r1.delivery"
                                  :placeholder="__('deliveryDates')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                <ve-input
                                  v-model="ud.t3.s2r1.amount"
                                  type="number"                                   
                                  :placeholder="__('amount')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                <ve-input
                                  v-model="ud.t3.s2r1.comments"
                                  :placeholder="__('comments')"
                                  />
                              </ve-form-group>
                            </ve-col>
                          </div>
                           <div class="row">
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-label>{{ __('tab3Sec2Op2') }}</ve-label>
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                  <ve-select
                                  :searchable="false"
                                  :placeholder="__('empty')"
                                  v-if="frequency"
                                  @on-select="function(option){ud.t3.s2r2.frequency = option.value}"
                                  :options="frequency"
                                  :readonly="true"
                                />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-select
                                  :searchable="false"
                                  :placeholder="__('empty')"
                                  v-if="responsible"
                                  @on-select="function(option){ud.t3.s2r2.responsible = option.value}"
                                  :options="responsible"
                                  :readonly="true"
                                />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-input 
                                  v-model="ud.t3.s2r2.delivery"
                                  :placeholder="__('deliveryDates')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                <ve-input
                                  v-model="ud.t3.s2r2.amount"
                                  type="number"                                   
                                  :placeholder="__('amount')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                <ve-input
                                  v-model="ud.t3.s2r2.comments"
                                  :placeholder="__('comments')"
                                  />
                              </ve-form-group>
                            </ve-col>
                          </div>
                           <div class="row">
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-label>{{ __('tab3Sec2Op3') }}</ve-label>
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                  <ve-select
                                  :searchable="false"
                                  :placeholder="__('empty')"
                                  v-if="frequency"
                                  @on-select="function(option){ud.t3.s2r3.frequency = option.value}"
                                  :options="frequency"
                                  :readonly="true"
                                />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-select
                                  :searchable="false"
                                  :placeholder="__('empty')"
                                  v-if="responsible"
                                  @on-select="function(option){ud.t3.s2r3.responsible = option.value}"
                                  :options="responsible"
                                  :readonly="true"
                                />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-input 
                                  v-model="ud.t3.s2r3.delivery"
                                  :placeholder="__('deliveryDates')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                <ve-input
                                  v-model="ud.t3.s2r3.amount"
                                  type="number"                                   
                                  :placeholder="__('amount')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                <ve-input
                                  v-model="ud.t3.s2r3.comments"
                                  :placeholder="__('comments')"
                                  />
                              </ve-form-group>
                            </ve-col>
                          </div>
                           <div class="row" v-for="(s2Ot, index) in ud.t3.s2Other" v-bind:key="index">
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-input 
                                  v-model="s2Ot.moment"
                                  :placeholder="__('otherSpecify')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                  <ve-select
                                  :searchable="false"
                                  :placeholder="__('empty')"
                                  v-if="frequency"
                                  @on-select="function(option){s2Ot.frequency = option.value}"
                                  :options="frequency"
                                  :readonly="true"
                                />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-select
                                  :searchable="false"
                                  :placeholder="__('empty')"
                                  v-if="responsible"
                                  @on-select="function(option){s2Ot.responsible = option.value}"
                                  :options="responsible"
                                  :readonly="true"
                                />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-input 
                                  v-model="s2Ot.delivery"
                                  :placeholder="__('deliveryDates')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                <ve-input
                                  v-model="s2Ot.amount"
                                  type="number"                                   
                                  :placeholder="__('amount')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                <ve-input
                                  v-model="s2Ot.comments"
                                  :placeholder="__('comments')"
                                  />
                              </ve-form-group>
                            </ve-col>
                          </div>
                          <ve-col md6 xs12 >
                            <div  class="" style="padding:20px 40%;">
                              <div @click="removeOther('t3','s2Other')" class="btn-minus1">-</div>
                              <div @click="addOther('t3','s2Other')" class="btn-plus1">+</div>
                            </div>
                          </ve-col>

                        </div>
                      </div>
                      <h5 class="mt-3">{{__('tab3Sec3Head')}}</h5>
                      <div class="card border-0 shadow-sm">
                        <div class="card-body pb-0">
                           <div class="row">
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-label>{{ __('tab3Sec3Op1') }}</ve-label>
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                  <ve-select
                                  :searchable="false"
                                  :placeholder="__('empty')"
                                  v-if="frequency"
                                  @on-select="function(option){ud.t3.s3r1.frequency = option.value}"
                                  :options="frequency"
                                  :readonly="true"
                                />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-select
                                  :searchable="false"
                                  :placeholder="__('empty')"
                                  v-if="responsible"
                                  @on-select="function(option){ud.t3.s3r1.responsible = option.value}"
                                  :options="responsible"
                                  :readonly="true"
                                />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-input 
                                  v-model="ud.t3.s3r1.delivery"
                                  :placeholder="__('deliveryDates')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                <ve-input
                                  v-model="ud.t3.s3r1.amount"
                                  type="number"                                   
                                  :placeholder="__('amount')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                <ve-input
                                  v-model="ud.t3.s3r1.comments"
                                  :placeholder="__('comments')"
                                  />
                              </ve-form-group>
                            </ve-col>
                          </div>
                          <div class="row">
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-label>{{ __('tab3Sec3Op2') }}</ve-label>
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                  <ve-select
                                  :searchable="false"
                                  :placeholder="__('empty')"
                                  v-if="frequency"
                                  @on-select="function(option){ud.t3.s3r2.frequency = option.value}"
                                  :options="frequency"
                                  :readonly="true"
                                />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-select
                                  :searchable="false"
                                  :placeholder="__('empty')"
                                  v-if="responsible"
                                  @on-select="function(option){ud.t3.s3r2.responsible = option.value}"
                                  :options="responsible"
                                  :readonly="true"
                                />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-input 
                                  v-model="ud.t3.s3r2.delivery"
                                  :placeholder="__('deliveryDates')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                <ve-input
                                  v-model="ud.t3.s3r2.amount"
                                  type="number"                                   
                                  :placeholder="__('amount')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                <ve-input
                                  v-model="ud.t3.s3r2.comments"
                                  :placeholder="__('comments')"
                                  />
                              </ve-form-group>
                            </ve-col>
                          </div>
                          <div class="row">
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-label>{{ __('tab3Sec3Op3') }}</ve-label>
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                  <ve-select
                                  :searchable="false"
                                  :placeholder="__('empty')"
                                  v-if="frequency"
                                  @on-select="function(option){ud.t3.s3r3.frequency = option.value}"
                                  :options="frequency"
                                  :readonly="true"
                                />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-select
                                  :searchable="false"
                                  :placeholder="__('empty')"
                                  v-if="responsible"
                                  @on-select="function(option){ud.t3.s3r3.responsible = option.value}"
                                  :options="responsible"
                                  :readonly="true"
                                />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-input 
                                  v-model="ud.t3.s3r3.delivery"
                                  :placeholder="__('deliveryDates')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                <ve-input
                                  v-model="ud.t3.s3r3.amount"
                                  type="number"                                   
                                  :placeholder="__('amount')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                <ve-input
                                  v-model="ud.t3.s3r3.comments"
                                  :placeholder="__('comments')"
                                  />
                              </ve-form-group>
                            </ve-col>
                          </div>
                          <div class="row">
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-label>{{ __('tab3Sec3Op4') }}</ve-label>
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                  <ve-select
                                  :searchable="false"
                                  :placeholder="__('empty')"
                                  v-if="frequency"
                                  @on-select="function(option){ud.t3.s3r4.frequency = option.value}"
                                  :options="frequency"
                                  :readonly="true"
                                />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-select
                                  :searchable="false"
                                  :placeholder="__('empty')"
                                  v-if="responsible"
                                  @on-select="function(option){ud.t3.s3r4.responsible = option.value}"
                                  :options="responsible"
                                  :readonly="true"
                                />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-input 
                                  v-model="ud.t3.s3r4.delivery"
                                  :placeholder="__('deliveryDates')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                <ve-input
                                  v-model="ud.t3.s3r4.amount"
                                  type="number"                                   
                                  :placeholder="__('amount')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                <ve-input
                                  v-model="ud.t3.s3r4.comments"
                                  :placeholder="__('comments')"
                                  />
                              </ve-form-group>
                            </ve-col>
                          </div>
                          <div class="row">
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-label>{{ __('tab3Sec3Op5') }}</ve-label>
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                  <ve-select
                                  :searchable="false"
                                  :placeholder="__('empty')"
                                  v-if="frequency"
                                  @on-select="function(option){ud.t3.s3r5.frequency = option.value}"
                                  :options="frequency"
                                  :readonly="true"
                                />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-select
                                  :searchable="false"
                                  :placeholder="__('empty')"
                                  v-if="responsible"
                                  @on-select="function(option){ud.t3.s3r5.responsible = option.value}"
                                  :options="responsible"
                                  :readonly="true"
                                />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-input 
                                  v-model="ud.t3.s3r5.delivery"
                                  :placeholder="__('deliveryDates')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                <ve-input
                                  v-model="ud.t3.s3r5.amount"
                                  type="number"                                   
                                  :placeholder="__('amount')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                <ve-input
                                  v-model="ud.t3.s3r5.comments"
                                  :placeholder="__('comments')"
                                  />
                              </ve-form-group>
                            </ve-col>
                          </div>
                          <div class="row">
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-label>{{ __('tab3Sec3Op6') }}</ve-label>
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                  <ve-select
                                  :searchable="false"
                                  :placeholder="__('empty')"
                                  v-if="frequency"
                                  @on-select="function(option){ud.t3.s3r6.frequency = option.value}"
                                  :options="frequency"
                                  :readonly="true"
                                />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-select
                                  :searchable="false"
                                  :placeholder="__('empty')"
                                  v-if="responsible"
                                  @on-select="function(option){ud.t3.s3r6.responsible = option.value}"
                                  :options="responsible"
                                  :readonly="true"
                                />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-input 
                                  v-model="ud.t3.s3r6.delivery"
                                  :placeholder="__('deliveryDates')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                <ve-input
                                  v-model="ud.t3.s3r6.amount"
                                  type="number"                                   
                                  :placeholder="__('amount')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                <ve-input
                                  v-model="ud.t3.s3r6.comments"
                                  :placeholder="__('comments')"
                                  />
                              </ve-form-group>
                            </ve-col>
                          </div>
                          <div class="row">
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-label>{{ __('tab3Sec3Op7') }}</ve-label>
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                  <ve-select
                                  :searchable="false"
                                  :placeholder="__('empty')"
                                  v-if="frequency"
                                  @on-select="function(option){ud.t3.s3r7.frequency = option.value}"
                                  :options="frequency"
                                  :readonly="true"
                                />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-select
                                  :searchable="false"
                                  :placeholder="__('empty')"
                                  v-if="responsible"
                                  @on-select="function(option){ud.t3.s3r7.responsible = option.value}"
                                  :options="responsible"
                                  :readonly="true"
                                />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-input 
                                  v-model="ud.t3.s3r7.delivery"
                                  :placeholder="__('deliveryDates')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                <ve-input
                                  v-model="ud.t3.s3r7.amount"
                                  type="number"                                   
                                  :placeholder="__('amount')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                <ve-input
                                  v-model="ud.t3.s3r7.comments"
                                  :placeholder="__('comments')"
                                  />
                              </ve-form-group>
                            </ve-col>
                          </div>
                          <div class="row">
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-label>{{ __('tab3Sec3Op8') }}</ve-label>
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                  <ve-select
                                  :searchable="false"
                                  :placeholder="__('empty')"
                                  v-if="frequency"
                                  @on-select="function(option){ud.t3.s3r8.frequency = option.value}"
                                  :options="frequency"
                                  :readonly="true"
                                />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-select
                                  :searchable="false"
                                  :placeholder="__('empty')"
                                  v-if="responsible"
                                  @on-select="function(option){ud.t3.s3r8.responsible = option.value}"
                                  :options="responsible"
                                  :readonly="true"
                                />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-input 
                                  v-model="ud.t3.s3r8.delivery"
                                  :placeholder="__('deliveryDates')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                <ve-input
                                  v-model="ud.t3.s3r8.amount"
                                  type="number"                                   
                                  :placeholder="__('amount')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                <ve-input
                                  v-model="ud.t3.s3r8.comments"
                                  :placeholder="__('comments')"
                                  />
                              </ve-form-group>
                            </ve-col>
                          </div>
                          <div class="row" v-for="(s3Ot, index) in ud.t3.s3Other" v-bind:key="index">
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-input 
                                  v-model="s3Ot.moment"
                                  :placeholder="__('otherSpecify')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                  <ve-select
                                  :searchable="false"
                                  :placeholder="__('empty')"
                                  v-if="frequency"
                                  @on-select="function(option){s3Ot.frequency = option.value}"
                                  :options="frequency"
                                  :readonly="true"
                                />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-select
                                  :searchable="false"
                                  :placeholder="__('empty')"
                                  v-if="responsible"
                                  @on-select="function(option){s3Ot.responsible = option.value}"
                                  :options="responsible"
                                  :readonly="true"
                                />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-input 
                                  v-model="s3Ot.delivery"
                                  :placeholder="__('deliveryDates')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                <ve-input
                                  v-model="s3Ot.amount"
                                  type="number"                                   
                                  :placeholder="__('amount')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                <ve-input
                                  v-model="s3Ot.comments"
                                  :placeholder="__('comments')"
                                  />
                              </ve-form-group>
                            </ve-col>
                          </div>
                          <ve-col md6 xs12 >
                            <div  class="" style="padding:20px 40%;">
                              <div @click="removeOther('t3','s3Other')" class="btn-minus1">-</div>
                              <div @click="addOther('t3','s3Other')" class="btn-plus1">+</div>
                            </div>
                          </ve-col>

                        </div>
                      </div>
                      <h5 class="mt-3">{{__('tab3Sec4Head')}}</h5>
                      <div class="card border-0 shadow-sm">
                        <div class="card-body pb-0">
                           <div class="row">
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-label>{{ __('tab3Sec4Op1') }}</ve-label>
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                  <ve-select
                                  :searchable="false"
                                  :placeholder="__('empty')"
                                  v-if="frequency"
                                  @on-select="function(option){ud.t3.s4r1.frequency = option.value}"
                                  :options="frequency"
                                  :readonly="true"
                                />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-select
                                  :searchable="false"
                                  :placeholder="__('empty')"
                                  v-if="responsible"
                                  @on-select="function(option){ud.t3.s4r1.responsible = option.value}"
                                  :options="responsible"
                                  :readonly="true"
                                />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-input 
                                  v-model="ud.t3.s4r1.delivery"
                                  :placeholder="__('deliveryDates')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                <ve-input
                                  v-model="ud.t3.s4r1.amount"
                                  type="number"                                   
                                  :placeholder="__('amount')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                <ve-input
                                  v-model="ud.t3.s4r1.comments"
                                  :placeholder="__('comments')"
                                  />
                              </ve-form-group>
                            </ve-col>
                          </div>
                          <div class="row">
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-label>{{ __('tab3Sec4Op2') }}</ve-label>
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                  <ve-select
                                  :searchable="false"
                                  :placeholder="__('empty')"
                                  v-if="frequency"
                                  @on-select="function(option){ud.t3.s4r2.frequency = option.value}"
                                  :options="frequency"
                                  :readonly="true"
                                />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-select
                                  :searchable="false"
                                  :placeholder="__('empty')"
                                  v-if="responsible"
                                  @on-select="function(option){ud.t3.s4r2.responsible = option.value}"
                                  :options="responsible"
                                  :readonly="true"
                                />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-input 
                                  v-model="ud.t3.s4r2.delivery"
                                  :placeholder="__('deliveryDates')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                <ve-input
                                  v-model="ud.t3.s4r2.amount"
                                  type="number"                                   
                                  :placeholder="__('amount')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                <ve-input
                                  v-model="ud.t3.s4r2.comments"
                                  :placeholder="__('comments')"
                                  />
                              </ve-form-group>
                            </ve-col>
                          </div>
                          <div class="row">
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-label>{{ __('tab3Sec4Op3') }}</ve-label>
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                  <ve-select
                                  :searchable="false"
                                  :placeholder="__('empty')"
                                  v-if="frequency"
                                  @on-select="function(option){ud.t3.s4r3.frequency = option.value}"
                                  :options="frequency"
                                  :readonly="true"
                                />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-select
                                  :searchable="false"
                                  :placeholder="__('empty')"
                                  v-if="responsible"
                                  @on-select="function(option){ud.t3.s4r3.responsible = option.value}"
                                  :options="responsible"
                                  :readonly="true"
                                />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-input 
                                  v-model="ud.t3.s4r3.delivery"
                                  :placeholder="__('deliveryDates')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                <ve-input
                                  v-model="ud.t3.s4r3.amount"
                                  type="number"                                   
                                  :placeholder="__('amount')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                <ve-input
                                  v-model="ud.t3.s4r3.comments"
                                  :placeholder="__('comments')"
                                  />
                              </ve-form-group>
                            </ve-col>
                          </div>
                          <div class="row">
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-label>{{ __('tab3Sec4Op4') }}</ve-label>
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                  <ve-select
                                  :searchable="false"
                                  :placeholder="__('empty')"
                                  v-if="frequency"
                                  @on-select="function(option){ud.t3.s4r4.frequency = option.value}"
                                  :options="frequency"
                                  :readonly="true"
                                />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-select
                                  :searchable="false"
                                  :placeholder="__('empty')"
                                  v-if="responsible"
                                  @on-select="function(option){ud.t3.s4r4.responsible = option.value}"
                                  :options="responsible"
                                  :readonly="true"
                                />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-input 
                                  v-model="ud.t3.s4r4.delivery"
                                  :placeholder="__('deliveryDates')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                <ve-input
                                  v-model="ud.t3.s4r4.amount"
                                  type="number"                                   
                                  :placeholder="__('amount')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                <ve-input
                                  v-model="ud.t3.s4r4.comments"
                                  :placeholder="__('comments')"
                                  />
                              </ve-form-group>
                            </ve-col>
                          </div>
                          <div class="row">
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-label>{{ __('tab3Sec4Op5') }}</ve-label>
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                  <ve-select
                                  :searchable="false"
                                  :placeholder="__('empty')"
                                  v-if="frequency"
                                  @on-select="function(option){ud.t3.s4r5.frequency = option.value}"
                                  :options="frequency"
                                  :readonly="true"
                                />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-select
                                  :searchable="false"
                                  :placeholder="__('empty')"
                                  v-if="responsible"
                                  @on-select="function(option){ud.t3.s4r5.responsible = option.value}"
                                  :options="responsible"
                                  :readonly="true"
                                />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-input 
                                  v-model="ud.t3.s4r5.delivery"
                                  :placeholder="__('deliveryDates')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                <ve-input
                                  v-model="ud.t3.s4r5.amount"
                                  type="number"                                   
                                  :placeholder="__('amount')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                <ve-input
                                  v-model="ud.t3.s4r5.comments"
                                  :placeholder="__('comments')"
                                  />
                              </ve-form-group>
                            </ve-col>
                          </div>
                          <div class="row">
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-label>{{ __('tab3Sec4Op6') }}</ve-label>
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                  <ve-select
                                  :searchable="false"
                                  :placeholder="__('empty')"
                                  v-if="frequency"
                                  @on-select="function(option){ud.t3.s4r6.frequency = option.value}"
                                  :options="frequency"
                                  :readonly="true"
                                />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-select
                                  :searchable="false"
                                  :placeholder="__('empty')"
                                  v-if="responsible"
                                  @on-select="function(option){ud.t3.s4r6.responsible = option.value}"
                                  :options="responsible"
                                  :readonly="true"
                                />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-input 
                                  v-model="ud.t3.s4r6.delivery"
                                  :placeholder="__('deliveryDates')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                <ve-input
                                  v-model="ud.t3.s4r6.amount"
                                  type="number"                                   
                                  :placeholder="__('amount')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                <ve-input
                                  v-model="ud.t3.s4r6.comments"
                                  :placeholder="__('comments')"
                                  />
                              </ve-form-group>
                            </ve-col>
                          </div>
                          <div class="row">
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-label>{{ __('tab3Sec4Op7') }}</ve-label>
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                  <ve-select
                                  :searchable="false"
                                  :placeholder="__('empty')"
                                  v-if="frequency"
                                  @on-select="function(option){ud.t3.s4r7.frequency = option.value}"
                                  :options="frequency"
                                  :readonly="true"
                                />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-select
                                  :searchable="false"
                                  :placeholder="__('empty')"
                                  v-if="responsible"
                                  @on-select="function(option){ud.t3.s4r7.responsible = option.value}"
                                  :options="responsible"
                                  :readonly="true"
                                />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-input 
                                  v-model="ud.t3.s4r7.delivery"
                                  :placeholder="__('deliveryDates')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                <ve-input
                                  v-model="ud.t3.s4r7.amount"
                                  type="number"                                   
                                  :placeholder="__('amount')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                <ve-input
                                  v-model="ud.t3.s4r7.comments"
                                  :placeholder="__('comments')"
                                  />
                              </ve-form-group>
                            </ve-col>
                          </div>
                          <div class="row">
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-label>{{ __('tab3Sec4Op8') }}</ve-label>
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                  <ve-select
                                  :searchable="false"
                                  :placeholder="__('empty')"
                                  v-if="frequency"
                                  @on-select="function(option){ud.t3.s4r8.frequency = option.value}"
                                  :options="frequency"
                                  :readonly="true"
                                />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-select
                                  :searchable="false"
                                  :placeholder="__('empty')"
                                  v-if="responsible"
                                  @on-select="function(option){ud.t3.s4r8.responsible = option.value}"
                                  :options="responsible"
                                  :readonly="true"
                                />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-input 
                                  v-model="ud.t3.s4r8.delivery"
                                  :placeholder="__('deliveryDates')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                <ve-input
                                  v-model="ud.t3.s4r8.amount"
                                  type="number"                                   
                                  :placeholder="__('amount')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                <ve-input
                                  v-model="ud.t3.s4r8.comments"
                                  :placeholder="__('comments')"
                                  />
                              </ve-form-group>
                            </ve-col>
                          </div>
                          <div class="row">
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-label>{{ __('tab3Sec4Op9') }}</ve-label>
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                  <ve-select
                                  :searchable="false"
                                  :placeholder="__('empty')"
                                  v-if="frequency"
                                  @on-select="function(option){ud.t3.s4r9.frequency = option.value}"
                                  :options="frequency"
                                  :readonly="true"
                                />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-select
                                  :searchable="false"
                                  :placeholder="__('empty')"
                                  v-if="responsible"
                                  @on-select="function(option){ud.t3.s4r9.responsible = option.value}"
                                  :options="responsible"
                                  :readonly="true"
                                />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-input 
                                  v-model="ud.t3.s4r9.delivery"
                                  :placeholder="__('deliveryDates')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                <ve-input
                                  v-model="ud.t3.s4r9.amount"
                                  type="number"                                   
                                  :placeholder="__('amount')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                <ve-input
                                  v-model="ud.t3.s4r9.comments"
                                  :placeholder="__('comments')"
                                  />
                              </ve-form-group>
                            </ve-col>
                          </div>
                          <div class="row">
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-label>{{ __('tab3Sec4Op10') }}</ve-label>
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                  <ve-select
                                  :searchable="false"
                                  :placeholder="__('empty')"
                                  v-if="frequency"
                                  @on-select="function(option){ud.t3.s4r10.frequency = option.value}"
                                  :options="frequency"
                                  :readonly="true"
                                />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-select
                                  :searchable="false"
                                  :placeholder="__('empty')"
                                  v-if="responsible"
                                  @on-select="function(option){ud.t3.s4r10.responsible = option.value}"
                                  :options="responsible"
                                  :readonly="true"
                                />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-input 
                                  v-model="ud.t3.s4r10.delivery"
                                  :placeholder="__('deliveryDates')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                <ve-input
                                  v-model="ud.t3.s4r10.amount"
                                  type="number"                                   
                                  :placeholder="__('amount')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                <ve-input
                                  v-model="ud.t3.s4r10.comments"
                                  :placeholder="__('comments')"
                                  />
                              </ve-form-group>
                            </ve-col>
                          </div>
                          <div class="row" v-for="(s4Ot, index) in ud.t3.s4Other" v-bind:key="index">
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-input 
                                  v-model="s4Ot.moment"
                                  :placeholder="__('otherSpecify')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                  <ve-select
                                  :searchable="false"
                                  :placeholder="__('empty')"
                                  v-if="frequency"
                                  @on-select="function(option){s4Ot.frequency = option.value}"
                                  :options="frequency"
                                  :readonly="true"
                                />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-select
                                  :searchable="false"
                                  :placeholder="__('empty')"
                                  v-if="responsible"
                                  @on-select="function(option){s4Ot.responsible = option.value}"
                                  :options="responsible"
                                  :readonly="true"
                                />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-input 
                                  v-model="s4Ot.delivery"
                                  :placeholder="__('deliveryDates')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                <ve-input
                                  v-model="s4Ot.amount"
                                  type="number"                                   
                                  :placeholder="__('amount')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                <ve-input
                                  v-model="s4Ot.comments"
                                  :placeholder="__('comments')"
                                  />
                              </ve-form-group>
                            </ve-col>
                          </div>
                          <ve-col md6 xs12 >
                            <div  class="" style="padding:20px 40%;">
                              <div @click="removeOther('t3','s4Other')" class="btn-minus1">-</div>
                              <div @click="addOther('t3','s4Other')" class="btn-plus1">+</div>
                            </div>
                          </ve-col>

                        </div>
                      </div>
                      <h4 class="mt-1">{{__('tab3Sec5Head')}}</h4>
                      <div class="card border-0 shadow-sm">
                        <div class="card-body pb-0">
                          <div class="row">
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-label>{{ __('tab3Sec5Op1') }}</ve-label>
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                  <ve-select
                                  :searchable="false"
                                  :placeholder="__('empty')"
                                  v-if="frequency"
                                  @on-select="function(option){ud.t3.s5r1.frequency = option.value}"
                                  :options="frequency"
                                  :readonly="true"
                                />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-select
                                  :searchable="false"
                                  :placeholder="__('empty')"
                                  v-if="responsible"
                                  @on-select="function(option){ud.t3.s5r1.responsible = option.value}"
                                  :options="responsible"
                                  :readonly="true"
                                />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-input 
                                  v-model="ud.t3.s5r1.delivery"
                                  :placeholder="__('deliveryDates')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                <ve-input
                                  v-model="ud.t3.s5r1.amount"
                                  type="number"                                   
                                  :placeholder="__('amount')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                <ve-input
                                  v-model="ud.t3.s5r1.comments"
                                  :placeholder="__('comments')"
                                  />
                              </ve-form-group>
                            </ve-col>
                          </div>
                          <div class="row">
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-label>{{ __('tab3Sec5Op2') }}</ve-label>
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                  <ve-select
                                  :searchable="false"
                                  :placeholder="__('empty')"
                                  v-if="frequency"
                                  @on-select="function(option){ud.t3.s5r2.frequency = option.value}"
                                  :options="frequency"
                                  :readonly="true"
                                />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-select
                                  :searchable="false"
                                  :placeholder="__('empty')"
                                  v-if="responsible"
                                  @on-select="function(option){ud.t3.s5r2.responsible = option.value}"
                                  :options="responsible"
                                  :readonly="true"
                                />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-input 
                                  v-model="ud.t3.s5r2.delivery"
                                  :placeholder="__('deliveryDates')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                <ve-input
                                  v-model="ud.t3.s5r2.amount"
                                  type="number"                                   
                                  :placeholder="__('amount')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                <ve-input
                                  v-model="ud.t3.s5r2.comments"
                                  :placeholder="__('comments')"
                                  />
                              </ve-form-group>
                            </ve-col>
                          </div>
                          <div class="row">
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-label>{{ __('tab3Sec5Op3') }}</ve-label>
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                  <ve-select
                                  :searchable="false"
                                  :placeholder="__('empty')"
                                  v-if="frequency"
                                  @on-select="function(option){ud.t3.s5r3.frequency = option.value}"
                                  :options="frequency"
                                  :readonly="true"
                                />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-select
                                  :searchable="false"
                                  :placeholder="__('empty')"
                                  v-if="responsible"
                                  @on-select="function(option){ud.t3.s5r3.responsible = option.value}"
                                  :options="responsible"
                                  :readonly="true"
                                />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-input 
                                  v-model="ud.t3.s5r3.delivery"
                                  :placeholder="__('deliveryDates')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                <ve-input
                                  v-model="ud.t3.s5r3.amount"
                                  type="number"                                   
                                  :placeholder="__('amount')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                <ve-input
                                  v-model="ud.t3.s5r3.comments"
                                  :placeholder="__('comments')"
                                  />
                              </ve-form-group>
                            </ve-col>
                          </div>
                          <div class="row">
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-label>{{ __('tab3Sec5Op4') }}</ve-label>
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                  <ve-select
                                  :searchable="false"
                                  :placeholder="__('empty')"
                                  v-if="frequency"
                                  @on-select="function(option){ud.t3.s5r4.frequency = option.value}"
                                  :options="frequency"
                                  :readonly="true"
                                />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-select
                                  :searchable="false"
                                  :placeholder="__('empty')"
                                  v-if="responsible"
                                  @on-select="function(option){ud.t3.s5r4.responsible = option.value}"
                                  :options="responsible"
                                  :readonly="true"
                                />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-input 
                                  v-model="ud.t3.s5r4.delivery"
                                  :placeholder="__('deliveryDates')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                <ve-input
                                  v-model="ud.t3.s5r4.amount"
                                  type="number"                                   
                                  :placeholder="__('amount')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                <ve-input
                                  v-model="ud.t3.s5r4.comments"
                                  :placeholder="__('comments')"
                                  />
                              </ve-form-group>
                            </ve-col>
                          </div>
                          <div class="row">
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-label>{{ __('tab3Sec5Op5') }}</ve-label>
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                  <ve-select
                                  :searchable="false"
                                  :placeholder="__('empty')"
                                  v-if="frequency"
                                  @on-select="function(option){ud.t3.s5r5.frequency = option.value}"
                                  :options="frequency"
                                  :readonly="true"
                                />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-select
                                  :searchable="false"
                                  :placeholder="__('empty')"
                                  v-if="responsible"
                                  @on-select="function(option){ud.t3.s5r5.responsible = option.value}"
                                  :options="responsible"
                                  :readonly="true"
                                />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-input 
                                  v-model="ud.t3.s5r5.delivery"
                                  :placeholder="__('deliveryDates')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                <ve-input
                                  v-model="ud.t3.s5r5.amount"
                                  type="number"                                   
                                  :placeholder="__('amount')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                <ve-input
                                  v-model="ud.t3.s5r5.comments"
                                  :placeholder="__('comments')"
                                  />
                              </ve-form-group>
                            </ve-col>
                          </div>
                          <div class="row" v-for="(s5Ot, index) in ud.t3.s5Other" v-bind:key="index">
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-input 
                                  v-model="s5Ot.moment"
                                  :placeholder="__('otherSpecify')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                  <ve-select
                                  :searchable="false"
                                  :placeholder="__('empty')"
                                  v-if="frequency"
                                  @on-select="function(option){s5Ot.frequency = option.value}"
                                  :options="frequency"
                                  :readonly="true"
                                />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-select
                                  :searchable="false"
                                  :placeholder="__('empty')"
                                  v-if="responsible"
                                  @on-select="function(option){s5Ot.responsible = option.value}"
                                  :options="responsible"
                                  :readonly="true"
                                />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-input 
                                  v-model="s5Ot.delivery"
                                  :placeholder="__('deliveryDates')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                <ve-input
                                  v-model="s5Ot.amount"
                                  type="number"
                                  :placeholder="__('amount')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                <ve-input
                                  v-model="s5Ot.comments"
                                  :placeholder="__('comments')"
                                  />
                              </ve-form-group>
                            </ve-col>
                          </div>
                          <ve-col md6 xs12 >
                            <div  class="" style="padding:20px 40%;">
                              <div @click="removeOther('t3','s5Other')" class="btn-minus1">-</div>
                              <div @click="addOther('t3','s5Other')" class="btn-plus1">+</div>
                            </div>
                          </ve-col>
                        </div>
                      </div>
                      <h5 class="mt-3">{{__('tab3Sec6Head')}}</h5>
                      <div class="card border-0 shadow-sm">
                        <div class="card-body pb-0">
                           <div class="row">
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-label>{{ __('tab3Sec6Op1') }}</ve-label>
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                  <ve-select
                                  :searchable="false"
                                  :placeholder="__('empty')"
                                  v-if="frequency"
                                  @on-select="function(option){ud.t3.s6r1.frequency = option.value}"
                                  :options="frequency"
                                  :readonly="true"
                                />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-select
                                  :searchable="false"
                                  :placeholder="__('empty')"
                                  v-if="responsible"
                                  @on-select="function(option){ud.t3.s6r1.responsible = option.value}"
                                  :options="responsible"
                                  :readonly="true"
                                />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-input 
                                  v-model="ud.t3.s6r1.delivery"
                                  :placeholder="__('deliveryDates')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                <ve-input
                                  v-model="ud.t3.s6r1.amount"
                                  type="number"                                   
                                  :placeholder="__('amount')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                <ve-input
                                  v-model="ud.t3.s6r1.comments"
                                  :placeholder="__('comments')"
                                  />
                              </ve-form-group>
                            </ve-col>
                          </div>
                          <div class="row">
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-label>{{ __('tab3Sec6Op2') }}</ve-label>
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                  <ve-select
                                  :searchable="false"
                                  :placeholder="__('empty')"
                                  v-if="frequency"
                                  @on-select="function(option){ud.t3.s6r2.frequency = option.value}"
                                  :options="frequency"
                                  :readonly="true"
                                />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-select
                                  :searchable="false"
                                  :placeholder="__('empty')"
                                  v-if="responsible"
                                  @on-select="function(option){ud.t3.s6r2.responsible = option.value}"
                                  :options="responsible"
                                  :readonly="true"
                                />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-input 
                                  v-model="ud.t3.s6r2.delivery"
                                  :placeholder="__('deliveryDates')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                <ve-input
                                  v-model="ud.t3.s6r2.amount"
                                  type="number"                                   
                                  :placeholder="__('amount')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                <ve-input
                                  v-model="ud.t3.s6r2.comments"
                                  :placeholder="__('comments')"
                                  />
                              </ve-form-group>
                            </ve-col>
                          </div>
                          <div class="row">
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-label>{{ __('tab3Sec6Op3') }}</ve-label>
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                  <ve-select
                                  :searchable="false"
                                  :placeholder="__('empty')"
                                  v-if="frequency"
                                  @on-select="function(option){ud.t3.s6r3.frequency = option.value}"
                                  :options="frequency"
                                  :readonly="true"
                                />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-select
                                  :searchable="false"
                                  :placeholder="__('empty')"
                                  v-if="responsible"
                                  @on-select="function(option){ud.t3.s6r3.responsible = option.value}"
                                  :options="responsible"
                                  :readonly="true"
                                />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-input 
                                  v-model="ud.t3.s6r3.delivery"
                                  :placeholder="__('deliveryDates')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                <ve-input
                                  v-model="ud.t3.s6r3.amount"
                                  type="number"                                   
                                  :placeholder="__('amount')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                <ve-input
                                  v-model="ud.t3.s6r3.comments"
                                  :placeholder="__('comments')"
                                  />
                              </ve-form-group>
                            </ve-col>
                          </div>
                          <div class="row">
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-label>{{ __('tab3Sec6Op4') }}</ve-label>
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                  <ve-select
                                  :searchable="false"
                                  :placeholder="__('empty')"
                                  v-if="frequency"
                                  @on-select="function(option){ud.t3.s6r4.frequency = option.value}"
                                  :options="frequency"
                                  :readonly="true"
                                />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-select
                                  :searchable="false"
                                  :placeholder="__('empty')"
                                  v-if="responsible"
                                  @on-select="function(option){ud.t3.s6r4.responsible = option.value}"
                                  :options="responsible"
                                  :readonly="true"
                                />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-input 
                                  v-model="ud.t3.s6r4.delivery"
                                  :placeholder="__('deliveryDates')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                <ve-input
                                  v-model="ud.t3.s6r4.amount"
                                  type="number"                                   
                                  :placeholder="__('amount')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                <ve-input
                                  v-model="ud.t3.s6r4.comments"
                                  :placeholder="__('comments')"
                                  />
                              </ve-form-group>
                            </ve-col>
                          </div>
                          <div class="row">
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-label>{{ __('tab3Sec6Op5') }}</ve-label>
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                  <ve-select
                                  :searchable="false"
                                  :placeholder="__('empty')"
                                  v-if="frequency"
                                  @on-select="function(option){ud.t3.s6r5.frequency = option.value}"
                                  :options="frequency"
                                  :readonly="true"
                                />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-select
                                  :searchable="false"
                                  :placeholder="__('empty')"
                                  v-if="responsible"
                                  @on-select="function(option){ud.t3.s6r5.responsible = option.value}"
                                  :options="responsible"
                                  :readonly="true"
                                />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-input 
                                  v-model="ud.t3.s6r5.delivery"
                                  :placeholder="__('deliveryDates')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                <ve-input
                                  v-model="ud.t3.s6r5.amount"
                                  type="number"                                   
                                  :placeholder="__('amount')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                <ve-input
                                  v-model="ud.t3.s6r5.comments"
                                  :placeholder="__('comments')"
                                  />
                              </ve-form-group>
                            </ve-col>
                          </div>
                          <div class="row">
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-label>{{ __('tab3Sec6Op6') }}</ve-label>
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                  <ve-select
                                  :searchable="false"
                                  :placeholder="__('empty')"
                                  v-if="frequency"
                                  @on-select="function(option){ud.t3.s6r6.frequency = option.value}"
                                  :options="frequency"
                                  :readonly="true"
                                />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-select
                                  :searchable="false"
                                  :placeholder="__('empty')"
                                  v-if="responsible"
                                  @on-select="function(option){ud.t3.s6r6.responsible = option.value}"
                                  :options="responsible"
                                  :readonly="true"
                                />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-input 
                                  v-model="ud.t3.s6r6.delivery"
                                  :placeholder="__('deliveryDates')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                <ve-input
                                  v-model="ud.t3.s6r6.amount"
                                  type="number"                                   
                                  :placeholder="__('amount')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                <ve-input
                                  v-model="ud.t3.s6r6.comments"
                                  :placeholder="__('comments')"
                                  />
                              </ve-form-group>
                            </ve-col>
                          </div>
                          <div class="row">
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-label>{{ __('tab3Sec6Op7') }}</ve-label>
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                  <ve-select
                                  :searchable="false"
                                  :placeholder="__('empty')"
                                  v-if="frequency"
                                  @on-select="function(option){ud.t3.s6r7.frequency = option.value}"
                                  :options="frequency"
                                  :readonly="true"
                                />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-select
                                  :searchable="false"
                                  :placeholder="__('empty')"
                                  v-if="responsible"
                                  @on-select="function(option){ud.t3.s6r7.responsible = option.value}"
                                  :options="responsible"
                                  :readonly="true"
                                />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-input 
                                  v-model="ud.t3.s6r7.delivery"
                                  :placeholder="__('deliveryDates')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                <ve-input
                                  v-model="ud.t3.s6r7.amount"
                                  type="number"                                   
                                  :placeholder="__('amount')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                <ve-input
                                  v-model="ud.t3.s6r7.comments"
                                  :placeholder="__('comments')"
                                  />
                              </ve-form-group>
                            </ve-col>
                          </div>
                          <div class="row">
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-label>{{ __('tab3Sec6Op8') }}</ve-label>
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                  <ve-select
                                  :searchable="false"
                                  :placeholder="__('empty')"
                                  v-if="frequency"
                                  @on-select="function(option){ud.t3.s6r8.frequency = option.value}"
                                  :options="frequency"
                                  :readonly="true"
                                />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-select
                                  :searchable="false"
                                  :placeholder="__('empty')"
                                  v-if="responsible"
                                  @on-select="function(option){ud.t3.s6r8.responsible = option.value}"
                                  :options="responsible"
                                  :readonly="true"
                                />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-input 
                                  v-model="ud.t3.s6r8.delivery"
                                  :placeholder="__('deliveryDates')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                <ve-input
                                  v-model="ud.t3.s6r8.amount"
                                  type="number"                                   
                                  :placeholder="__('amount')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                <ve-input
                                  v-model="ud.t3.s6r8.comments"
                                  :placeholder="__('comments')"
                                  />
                              </ve-form-group>
                            </ve-col>
                          </div>
                          <div class="row" v-for="(s6Ot, index) in ud.t3.s6Other" v-bind:key="index">
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-input 
                                  v-model="s6Ot.moment"
                                  :placeholder="__('otherSpecify')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                  <ve-select
                                  :searchable="false"
                                  :placeholder="__('empty')"
                                  v-if="frequency"
                                  @on-select="function(option){s6Ot.frequency = option.value}"
                                  :options="frequency"
                                  :readonly="true"
                                />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-select
                                  :searchable="false"
                                  :placeholder="__('empty')"
                                  v-if="responsible"
                                  @on-select="function(option){s6Ot.responsible = option.value}"
                                  :options="responsible"
                                  :readonly="true"
                                />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-input 
                                  v-model="s6Ot.delivery"
                                  :placeholder="__('deliveryDates')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                <ve-input
                                  v-model="s6Ot.amount"
                                  type="number"                                   
                                  :placeholder="__('amount')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                <ve-input
                                  v-model="s6Ot.comments"
                                  :placeholder="__('comments')"
                                  />
                              </ve-form-group>
                            </ve-col>
                          </div>
                          <ve-col md6 xs12 >
                            <div  class="" style="padding:20px 40%;">
                              <div @click="removeOther('t3','s6Other')" class="btn-minus1">-</div>
                              <div @click="addOther('t3','s6Other')" class="btn-plus1">+</div>
                            </div>
                          </ve-col>

                        </div>
                      </div>
                      <h4 class="mt-1">{{__('tab3Sec7Head')}}</h4>
                      <div class="card border-0 shadow-sm">
                        <div class="card-body pb-0">
                          <div class="row">
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-label>{{ __('tab3Sec7Op1') }}</ve-label>
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                  <ve-select
                                  :searchable="false"
                                  :placeholder="__('empty')"
                                  v-if="frequency"
                                  @on-select="function(option){ud.t3.s7r1.frequency = option.value}"
                                  :options="frequency"
                                  :readonly="true"
                                />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-select
                                  :searchable="false"
                                  :placeholder="__('empty')"
                                  v-if="responsible"
                                  @on-select="function(option){ud.t3.s7r1.responsible = option.value}"
                                  :options="responsible"
                                  :readonly="true"
                                />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-input 
                                  v-model="ud.t3.s7r1.delivery"
                                  :placeholder="__('deliveryDates')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                <ve-input
                                  v-model="ud.t3.s7r1.amount"
                                  type="number"                                   
                                  :placeholder="__('amount')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                <ve-input
                                  v-model="ud.t3.s7r1.comments"
                                  :placeholder="__('comments')"
                                  />
                              </ve-form-group>
                            </ve-col>
                          </div>
                          <div class="row">
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-label>{{ __('tab3Sec7Op2') }}</ve-label>
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                  <ve-select
                                  :searchable="false"
                                  :placeholder="__('empty')"
                                  v-if="frequency"
                                  @on-select="function(option){ud.t3.s7r2.frequency = option.value}"
                                  :options="frequency"
                                  :readonly="true"
                                />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-select
                                  :searchable="false"
                                  :placeholder="__('empty')"
                                  v-if="responsible"
                                  @on-select="function(option){ud.t3.s7r2.responsible = option.value}"
                                  :options="responsible"
                                  :readonly="true"
                                />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-input 
                                  v-model="ud.t3.s7r2.delivery"
                                  :placeholder="__('deliveryDates')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                <ve-input
                                  v-model="ud.t3.s7r2.amount"
                                  type="number"                                   
                                  :placeholder="__('amount')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                <ve-input
                                  v-model="ud.t3.s7r2.comments"
                                  :placeholder="__('comments')"
                                  />
                              </ve-form-group>
                            </ve-col>
                          </div>
                          <div class="row">
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-label>{{ __('tab3Sec7Op3') }}</ve-label>
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                  <ve-select
                                  :searchable="false"
                                  :placeholder="__('empty')"
                                  v-if="frequency"
                                  @on-select="function(option){ud.t3.s7r3.frequency = option.value}"
                                  :options="frequency"
                                  :readonly="true"
                                />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-select
                                  :searchable="false"
                                  :placeholder="__('empty')"
                                  v-if="responsible"
                                  @on-select="function(option){ud.t3.s7r3.responsible = option.value}"
                                  :options="responsible"
                                  :readonly="true"
                                />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-input 
                                  v-model="ud.t3.s7r3.delivery"
                                  :placeholder="__('deliveryDates')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                <ve-input
                                  v-model="ud.t3.s7r3.amount"
                                  type="number"                                   
                                  :placeholder="__('amount')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                <ve-input
                                  v-model="ud.t3.s7r3.comments"
                                  :placeholder="__('comments')"
                                  />
                              </ve-form-group>
                            </ve-col>
                          </div>
                          <div class="row" v-for="(s7Ot, index) in ud.t3.s7Other" v-bind:key="index">
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-input 
                                  v-model="s7Ot.moment"
                                  :placeholder="__('otherSpecify')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                  <ve-select
                                  :searchable="false"
                                  :placeholder="__('empty')"
                                  v-if="frequency"
                                  @on-select="function(option){s7Ot.frequency = option.value}"
                                  :options="frequency"
                                  :readonly="true"
                                />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-select
                                  :searchable="false"
                                  :placeholder="__('empty')"
                                  v-if="responsible"
                                  @on-select="function(option){s7Ot.responsible = option.value}"
                                  :options="responsible"
                                  :readonly="true"
                                />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-input 
                                  v-model="s7Ot.delivery"
                                  :placeholder="__('deliveryDates')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                <ve-input
                                  v-model="s7Ot.amount"
                                  type="number"
                                  :placeholder="__('amount')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                <ve-input
                                  v-model="s7Ot.comments"
                                  :placeholder="__('comments')"
                                  />
                              </ve-form-group>
                            </ve-col>
                          </div>
                          <ve-col md6 xs12 >
                            <div  class="" style="padding:20px 40%;">
                              <div @click="removeOther('t3','s7Other')" class="btn-minus1">-</div>
                              <div @click="addOther('t3','s7Other')" class="btn-plus1">+</div>
                            </div>
                          </ve-col>
                        </div>
                      </div>
                      <h4 class="mt-1">{{__('tab3Sec8Head')}}</h4>
                      <div class="card border-0 shadow-sm">
                        <div class="card-body pb-0">
                          <div class="row" v-for="(s8Ot, index) in ud.t3.s8Other" v-bind:key="index">
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-input 
                                  v-model="s8Ot.moment"
                                  :placeholder="__('otherSpecify')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                  <ve-select
                                  :searchable="false"
                                  :placeholder="__('empty')"
                                  v-if="frequency"
                                  @on-select="function(option){s8Ot.frequency = option.value}"
                                  :options="frequency"
                                  :readonly="true"
                                />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-select
                                  :searchable="false"
                                  :placeholder="__('empty')"
                                  v-if="responsible"
                                  @on-select="function(option){s8Ot.responsible = option.value}"
                                  :options="responsible"
                                  :readonly="true"
                                />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-input 
                                  v-model="s8Ot.delivery"
                                  :placeholder="__('deliveryDates')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                <ve-input
                                  v-model="s8Ot.amount"
                                  type="number"
                                  :placeholder="__('amount')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                <ve-input
                                  v-model="s8Ot.comments"
                                  :placeholder="__('comments')"
                                  />
                              </ve-form-group>
                            </ve-col>
                          </div>
                          <ve-col md6 xs12 >
                            <div  class="" style="padding:20px 40%;">
                              <div @click="removeOther('t3','s8Other')" class="btn-minus1">-</div>
                              <div @click="addOther('t3','s8Other')" class="btn-plus1">+</div>
                            </div>
                          </ve-col>
                        </div>
                      </div>
                      <h5 class="mt-3">{{__('tab3Sec9Head')}}</h5>
                      <div class="card border-0 shadow-sm">
                        <div class="card-body pb-0">
                           <div class="row">
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-label>{{ __('tab3Sec9Op1') }}</ve-label>
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                  <ve-select
                                  :searchable="false"
                                  :placeholder="__('empty')"
                                  v-if="frequency"
                                  @on-select="function(option){ud.t3.s9r1.frequency = option.value}"
                                  :options="frequency"
                                  :readonly="true"
                                />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-select
                                  :searchable="false"
                                  :placeholder="__('empty')"
                                  v-if="responsible"
                                  @on-select="function(option){ud.t3.s9r1.responsible = option.value}"
                                  :options="responsible"
                                  :readonly="true"
                                />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-input 
                                  v-model="ud.t3.s9r1.delivery"
                                  :placeholder="__('deliveryDates')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                <ve-input
                                  v-model="ud.t3.s9r1.amount"
                                  type="number"                                   
                                  :placeholder="__('amount')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                <ve-input
                                  v-model="ud.t3.s9r1.comments"
                                  :placeholder="__('comments')"
                                  />
                              </ve-form-group>
                            </ve-col>
                          </div>
                          <div class="row">
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-label>{{ __('tab3Sec9Op2') }}</ve-label>
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                  <ve-select
                                  :searchable="false"
                                  :placeholder="__('empty')"
                                  v-if="frequency"
                                  @on-select="function(option){ud.t3.s9r2.frequency = option.value}"
                                  :options="frequency"
                                  :readonly="true"
                                />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-select
                                  :searchable="false"
                                  :placeholder="__('empty')"
                                  v-if="responsible"
                                  @on-select="function(option){ud.t3.s9r2.responsible = option.value}"
                                  :options="responsible"
                                  :readonly="true"
                                />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-input 
                                  v-model="ud.t3.s9r2.delivery"
                                  :placeholder="__('deliveryDates')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                <ve-input
                                  v-model="ud.t3.s9r2.amount"
                                  type="number"                                   
                                  :placeholder="__('amount')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                <ve-input
                                  v-model="ud.t3.s9r2.comments"
                                  :placeholder="__('comments')"
                                  />
                              </ve-form-group>
                            </ve-col>
                          </div>
                          <div class="row">
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-label>{{ __('tab3Sec9Op3') }}</ve-label>
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                  <ve-select
                                  :searchable="false"
                                  :placeholder="__('empty')"
                                  v-if="frequency"
                                  @on-select="function(option){ud.t3.s9r3.frequency = option.value}"
                                  :options="frequency"
                                  :readonly="true"
                                />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-select
                                  :searchable="false"
                                  :placeholder="__('empty')"
                                  v-if="responsible"
                                  @on-select="function(option){ud.t3.s9r3.responsible = option.value}"
                                  :options="responsible"
                                  :readonly="true"
                                />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-input 
                                  v-model="ud.t3.s9r3.delivery"
                                  :placeholder="__('deliveryDates')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                <ve-input
                                  v-model="ud.t3.s9r3.amount"
                                  type="number"                                   
                                  :placeholder="__('amount')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                <ve-input
                                  v-model="ud.t3.s9r3.comments"
                                  :placeholder="__('comments')"
                                  />
                              </ve-form-group>
                            </ve-col>
                          </div>
                          <div class="row">
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-label>{{ __('tab3Sec9Op4') }}</ve-label>
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                  <ve-select
                                  :searchable="false"
                                  :placeholder="__('empty')"
                                  v-if="frequency"
                                  @on-select="function(option){ud.t3.s9r4.frequency = option.value}"
                                  :options="frequency"
                                  :readonly="true"
                                />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-select
                                  :searchable="false"
                                  :placeholder="__('empty')"
                                  v-if="responsible"
                                  @on-select="function(option){ud.t3.s9r4.responsible = option.value}"
                                  :options="responsible"
                                  :readonly="true"
                                />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-input 
                                  v-model="ud.t3.s9r4.delivery"
                                  :placeholder="__('deliveryDates')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                <ve-input
                                  v-model="ud.t3.s9r4.amount"
                                  type="number"                                   
                                  :placeholder="__('amount')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                <ve-input
                                  v-model="ud.t3.s9r4.comments"
                                  :placeholder="__('comments')"
                                  />
                              </ve-form-group>
                            </ve-col>
                          </div>
                          <div class="row">
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-label>{{ __('tab3Sec9Op5') }}</ve-label>
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                  <ve-select
                                  :searchable="false"
                                  :placeholder="__('empty')"
                                  v-if="frequency"
                                  @on-select="function(option){ud.t3.s9r5.frequency = option.value}"
                                  :options="frequency"
                                  :readonly="true"
                                />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-select
                                  :searchable="false"
                                  :placeholder="__('empty')"
                                  v-if="responsible"
                                  @on-select="function(option){ud.t3.s9r5.responsible = option.value}"
                                  :options="responsible"
                                  :readonly="true"
                                />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-input 
                                  v-model="ud.t3.s9r5.delivery"
                                  :placeholder="__('deliveryDates')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                <ve-input
                                  v-model="ud.t3.s9r5.amount"
                                  type="number"                                   
                                  :placeholder="__('amount')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                <ve-input
                                  v-model="ud.t3.s9r5.comments"
                                  :placeholder="__('comments')"
                                  />
                              </ve-form-group>
                            </ve-col>
                          </div>
                          <div class="row">
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-label>{{ __('tab3Sec9Op6') }}</ve-label>
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                  <ve-select
                                  :searchable="false"
                                  :placeholder="__('empty')"
                                  v-if="frequency"
                                  @on-select="function(option){ud.t3.s9r6.frequency = option.value}"
                                  :options="frequency"
                                  :readonly="true"
                                />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-select
                                  :searchable="false"
                                  :placeholder="__('empty')"
                                  v-if="responsible"
                                  @on-select="function(option){ud.t3.s9r6.responsible = option.value}"
                                  :options="responsible"
                                  :readonly="true"
                                />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-input 
                                  v-model="ud.t3.s9r6.delivery"
                                  :placeholder="__('deliveryDates')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                <ve-input
                                  v-model="ud.t3.s9r6.amount"
                                  type="number"                                   
                                  :placeholder="__('amount')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                <ve-input
                                  v-model="ud.t3.s9r6.comments"
                                  :placeholder="__('comments')"
                                  />
                              </ve-form-group>
                            </ve-col>
                          </div>
                          <div class="row">
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-label>{{ __('tab3Sec9Op7') }}</ve-label>
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                  <ve-select
                                  :searchable="false"
                                  :placeholder="__('empty')"
                                  v-if="frequency"
                                  @on-select="function(option){ud.t3.s9r7.frequency = option.value}"
                                  :options="frequency"
                                  :readonly="true"
                                />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-select
                                  :searchable="false"
                                  :placeholder="__('empty')"
                                  v-if="responsible"
                                  @on-select="function(option){ud.t3.s9r7.responsible = option.value}"
                                  :options="responsible"
                                  :readonly="true"
                                />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-input 
                                  v-model="ud.t3.s9r7.delivery"
                                  :placeholder="__('deliveryDates')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                <ve-input
                                  v-model="ud.t3.s9r7.amount"
                                  type="number"                                   
                                  :placeholder="__('amount')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                <ve-input
                                  v-model="ud.t3.s9r7.comments"
                                  :placeholder="__('comments')"
                                  />
                              </ve-form-group>
                            </ve-col>
                          </div>
                          <div class="row">
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-label>{{ __('tab3Sec9Op8') }}</ve-label>
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                  <ve-select
                                  :searchable="false"
                                  :placeholder="__('empty')"
                                  v-if="frequency"
                                  @on-select="function(option){ud.t3.s9r8.frequency = option.value}"
                                  :options="frequency"
                                  :readonly="true"
                                />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-select
                                  :searchable="false"
                                  :placeholder="__('empty')"
                                  v-if="responsible"
                                  @on-select="function(option){ud.t3.s9r8.responsible = option.value}"
                                  :options="responsible"
                                  :readonly="true"
                                />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-input 
                                  v-model="ud.t3.s9r8.delivery"
                                  :placeholder="__('deliveryDates')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                <ve-input
                                  v-model="ud.t3.s9r8.amount"
                                  type="number"                                   
                                  :placeholder="__('amount')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                <ve-input
                                  v-model="ud.t3.s9r8.comments"
                                  :placeholder="__('comments')"
                                  />
                              </ve-form-group>
                            </ve-col>
                          </div>
                          <div class="row">
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-label>{{ __('tab3Sec9Op9') }}</ve-label>
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                  <ve-select
                                  :searchable="false"
                                  :placeholder="__('empty')"
                                  v-if="frequency"
                                  @on-select="function(option){ud.t3.s9r9.frequency = option.value}"
                                  :options="frequency"
                                  :readonly="true"
                                />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-select
                                  :searchable="false"
                                  :placeholder="__('empty')"
                                  v-if="responsible"
                                  @on-select="function(option){ud.t3.s9r9.responsible = option.value}"
                                  :options="responsible"
                                  :readonly="true"
                                />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-input 
                                  v-model="ud.t3.s9r9.delivery"
                                  :placeholder="__('deliveryDates')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                <ve-input
                                  v-model="ud.t3.s9r9.amount"
                                  type="number"                                   
                                  :placeholder="__('amount')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                <ve-input
                                  v-model="ud.t3.s9r9.comments"
                                  :placeholder="__('comments')"
                                  />
                              </ve-form-group>
                            </ve-col>
                          </div>
                          <div class="row" v-for="(s9Ot, index) in ud.t3.s9Other" v-bind:key="index">
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-input 
                                  v-model="s9Ot.moment"
                                  :placeholder="__('otherSpecify')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                  <ve-select
                                  :searchable="false"
                                  :placeholder="__('empty')"
                                  v-if="frequency"
                                  @on-select="function(option){s9Ot.frequency = option.value}"
                                  :options="frequency"
                                  :readonly="true"
                                />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-select
                                  :searchable="false"
                                  :placeholder="__('empty')"
                                  v-if="responsible"
                                  @on-select="function(option){s9Ot.responsible = option.value}"
                                  :options="responsible"
                                  :readonly="true"
                                />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-input 
                                  v-model="s9Ot.delivery"
                                  :placeholder="__('deliveryDates')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                <ve-input
                                  v-model="s9Ot.amount"
                                  type="number"                                   
                                  :placeholder="__('amount')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                <ve-input
                                  v-model="s9Ot.comments"
                                  :placeholder="__('comments')"
                                  />
                              </ve-form-group>
                            </ve-col>
                          </div>
                          <ve-col md6 xs12 >
                            <div  class="" style="padding:20px 40%;">
                              <div @click="removeOther('t3','s9Other')" class="btn-minus1">-</div>
                              <div @click="addOther('t3','s9Other')" class="btn-plus1">+</div>
                            </div>
                          </ve-col>

                        </div>
                      </div>
                       <h5 class="mt-3">{{__('tab3Sec10Head')}}</h5>
                      <div class="card border-0 shadow-sm">
                        <div class="card-body pb-0">
                          <div class="row">
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-label>{{ __('tab3Sec10Op1') }}</ve-label>
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                  <ve-select
                                  :searchable="false"
                                  :placeholder="__('empty')"
                                  v-if="frequency"
                                  @on-select="function(option){ud.t3.s10r1.frequency = option.value}"
                                  :options="frequency"
                                  :readonly="true"
                                />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-select
                                  :searchable="false"
                                  :placeholder="__('empty')"
                                  v-if="responsible"
                                  @on-select="function(option){ud.t3.s10r1.responsible = option.value}"
                                  :options="responsible"
                                  :readonly="true"
                                />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-input 
                                  v-model="ud.t3.s10r1.delivery"
                                  :placeholder="__('deliveryDates')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                <ve-input
                                  v-model="ud.t3.s10r1.amount"
                                  type="number"                                   
                                  :placeholder="__('amount')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                <ve-input
                                  v-model="ud.t3.s10r1.comments"
                                  :placeholder="__('comments')"
                                  />
                              </ve-form-group>
                            </ve-col>
                          </div>
                           <div class="row" v-for="(s10Ot, index) in ud.t3.s10Other" v-bind:key="index">
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-input 
                                  v-model="s10Ot.moment"
                                  :placeholder="__('otherSpecify')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                  <ve-select
                                  :searchable="false"
                                  :placeholder="__('empty')"
                                  v-if="frequency"
                                  @on-select="function(option){s10Ot.frequency = option.value}"
                                  :options="frequency"
                                  :readonly="true"
                                />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-select
                                  :searchable="false"
                                  :placeholder="__('empty')"
                                  v-if="responsible"
                                  @on-select="function(option){s10Ot.responsible = option.value}"
                                  :options="responsible"
                                  :readonly="true"
                                />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-input 
                                  v-model="s10Ot.delivery"
                                  :placeholder="__('deliveryDates')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                <ve-input
                                  v-model="s10Ot.amount"
                                  type="number"                                   
                                  :placeholder="__('amount')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                <ve-input
                                  v-model="s10Ot.comments"
                                  :placeholder="__('comments')"
                                  />
                              </ve-form-group>
                            </ve-col>
                          </div>
                          <ve-col md6 xs12 >
                            <div  class="" style="padding:20px 40%;">
                              <div @click="removeOther('t3','s10Other')" class="btn-minus1">-</div>
                              <div @click="addOther('t3','s10Other')" class="btn-plus1">+</div>
                            </div>
                          </ve-col>

                        </div>
                      </div>
    </div>
</template>

<script>

export default {
    name: "ve-tab-accounting",
    props: {
        responsible : {
            type : Array,
            default: null
        },
        frequency : {
            type : Array,
            default: null
        },
        ud : {
            type: Object,
            default: () => { return {} }
        },
    },
    data() {
        return {
            validation_error: false
        }
    },
    methods: {
        addOther(tab,sec){
			this.ud[tab][sec].push({
						moment : null,
						frequency : null,
						responsible: null,
						delivery: null,
						amount : null,
						comments: null
					})
		},
		removeOther(tab,sec){
			this.ud[tab][sec].splice(this.ud[tab][sec].length-1, 1)
		},
        handleInput(e) {

        },
    },
    created() {
    }
}
</script>

<style lang="scss" scoped>


</style>
