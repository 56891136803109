<template>
    <div>
        <div class="no-page-break page-break">
            <h3 style="margin: 0;text-align: center;">Bilaga 1: FAR:s Allmänna villkor om rådgivning och andra tjänster (2018:1)</h3>
            <ve-col md12 xs12 >                     <img :src="'./far-logo.png'" width="150px">                 </ve-col>
            <h3 style="margin: 0;">Allmänna villkor om rådgivning och andra tjänster</h3>
            <ve-row>
                <ve-col md6 xs12 class="atcDiv">
                    <span style="margin-bottom: 3px;">version 2018:1 – 1 mars 2018</span><br>
                    <h3 style="margin-bottom: 2px;">1 Allmänt</h3>
                    <span style="margin-bottom: 3px;"><b><i>Tillämpning</i></b><br><b>1.1</b> Dessa allmänna villkor gäller för uppdrag avseende redovisnings-, rådgivnings- och andra granskningstjänster som inte utgör lagstadgad revision eller lagstadgade tilläggsuppdrag ("Uppdraget") som uppdragstagaren ("Uppdragstagaren") åtar sig att utföra för uppdragsgivarens ("Uppdragsgivaren") räkning. </span><br>
                    <span style="margin-bottom: 3px;"><b><i>Uppdragsavtal</i></b><br>
                    <b>1.2</b> Dessa allmänna villkor utgör tillsammans med uppdragsbrevet ("Uppdragsbrevet"), eller annan skriftlig överenskommelse, uppdragsavtalet ("Uppdragsavtalet") mellan Uppdragstagaren och Uppdragsgivaren (tillsammans "Parterna"). </span><br>

                    <span style="margin-bottom: 3px;"><b><i>Företrädesordning</i></b><br>
                    <b>1.3</b> Vid eventuella motstridigheter mellan å ena sidan Uppdragsbrevet eller annan skriftlig överenskommelse och å andra sidan dessa allmänna villkor ska Uppdragsbrevet eller annan skriftlig överenskommelse ha företräde. </span><br>

                    <h3 style="margin-bottom: 2px;">2 Uppdragets omfattning och utförande </h3>
                    <span style="margin-bottom: 3px;"><b><i>Uppdragsbrev</i></b><br>
                    <b>2.1</b> Uppdragets närmare omfattning, inriktning och eventuell tillämplig standard regleras i Uppdragsbrevet eller annan skriftlig överenskommelse.  </span><br>

                    <span style="margin-bottom: 3px;"><b><i>Ändringar och tillägg</i> </b><br>
                    <b>2.2</b> Ändringar av och tillägg till ett befintligt Uppdragsavtal gäller endast om de bekräftats skriftligen av Parterna. </span><br>

                    <h3 style="margin-bottom: 2px;">3 Parternas åtaganden  </h3>
                    <span style="margin-bottom: 3px;"><b><i>Uppdragstagarens åtagande </i></b><br>
                    <b>3.1</b> Uppdragstagaren ska utföra Uppdraget med sådan skicklighet och omsorg som följer av tilllämpliga lagar, förordningar och föreskrifter samt för Uppdragstagaren gällande god yrkessed i branschen.  </span><br>

                    <span style="margin-bottom: 3px;"><b><i>Ändringar och tillägg </i></b><br>
                    <b>3.2</b> Uppdragstagaren ansvarar inte för slutsatser eller rekommendationer baserade på felaktig eller bristfällig information från Uppdragsgivaren eller tredje man som</span><br>
                </ve-col>
                <ve-col md6 xs12>
                    <p>Uppdragsgivaren anvisat. Om det finns uppenbara skäl för Uppdragstagaren att anta att tillhandahållen information är felaktig eller bristfällig, ska Uppdragsgivaren skyndsamt informeras om detta. </p>
                    
                    <p><b><i>Uppdragsgivarens åtagande </i></b><br>
                    <b>3.3</b> En övergripande förutsättning för Uppdragets utförande är att Uppdragsgivaren har erforderliga resurser för att i verksamheten kunna fatta affärsmässigt välgrundade beslut och för att kunna ta ansvar för användningen av resultatet av Uppdraget. Uppdragsgivaren ansvarar således för att Uppdragsgivarens beslut fattas på underlag av tillräcklig omfattning och kvalitet och för bedömningen av att resultatet av Uppdraget är lämpligt för Uppdragsgivarens syften. Det är också Uppdragsgivarens ansvar att verksamheten i stort bedrivs i enlighet med gällande lagar och bestämmelser.  </p>

                    <p>
                    <b>3.4</b> Uppdragsgivaren ska på begäran av Uppdragstagaren utan dröjsmål tillhandahålla sådan komplett och korrekt information som behövs för Uppdragets genomförande. Om Uppdragsgivaren, eller tredje man som Uppdragsgivaren anvisat, dröjer med att tillhandahålla information och material eller inte vidtar åtgärder som krävs för Uppdragets utförande, kan detta orsaka förseningar och ökade kostnader. Uppdragstagaren ansvarar inte för sådana förseningar och ökade kostnader, oberoende av om det är fråga om ökade arvodeskostnader eller andra kostnader.   </p>

                    <p>
                    <b>3.5</b> Om inte Uppdragsgivaren och Uppdragstagaren särskilt har kommit överens om något annat i Uppdragsavtalet, baseras Uppdraget på den information och det material som Uppdragsgivaren tillhandahåller.  </p>

                    <p>
                    <b>3.6</b> Om Uppdraget inte ska resultera i ett bestyrkande enligt en särskilt angiven granskningsstandard utgår Uppdragstagaren från att informationen och materialet är korrekt och fullständigt, vilket innebär att Uppdragstagaren inte självständigt verifierar den information och det material som lämnats.  </p>

                    <p>
                    <b>3.7</b> Under Uppdragets utförande ska Uppdragsgivaren se till att Uppdragstagaren utan dröjsmål får löpande information om eventuella förändringar i förutsättningarna för Uppdraget.  </p>
                </ve-col>
            </ve-row>
        </div>
        <div class="no-page-break page-break">
            <ve-col md12 xs12 >                     <img :src="'./far-logo.png'" width="150px">                 </ve-col>
            <ve-row>
                <ve-col md6 xs12>
                    <p><b>3.8</b> För att Uppdragstagaren ska kunna genomföra Uppdraget inom de uppsatta tidsramarna eller i övrigt inom rimlig tid och med bibehållen kvalitet, ska Uppdragsgivaren säkerställa att Uppdragsgivarens personal finns tillgänglig för att ge Uppdragstagaren den assistans som Uppdragstagaren rimligen kan begära.</p>
                    <p>
                    <b>3.9</b> Om Uppdragstagaren behöver tillträde till Uppdragsgivarens lokaler för att kunna utföra Uppdraget, ska Uppdragsgivaren tillhandahålla de lokaler och de andra resurser som Uppdragstagaren rimligen kan begära. Uppdragstagaren åtar sig att efter skriftliga anvisningar från Uppdragsgivaren följa de säkerhetsföreskrifter som gäller för användningen av Uppdragsgivarens datautrustning samt för att få tillgång till datanätverk och/eller lokaler. </p>

                    <h3>4 Åtgärder enligt lagen om penningtvätt och finansiering av terrorism </h3>
                    <p>
                    <b>4.1</b> Uppdragstagaren har enligt lagen (2017:630) om åtgärder mot penningtvätt och finansiering av terrorism en skyldighet att inför antagandet av Uppdraget identifiera Uppdragsgivarens företrädare och varje fysisk person som, direkt eller indirekt, äger mer än 25 procent av Uppdragsgivaren eller av annan anledning har ett bestämmande inflytande över Uppdragsgivaren ("Verklig huvudman") samt inhämta och bevara relevant dokumentation om detta. Uppdragsgivaren ska utan dröjsmål lämna den information som Uppdragstagaren efterfrågar och informera Uppdragstagaren om de förändringar som sker vad gäller Uppdragsgivarens företrädare och Verklig huvudman i förhållande till den information som lämnats till Uppdragstagaren inför antagandet av Uppdraget.  </p>

                    <p>
                    <b>4.2</b> Uppdragstagaren ska i enlighet med lag, i den omfattning som är motiverad med beaktande av identifierade risker för penningtvätt och finansiering av terrorism, kontrollera och dokumentera att de transaktioner som utförs stämmer överens med den kunskap som Uppdragstagaren har om Uppdragsgivaren, dess affärs- och riskprofil och, om det behövs, varifrån dess ekonomiska medel kommer. Vid misstankar om penningtvätt eller finansiering av terrorism är Uppdragstagaren skyldig att rapportera misstankarna till Polismyndigheten. Uppdragstagaren kan inte hållas ansvarig för eventuell skada som förorsakats </p>
                </ve-col>
                <ve-col md6 xs12>
                    <p>Uppdragsgivaren, direkt eller indirekt, till följd av att Uppdragstagaren fullföljer sin lagstadgade skyldighet. </p>
                    <h3>5 Behandling av personuppgifter</h3>
                    <p>
                    <b>5.1</b> Inför antagandet av och med anledning av Uppdraget kan Uppdragstagaren, eventuella nätverksföretag eller annan som anlitas av Uppdragstagaren, komma att insamla, använda, överföra, lagra eller på annat sätt behandla information som kan avse en identifierad eller identifierbar fysisk person ("Personuppgifter") i de jurisdiktioner de bedriver verksamhet. Uppdragstagaren åtar sig att behandla Personuppgifterna i enlighet med de krav som följer av gällande rätt. </p>
                    <h3>6 Avrapportering m.m. </h3>
                    <p><b><i>Avrapportering </i></b><br>
                    <b>6.1</b> Om Uppdraget innefattar leverans av dokument fullgör Uppdragstagaren Uppdraget genom att leverera slutdokument (i pappersform och/eller elektronisk form) till Uppdragsgivaren i enlighet med vad som har överenskommits i Uppdragsavtalet. Dokumenten kan utgöra råd och rekommendationer i rapporter, mötesanteckningar, korrespondens, utlåtanden samt andra handlingar upprättade på Uppdragsgivarens begäran. </p>

                    <p><b><i>Muntliga råd  </i></b><br>
                    <b>6.2</b> Under Uppdragets utförande och på begäran av Uppdragsgivaren kan Uppdragstagaren komma att muntligen (vid telefonsamtal eller möten) och mer informellt lämna svar på direkta frågor eller i övrigt lämna kommentarer. Eftersom detta kan innebära en kort kommentar till ett komplicerat problem där Uppdragstagaren inte har tillgång till fullständig och korrekt information, blir Uppdragstagaren inte ansvarig förrän svaret eller kommentaren bekräftats skriftligen. </p>

                    <p><b><i>Utkast till dokument </i></b><br>
                    <b>6.3</b> Utkast till dokument som Uppdragstagaren tillhandahåller Uppdragsgivaren utgör inte Uppdragstagarens slutliga ställningstagande. Uppdragstagaren ansvarar inte för eventuella skador som kan uppkomma på grund av att Uppdragsgivaren i något avseende har förlitat sig på ett utkast.</p>
                </ve-col>
            </ve-row>
        </div>
        <div class="no-page-break page-break">
            <ve-col md12 xs12 >                     <img :src="'./far-logo.png'" width="150px">                 </ve-col>
            <ve-row>
                <ve-col md6 xs12>
                    <h3>7 Uppdragets bemanning </h3>
                    <p><b><i>Egen personal</i></b><br>
                    <b>7.1</b> Uppdragstagaren åtar sig att bemanna Uppdraget med personal på ett sådant sätt att åtagandet enligt punkterna 3.1 och 3.2 uppfylls. Om Parterna i Uppdragsavtalet har kommit överens om vilka medarbetare som ska ingå i Uppdragets team får Uppdragstagaren byta ut medarbetare om detta inte negativt påverkar Uppdragstagarens åtagande enligt punkt 3.1, ökar kostnaderna för Uppdragsgivaren eller medför att en eventuell tidsplan i något väsentligt hänseende rubbas.</p>
                    <p><b><i>Underkonsulter</i></b><br>
                    <b>7.2</b> Uppdragstagaren har rätt att anlita underkonsulter förutsatt att dessa uppfyller Uppdragstagarens åtagande enligt punkt 3.1. Uppdragstagaren svarar för underkonsultens arbete som sitt eget och har rätt till ersättning för arbete som utförts av underkonsulten. Uppdragstagaren åtar sig också att ansvara för att underkonsulten följer de riktlinjer och åtaganden i övrigt som gäller för Uppdragstagaren gentemot Uppdragsgivaren. </p>

                    <h3>8 Rekrytering </h3>
                    <p>
                    <b>8.1</b> Parterna förbinder sig att under den tid som Uppdraget pågår och sex (6) månader efter att det upphört inte – vare sig direkt eller indirekt genom någon annan – verka för att anställa någon hos respektive Part som varit involverad i Uppdraget.   </p>

                    <h3>9 Arvode, utlägg m.m. </h3>
                    <p><b><i>Allmänt</i></b><br>
                    <b>9.1</b> Om inte något annat särskilt avtalats i Uppdragsavtalet ska nedanstående gälla för arvode, utlägg och kostnader, tillkommande skatter och avgifter m.m. </p>

                    <p><b><i>Beräkning av arvode </i></b><br>
                    <b>9.2</b> Arvodet för Uppdraget kommer att debiteras enligt de beräkningsgrunder som anges i Uppdragsavtalet. Om sådana beräkningsgrunder saknas, kommer Uppdragstagaren att debitera ett skäligt arvode och då ta hänsyn till bl.a. resursåtgång inklusive specialistkunskap, komplexitet i Uppdraget, forskning och know-how som ut </p>
                </ve-col>
                <ve-col md6 xs12>
                    <p>vecklats av Uppdragstagaren, användning av teknik och strukturkapital. Hänsyn kommer även att tas till om Uppdraget varit så brådskande att arbetet behövt utföras efter ordinarie arbetstid, på helger eller under semesterperioder. </p>
                    <p>
                    <b>9.3</b> Om Uppdragsavtalet anger ett beräknat arvode, ska Uppdragstagaren underrätta Uppdragsgivaren om det beräknade arvodet väsentligt kommer att överskridas.  </p>
                    <p>
                    <b>9.4</b> Ökade arbetsinsatser som är en följd av att Uppdragsgivaren brustit i sina åtaganden, till exempel genom att tillhandahålla ofullständig eller bristfällig information alternativt ofullständigt eller bristfälligt material eller tillhandahållit informationen eller materialet för sent, ingår inte i det beräknade arvodet.  </p>
                    
                    <p><b><i>Prisjustering, utlägg och skatter  </i></b><br>
                    <b>9.5</b> Uppdragstagaren har rätt att för löpande uppdrag göra sådana prisjusteringar som kan hänföras till allmänna pris- eller kostnadsförändringar.</p>

                    <p>
                    <b>9.6</b> Utöver arvode har Uppdragstagaren rätt till ersättning för utlägg och kostnader i samband med Uppdraget, såsom bl.a. ansöknings- och registreringsavgifter samt utlägg för resor, kost och logi. </p>

                    <p>
                    <b>9.7</b> Uppdragsgivaren ska betala Uppdragstagaren den mervärdesskatt eller skatt av annat slag som ska tas ut med anledning av Uppdraget. </p>

                    <h3>10 Fakturering och betalningsvillkor m.m.  </h3>
                    <p><b><i>Fakturering och betalningsvillkor  </i></b><br>
                    <b>10.1</b> Om inte något annat särskilt avtalats i Uppdragsbrevet eller annan skriftlig överenskommelse ska följande gälla. Uppdragstagaren fakturerar Uppdragsgivaren löpande baserat på det arbete som utförts och de utlägg som gjorts. Uppdragstagaren kan alternativt fakturera Uppdragsgivaren a conto månadsvis eller kvartalsvis baserat på uppskattat arvode för Uppdraget. Uppdragsgivaren ska betala senast tio (10) dagar från fakturadatum. Vid försenad betalning tas dröjsmålsränta ut enligt räntelagen (1975:635).  </p>
                    <p>
                    <b>10.2</b> Uppdragstagaren ska alltid underrätta Uppdragsgivaren om eventuella förändringar i faktureringsvillkoren. </p>
                </ve-col>
            </ve-row>
        </div>
        <div class="no-page-break page-break">
            <ve-col md12 xs12 >                     <img :src="'./far-logo.png'" width="150px">                 </ve-col>
            <ve-row>
                <ve-col md6 xs12>
                    <p><b><i>Försenad betalning </i></b><br>
                    <b>10.3</b> Om Uppdragsgivaren inte betalar en fordran i rätt tid, har Uppdragstagaren rätt att omedelbart avbryta Uppdraget till dess den utestående fordran är till fullo betald, och Uppdragstagaren är då fri från ansvar för en försening eller någon annan skada som kan bli följden av avbrottet. Uppdragstagaren har rätt att säga upp Uppdragsavtalet med omedelbar verkan om Uppdragsgivaren efter mer än trettio (30) dagar efter betalningspåminnelse ännu inte betalat. Motsvarande gäller om Uppdragsgivaren inte i tid betalar för något annat uppdrag som Uppdragstagaren utfört.</p>

                    <h3>11 Tystnadsplikt  </h3>
                    <p><b><i>Parternas tystnadsplikt </i></b><br>
                    <b>11.1</b> Respektive Part förbinder sig att inte lämna konfidentiell information om Uppdraget till utomstående, inte heller information om den andra Partens verksamhet och angelägenheter, utan den andra Partens skriftliga samtycke. Uppdragstagaren äger dock rätt att lämna konfidentiell information, i den mån det erfordras för att Uppdraget ska kunna utföras. Uppdragstagaren svarar för att eventuella underkonsulter är bundna av denna tystnadsplikt. </p>

                    <p><b><i>Konfidentiell information  </i></b><br>
                    <b>11.2</b> Med konfidentiell information avses varje råd och upplysning, i muntlig eller skriftlig form, av teknisk, ekonomisk eller kommersiell art som utväxlats mellan Parterna i samband med Uppdraget eller som någon av Parterna på något annat sätt får kännedom om med anledning av Uppdraget, med undantag för sådana råd och upplysningar som </p>

                    <ul>
                        <li>är allmänt kända eller kommer till allmän kännedom på något annat sätt än genom brott mot tystnadsplikten ovan, eller</li>
                        <li>en Part tagit emot från tredje man som inte är bunden av tystnadsplikten ovan eller mottagande Part självständigt tagit fram eller redan känner till. </li>
                    </ul>
                </ve-col>
                <ve-col md6 xs12>
                    <p><b><i>Undantag från tystnadsplikten   </i></b><br>
                    <b>11.3</b> Parterna har rätt och ibland skyldighet att lämna konfidentiell information till tredje man om det följer av författning, professionell skyldighet eller myndighetsbeslut. </p>
                    <p>
                    <b>11.4</b> Uppdragstagaren har vidare rätt att lämna konfidentiell information till  </p>
                    <ul>
                        <li>FAR eller annan som anlitas av FAR i samband med disciplinärenden och kvalitetskontroller som inte följer av författning, </li>
                        <li>eventuella nätverksföretag eller annan som anlitas av Uppdragstagaren för utförandet av Uppdraget,  </li>
                        <li>försäkringsbolag eller juridiska rådgivare inför eller i samband med ett rättsligt förfarande i den mån det krävs för att Uppdragstagaren ska kunna ta tillvara sina rättmätiga intressen, </li>
                        <li>eventuella nätverksföretag eller annan som anlitas av Uppdragstagaren i syfte att kontrollera Uppdragstagarens eventuella krav på opartiskhet och självständighet samt utföra kvalitetskontroller och övriga risk management-åtgärder, och  </li>
                        <li>eventuella nätverksföretag eller annan som anlitas av Uppdragstagaren i administrativt syfte (såsom internredovisning, finansiell rapportering, nyttjande av gemensamma ITresurser och/eller för att utföra administrativa stödtjänster).  </li>
                    </ul>
                    <h3>12 Nyttjanderätt och immateriella rättigheter </h3>
                    <p><b><i>Nyttjanderätt till resultat  </i></b><br>
                    <b>12.1</b> Uppdragsgivaren har inte rätt att till tredje man sprida eller låta tredje man använda sig av eller förlita sig på material som tillhandahålls av Uppdragstagaren inom ramen för Uppdraget, till exempel slut- och delrapporter, men även övrigt material, som tas fram under Uppdraget ("Resultatet"), i vidare mån än vad som följer av Uppdragsavtalet. </p>
                </ve-col>
            </ve-row>
        </div>
        <div class="no-page-break page-break">
            <ve-col md12 xs12 >                     <img :src="'./far-logo.png'" width="150px">                 </ve-col>
            <ve-row>
                <ve-col md6 xs12>
                    <p>
                    <b>12.2</b> Uppdragstagaren får utan begränsning i tid och rum återanvända eller nyttja innehållet i Resultatet av Uppdraget i andra uppdrag, förutsatt att åtagandena i avsnitt 11 inte åsidosätts. </p>
                    <p>
                    <b>12.3</b> Punkterna 12.1 och 12.2 gäller inte när Resultatet utgör räkenskapsinformation.  </p>
                    <p><b><i>Immateriella rättigheter </i></b><br>
                    <b>12.4</b> Uppdragstagaren behåller äganderätten till alla immateriella rättigheter, såväl sådana som Uppdragstagaren innehar före Uppdraget som sådana som utvecklas under Uppdraget. Uppdragsgivaren får dock använda sig av immateriella rättigheter ägda eller utvecklade av Uppdragstagaren för att kunna tillgodogöra sig Resultatet av Uppdraget, men inte i vidare mån än vad som följer av punkterna 12.1–12.3. </p>
                    <p>
                    <b>12.5</b>  Om Uppdraget omfattar eller berör immateriella rättigheter som Uppdragsgivaren innehar och som under Uppdragets gång modifieras eller utvecklas genom insatser från Uppdragstagaren, behåller Uppdragsgivaren äganderätten till dessa rättigheter fullt ut. Uppdragstagaren får dock – om inte något annat avtalas i Uppdragsavtalet – återanvända eller nyttja den kunskap och knowhow som Uppdragstagaren genom sina insatser tillfört Uppdragsgivaren, förutsatt att åtagandena i avsnitt 11 inte åsidosätts.  </p>

                    <p>
                    <b>12.6</b> Ingendera Part får använda den andra Partens varumärken, logotyp eller andra kännetecken utan uttryckligt och skriftligt samtycke.  </p>

                    <h3>13 Intressekonflikter </h3>
                    <p><b><i>Parternas tystnadsplikt </i></b><br>
                    <b>13.1</b> Parterna åtar sig att såväl innan Uppdragsavtalet träffats som under avtalstiden vidta rimliga åtgärder för att undersöka att inte Uppdraget ger upphov till en intressekonflikt som kan äventyra Uppdragets fullgörande. Vardera Part åtar sig att informera den andra Parten om ändrade förhållanden. Uppdragstagaren måste ges möjlighet att beakta eventuella tvingande regler och andra standarder inom revisions- och rådgivningsbranschen, såsom IESBAs Etikkod. Parterna är överens om att det inte är möjligt att upptäcka och förutse alla de intressekonflikter som eventuellt finns när Uppdragsavtalet träffas eller som, enligt någondera Parts uppfattning, uppstår därefter.</p>
                </ve-col>
                <ve-col md6 xs12>
                    <p> Uppdragsgivaren är medveten om att Uppdragstagaren är bunden av tystnadsplikt rörande uppdrag för andra kunder och att bakgrunden till en intressekonflikt därför ibland inte kan lämnas. Då någondera Part (eller båda) anser att det finns en intressekonflikt, ska dock Parterna efter bästa förmåga försöka finna former för att lösa frågan för att Uppdraget ska kunna fullföljas. </p>
                    <h3>14 Giltighetstid och uppsägning  </h3>
                    <p><b><i>Uppdragsavtalets giltighetstid </i></b><br>
                    <b>14.1</b> Uppdragsavtalet börjar gälla från den dag som anges i Uppdragsavtalet eller från den dag Uppdraget påbörjas om något startdatum inte anges. Om inget annat avtalats gäller Uppdragsavtalet fram till dess Uppdraget avslutats. En Part får, om inget annat avtalats, genom skriftligt meddelade säga upp Uppdragsavtal som gäller tillsvidare med tre (3) månaders uppsägningstid. </p>
                    <p><b><i>Uppsägning – avtalsbrott  </i></b><br>
                    <b>14.2</b> En Part får genom ett skriftligt meddelande säga upp Uppdragsavtalet med omedelbar verkan, om den andra Parten bryter mot villkoren enligt Uppdragsavtalet, avvikelsen är av väsentlig betydelse och en möjlig rättelse inte har skett inom trettio (30) dagar från det att Parten skriftligen begärt rättelse.  </p>
                    <p>
                    <b>14.3</b> Uppdragstagaren får även genom ett skriftligt meddelande säga upp Uppdragsavtalet med omedelbar verkan om Uppdragsgivaren inte fullföljer sina åtaganden enligt punkterna 3.3–3.9 eller på annat sätt inte medverkar till att Uppdraget kan utföras i enlighet med Uppdragsavtalet och rättelse inte har skett inom trettio (30) dagar från det att Uppdragstagaren skriftligen begärt rättelse. Någon begäran om rättelse behöver dock inte lämnas om det skäligen kan antas att Uppdragsgivaren inte kommer att vida rättelse med anledning av Uppdragstagarens begäran.  </p>
                    <p><b><i>Uppsägning – obestånd m.m.  </i></b><br>
                    <b>14.4</b> En Part får genom ett skriftligt meddelande säga upp Uppdragsavtalet med omedelbar verkan, om den andra Parten inte kan betala sina skulder eller har konkursförvaltare, företagsrekonstruktör eller likvidator utsedd eller om det finns skäl att anta att något sådant kommer att inträffa.  </p>
                </ve-col>
            </ve-row>
        </div>
        <div class="no-page-break page-break">
            <ve-col md12 xs12 >                     <img :src="'./far-logo.png'" width="150px">                 </ve-col>
            <ve-row>
                <ve-col md6 xs12>
                    <p><b><i>Uppsägning – opartiskhet eller självständighet  </i></b><br>
                    <b>14.5</b> En Part får genom ett skriftligt meddelande säga upp Uppdragsavtalet med omedelbar verkan, om det visar sig att Uppdraget kan komma att hota Uppdragstagarens opartiskhet eller självständighet i egenskap av revisor för Uppdragsgivaren eller någon annan revisionskund och hotet inte går att reducera till en acceptabel nivå genom andra åtgärder. </p>
                    <p><b><i>Uppsägning – redovisningsuppdrag (enligt Reko)  </i></b><br>
                    <b>14.6</b> Uppdragstagaren får genom ett skriftligt meddelande säga upp ett Uppdragsavtal som avser redovisningsuppdrag med omedelbar verkan om det visar sig att redovisningen kan antas innehålla väsentliga fel, brister eller oegentligheter och rättelse inte har skett inom trettio (30) dagar från det att Uppdragstagaren skriftligen begärt rättelse. Någon begäran om rättelse behöver dock inte lämnas om redovisningen innehåller oegentligheter eller om det skäligen kan antas att Uppdragsgivaren inte kommer att vidta rättelse med anledning av Uppdragstagarens begäran.  </p>
                    <p><b><i>Uppsägning – arvode  </i></b><br>
                    <b>14.7</b> Vid uppsägning av Uppdragsavtalet ska Uppdragsgivaren betala Uppdragstagaren arvode, utlägg, kostnader för underkonsulter och andra kostnader enligt Uppdragsavtalet som Uppdragstagaren enligt Uppdragsavtalet är berättigad till fram till upphörandetidpunkten. Om uppsägningen inte görs enligt punkt 14.4 eller 14.5 eller om den görs av Uppdragsgivaren och inte grundar sig på ett väsentligt avtalsbrott från Uppdragstagarens sida, ska Uppdragsgivaren även ersätta Uppdragstagaren för andra rimliga kostnader som uppstått i samband med Uppdraget. Till sådana kostnader räknas bl.a. särskilda investeringar som föranleds av Uppdraget och särskilda avvecklingskostnader till följd av att Uppdragsavtalet upphört i förtid. Uppdragstagaren ska vidta skäliga åtgärder för att så långt möjligt begränsa sådana kostnader. </p>
                </ve-col>
                <ve-col md6 xs12>
                    <h3>15 Ansvar   </h3>
                    <p><b><i>Force majeure  </i></b><br>
                    <b>15.1</b> Uppdragstagaren är inte ansvarig för en skada som beror på svensk eller utländsk lag eller myndighetsåtgärd, krigshändelse, terrorism, ITattacker, strejk, blockad, bojkott, lockout, brand eller annan olycka, naturkatastrof eller störningar i IT-, tele- eller eldistributionen eller någon annan liknande omständighet som inte kunnat förutses. Ifråga om strejk, blockad, bojkott och lockout gäller förbehållet även om Uppdragstagaren själv är föremål för eller vidtar en sådan konfliktåtgärd. </p>
                    <p><b><i>Lagändringar m.m.   </i></b><br>
                    <b>15.2</b> Uppdragstagaren genomför Uppdraget i enlighet med gällande regler och utifrån förståelsen av tillämpliga författningstolkningar och domslut vid den tidpunkt som Uppdraget eller en del av Uppdraget genomförs. Uppdragstagaren har inget ansvar för följderna av författningsändringar eller omtolkningar som görs efter det datum som Uppdragstagaren avrapporterat Uppdraget eller en relevant del av Uppdraget.  </p>
                    <p><b><i>Tredjemansanspråk</i></b><br>
                    <b>15.3</b> Om inte något annat avtalats i Uppdragsavtalet är resultatet av Uppdraget avsett att endast användas av Uppdragsgivaren, och därför accepterar Uppdragstagaren inte något ansvar gentemot tredje man eller någon utomstående som försöker utnyttja, dra fördel av eller förlita sig på det arbete som Uppdragstagaren utfört i Uppdraget.  </p>
                    <p>
                    <b>15.4</b> Uppdragstagaren ska hållas skadeslös av Uppdragsgivaren för varje form av krav på ersättning som tredje man riktar mot Uppdragstagaren – innefattande Uppdragstagarens egna kostnader på grund av tredjemanskrav – till följd av att Uppdragsgivaren gjort Resultatet av Uppdraget, eller någon del därav, tillgängligt för tredje man. Uppdragsgivaren blir dock inte ansvarig för tredjemanskrav, om det kan visas att den aktuella skadan uppstått på grund av att Uppdragstagaren uppsåtligen har handlat felaktigt eller förfarit grovt vårdslöst. </p>
                </ve-col>
            </ve-row>
        </div>
        <div class="no-page-break page-break">
            <ve-col md12 xs12 >                     <img :src="'./far-logo.png'" width="150px">                 </ve-col>
            <ve-row>
                <ve-col md6 xs12>
                    <p><b><i>Begränsning av ansvar   </i></b><br>
                    <b>15.5</b> Skador i andra fall än de som avses i punkterna 15.1 och 15.2 ska Uppdragstagaren ersätta endast om Uppdragstagaren förfarit oaktsamt. Uppdragstagaren är inte i något fall ansvarig för bortfall av produktion, utebliven vinst eller någon annan indirekt skada eller följdskada av något som helst slag. Uppdragstagaren är inte heller ansvarig under de förhållanden som framgår av punkterna 6.2 och 6.3. </p>
                    <p><b><i>Maximalt ersättningsbelopp  </i></b><br>
                    <b>15.6</b> Uppdragstagarens ansvar för alla skador, förluster, kostnader och utgifter i Uppdraget är begränsat till det högsta av antingen två (2) gånger avtalat arvode för Uppdraget enligt Uppdragsavtalet eller tio (10) gånger det prisbasbelopp enligt socialförsäkringsbalken (2010:110) som gällde när Uppdragsavtalet träffades. För Uppdrag som löpt under längre tid än tolv (12) månader ska avtalat arvode för Uppdraget utgöras av det genomsnittliga årsarvodet. Det genomsnittliga årsarvodet uppgår till det sammanlagda arvode som utgått för tjänsterna under uppdragstiden, dividerat med antalet månader som förflutit under uppdragstiden, multiplicerat med tolv. Uppdragstiden är tiden från Uppdragets början till tidpunkten för den omständighet på vilken Uppdragsgivaren grundar sin talan. Begränsningen gäller dock inte om det visas att Uppdragstagaren förorsakat skadan genom uppsåt eller grov vårdslöshet.   </p>
                    <p><b><i>Reklamation </i></b><br>
                    <b>15.7</b> Uppdragsgivaren ska utan dröjsmål skriftligen till Uppdragstagaren reklamera sådana fel eller brister i utförandet av Uppdraget eller en del av Uppdraget som Uppdragsgivaren upptäcker eller bort upptäcka. Reklamationen ska innehålla tydliga uppgifter om felets eller bristens art och omfattning. Efter en reklamation eller anmärkning ska Uppdragstagaren beredas tillfälle att inom skälig tid avhjälpa felet eller bristen – om detta är möjligt – innan Uppdragsgivaren kräver ersättning. Uppdragsgivarens rätt till skadestånd eller annan gottgörelse är förverkad om reklamationen inte görs inom skälig tid.</p>
                </ve-col>
                <ve-col md6 xs12>
                    <p>
                    <b>15.8</b> För att skadeståndskrav ska kunna göras gällande mot Uppdragstagaren, ska Uppdragsgivaren först göra en reklamation och därefter framställa skadeståndskraven skriftligen senast inom tolv (12) månader efter reklamationen.  </p>
                    <p><b><i>Försäkring</i></b><br>
                    <b>15.9</b>  Uppdragstagaren ska ha en för sin verksamhet anpassad professionsansvarsförsäkring.   </p>
                    <h3>16 Uppdragsdokumentation, meddelanden och elektronisk kommunikation </h3>
                    <p><b><i>Uppdragsdokumentation</i></b><br>
                    <b>16.1</b> Uppdragstagaren bevarar uppdragsdokumentationen i minst tio (10) år efter avslutat Uppdrag och förvarar den på sådant sätt att den inte blir tillgänglig för obehöriga. </p>
                    <p><b><i>Meddelanden</i></b><br>
                    <b>16.2</b> Reklamationer, uppsägningar och andra meddelanden avseende Uppdragsavtalets tilllämpning samt adressändringar ska översändas genom bud, rekommenderat brev eller e-post till Parternas senast angivna adresser. </p>
                    <p><b><i>Elektronisk kommunikation </i></b><br>
                    <b>16.3</b> Elektronisk överföring av information mellan Parterna kan inte garanteras vara säker eller fri från virus eller fel, och följaktligen kan sådan information obehörigen fångas upp, förvanskas, förloras, förstöras, försenas, komma fram i ofullständigt skick, skadas på något annat sätt eller vara riskabel att använda av någon annan anledning. Parterna är medvetna om att system och rutiner inte kan garantera att överföringar inte kommer att utsättas för risker, men båda Parter samtycker till att använda kommersiellt rimliga metoder för viruskontroll eller sekretess innan information skickas elektroniskt. Parterna accepterar dessa risker och godkänner elektronisk kommunikation dem emellan.</p>

                    <h3>17 Fullständigt avtal, partiell ogiltighet  </h3>
                    <p>
                    <b>17.1</b>Uppdragsavtalet utgör hela överenskommelsen mellan Parterna rörande Uppdraget. Det ersätter och upphäver varje tidigare förslag, korrespondens, överenskommelse eller annan kommunikation, skriftlig som muntlig. </p>
                </ve-col>
            </ve-row>
        </div>
        <div class="no-page-break page-break">
            <ve-col md12 xs12 >
                <img :src="'./far-logo.png'" width="150px">
            </ve-col>
            <ve-row>
                <ve-col md6 xs12>
                    <p>
                    <b>17.2</b>  Om någon bestämmelse i Uppdragsavtalet konstateras ogiltig, ska detta inte innebära att Uppdragsavtalet i sin helhet är ogiltigt. I den mån ogiltigheten påverkar en Parts rättigheter eller skyldigheter, ska istället skälig jämkning ske.</p>
                    <h3>18 Tillämplig lag   </h3>
                    <p>
                    <b>18.1</b> Svensk lag, med undantag för dess lagvalsregler, ska tillämpas på Uppdraget.  </p>
                    <h3>19 Tvister </h3>
                    <p>
                    <b>19.1</b> Tvister med anledning av Uppdraget ska avgöras av svensk allmän domstol.  </p>
                </ve-col>
                <ve-col md6 xs12>
                </ve-col>
            </ve-row>
        </div><br><br>
        <ve-row >
            <h2 style="margin: 0;">Bilaga 2 Personuppgiftsbiträdesavtal</h2>
            <p style="margin-top: 15px;">
                Detta avtal är en bilaga till Parternas avtal om redovisningstjänster (”Uppdragsavtalet”).<br><br>
                Personuppgiftsbiträdet kommer att behandla personuppgifter i samband med fullgörandet av Uppdragsavtalet för den Personuppgiftsansvariges räkning. Parterna har med anledning av detta överenskommit att i detta personuppgiftsbiträdesavtal reglera Personuppgiftsbiträdets behandling av personuppgifter för den Personuppgiftsansvariges räkning (”Biträdesavtalet”). <br><br>
                Om definitionen av ett begrepp inte anges i Biträdesavtalet ska det anses ha samma betydelse som i artikel 4 i Dataskyddsförordningen1. 
            </p>
            <h3>Den Personuppgiftsansvariges ansvar</h3>
            <p style="margin-top: 15px;">
                Den Personuppgiftsansvarige ansvarar för att behandlingen av personuppgifter sker enligt gällande rätt. Den Personuppgiftsansvarige får endast tillhandahålla Personuppgiftsbiträdet de personuppgifter som behövs för att utföra och administrera uppdraget. Den Personuppgiftsansvarige är ansvarig för att denne har rätt att överföra personuppgifter till Personuppgiftsbiträdet i samband med utförandet av tjänsterna enligt Uppdragsavtalet och att dessa personuppgifter har behandlats i enlighet med gällande rätt. <br><br>
                Ändamålet med, typ av behandling samt uppgift om kategorier av registrerade, kategorier av personuppgifter och mottagare av personuppgifter som omfattas av behandlingen samt de tekniska och organisatoriska skyddsåtgärder som ska vidtas anges i Bilaga A i den utsträckning som behövs för att Personuppgiftsbiträdet ska kunna tillhandahålla tjänsterna enligt Uppdragsavtalet. I händelse av att den Personuppgiftsansvarige avser ändra eller meddela nya instruktioner som väsentligt påverkar Personuppgiftsbiträdets utförande av tjänsterna ska Personuppgiftsbiträdet ha rätt att invända mot sådan ändring och Parterna äger då rätt att frånträda Biträdesavtalet och Uppdragsavtalet med omedelbar verkan. Den Personuppgiftsansvarige ska svara för alla kostnader och utgifter som Personuppgiftsbiträdet åsamkas med anledning av att den Personuppgiftsansvarige ändrar eller meddelar nya instruktioner. 
            </p>
            <h3>Personuppgiftsbiträdets ansvar</h3>
            <p style="margin-top: 15px;">
                Personuppgiftsbiträdet ska behandla personuppgifter i enlighet med den Personuppgiftsansvariges dokumenterade instruktioner som anges i Bilaga A, såvida inte annat följer av gällande rätt. Personuppgiftsbiträdet ska, när så är tillåtet, informera den Personuppgiftsansvarige om det rättsliga kravet att behandla personuppgifterna.<br><br>
                Personuppgiftsbiträdet ska omedelbart informera den Personuppgiftsansvarige när Personuppgiftsbiträdet anser att en instruktion enligt Biträdesavtalet eller andra instruktioner meddelade av den Personuppgiftsansvarige strider mot gällande rätt. Vad som anges ovan fråntar dock inte den Personuppgiftsansvarige från dennes ansvar för lämnade instruktioner och gäller endast om Personuppgiftsbiträdet inte är förhindrat att lämna sådan information enligt gällande rätt.<br><br>
                Personuppgiftsbiträdet ska i den mån det är möjligt bistå den Personuppgiftsansvarige med att besvara en registrerads begäran (såsom exempelvis begäran om registerutdrag eller rättelse/uppdatering av personuppgifter som rör den registrerade). För det fall en registrerad kontaktar Personuppgiftsbiträdet direkt med en sådan begäran ska Personuppgiftsbiträdet utan onödigt dröjsmål informera den Personuppgiftsansvarige om begäran. Personuppgiftsbiträdet får inte på egen hand besvara en registrerads begäran. <br><br>
                Personuppgiftsbiträdet ska underrätta den Personuppgiftsansvarige utan onödigt dröjsmål efter att ha fått vetskap om en personuppgiftsincident. En sådan underrättelse ska innehålla den information som den Personuppgiftsansvarige rimligen behöver för att kunna uppfylla sina rättsliga skyldigheter, inklusive en beskrivning av de åtgärder som Personuppgiftsbiträdet har vidtagit eller föreslår för att mildra potentiella negativa effekter. Personuppgiftsbiträdet ska bistå den Personuppgiftsansvarige vid fullgörande av dess skyldigheter i samband med en personuppgiftsincident.
            </p><br>
            <p style=""><i>
                Europaparlamentet och rådets förordning (EU) 2016/679 från den 27 april 2016 om skydd för fysiska personer i fråga om behandling av personuppgifter och om den fria rörligheten för sådana uppgifter och om upphävande av direktiv 95/46/EG
            </i></p>
        </ve-row>
        <ve-row >
            <h3>Tekniska och organisatoriska skyddsåtgärder</h3>
            <p style="margin-top: 15px;">
                Personuppgiftsbiträdet ska vidta lämpliga tekniska och organisatoriska åtgärder för att skydda de personuppgifter som behandlas enligt Biträdesavtalet. Åtgärderna ska anpassas till en lämplig nivå med beaktande av typerna av behandling, kategorierna av personuppgifter, särskilda risker samt kostnader för genomförandet. <br><br>
                Personuppgiftsbiträdet ska begränsa tillgången till personuppgifterna och endast ge behörighet till sådan personal som behöver ha tillgång till personuppgifterna för utförandet av Uppdragsavtalet. 
            </p>
            <h3>Granskning</h3>
            <p style="margin-top: 15px;">
                För det fall den Personuppgiftsansvarige begär att få granska att Personuppgiftsbiträdet fullgör sina skyldigheter enligt gällande rätt och Biträdesavtalet ska den Personuppgiftsansvarige skriftligen avisera sin begäran senast tio arbetsdagar dessförinnan. Sådan granskning ska inte störa Personuppgiftsbiträdets löpande verksamhet och ska inte heller innebära rätt att få ta del av information som omfattas av lagstadgad eller avtalad tystnadsplikt. Personuppgiftsbiträdet ska förse den Personuppgiftsansvarige med den information som rimligen krävs för att visa att skyldigheterna har fullgjorts. Den Personuppgiftsansvarige har rätt att anlita en utomstående för sådan granskning (”Granskare”), under förutsättning att Granskaren har åtagit sig eller annars är bunden av tystnadsplikt avseende all information som Granskaren får tillgång till vid granskningen. Alla kostnader som uppkommer för Personuppgiftsbiträdet i samband med granskningen ska bäras av den Personuppgiftsansvarige.
            </p>
            <h3>Anlitande av ett annat personuppgiftsbiträde</h3>
            <p style="margin-top: 15px;">
                Personuppgiftsbiträdet har rätt att anlita ett annat personuppgiftsbiträde för behandling av personuppgifter för den Personuppgiftsansvariges räkning (”Underbiträde”). Anlitande av ett Underbiträde medför inga ändringar i den ansvarsfördelning som gäller mellan Parterna. Personuppgiftsbiträdet ansvarar för Underbiträdens behandling av personuppgifter i samma utsträckning som Personuppgiftsbiträdets egen behandling enligt Biträdesavtalet. <br><br>
                <!-- Utöver vad som framgår nedan kan Thorell Revision AB komma att anlita andra företag inom Baker Tilly nätverket för exempelvis rådfrågning inom skatt, moms eller andra specialistområden.
                <ul>
                    <li>Fortnox -bokföring, fakturering och lönehantering online, tel 0470-78 50 00</li>
                </ul> -->
                Personuppgiftsbiträdet ska informera den Personuppgiftsansvarige om sin avsikt att anlita nya eller ersätta befintliga Underbiträden. Den Personuppgiftsansvarige äger rätt att motsätta sig en sådan ändring inom 20 arbetsdagar från det att informationen lämnades. Om Personuppgiftsbiträdet trots detta väljer att ersätta eller anlita ett nytt Underbiträde, äger den Personuppgiftsansvarige rätt att frånträda Biträdesavtalet och Uppdragsavtalet, dock senast 20 arbetsdagar efter att Personuppgiftsbiträdet har informerat den Personuppgiftsansvarige om att Underbiträdet kommer att anlitas trots den Personuppgiftsansvariges invändning.
            </p>
            <h3>Överföring av personuppgifter till tredje land</h3>
            <p style="margin-top: 15px;">
                Personuppgiftsbiträdet får överföra personuppgifter till ett tredjeland utanför EU/EES eller en internationell organisation, endast i följande situationer.
                <ul>
                    <li>Europeiska kommissionen har beslutat att tredjelandet, ett territorium eller en eller flera specificerade sektorer i tredjelandet, eller den internationella organisationen i fråga säkerställer en adekvat skyddsnivå, eller</li>
                    <li>Personuppgiftsbiträdet har vidtagit någon av de lämpliga skyddsåtgärder som anges i artikel 46 i Dataskyddsförordningen och överföringen omfattas av vidtagna skyddsåtgärder, t.ex. i form av bindande företagsbestämmelser.</li>
                </ul><br>
            </p>
        </ve-row>
        <ve-row class="no-page-break page-break">
            <h3>Tystnadsplikt</h3>
            <p style="margin-top: 15px;">
                Personuppgiftsbiträdet ska säkerställa att dess anställda och Underbiträden som behandlar personuppgifter för den Personuppgiftsansvariges räkning har åtagit sig eller annars omfattas av tystnadsplikt som omfattar behandlingen av personuppgifterna. Bestämmelser om tystnadsplikt regleras i Uppdragsavtalet. 
            </p>
            <h3>Skada</h3>
            <p style="margin-top: 15px;">
                Personuppgiftsbiträdet ska ansvara för skada som uppkommer för den Personuppgiftsansvarige till följd av behandlingen endast om Personuppgiftsbiträdet har agerat utöver eller i strid med den Personuppgiftsansvariges instruktioner eller inte har fullgjort de skyldigheter i gällande rätt som specifikt riktar sig till Personuppgiftsbiträdet. <br><br>
                Om Personuppgiftsbiträdet utfört behandling i enlighet med den Personuppgiftsansvariges instruktioner och gällande rätt, och skada trots detta uppkommer, ska Personuppgiftsbiträdet hållas skadelöst.  <br><br>
                Närmare bestämmelser om skadeståndsansvar regleras i Uppdragsavtalet. 
            </p>
            <h3>Giltighetstid</h3>
            <p style="margin-top: 15px;">
                Biträdesavtalet gäller under den tid som Personuppgiftsbiträdet behandlar personuppgifter för den Personuppgiftsansvariges räkning för fullgörandet av Uppdragsavtalet. Om Uppdragsavtalet upphör att gälla upphör även Biträdesavtalet att gälla. <br><br>
                När Biträdesavtalet upphör att gälla ska Personuppgiftsbiträdet återlämna alla personuppgifter till den Personuppgiftsansvarige, eller om denne skriftligen begär det, radera alla personuppgifter som har behandlats för den Personuppgiftsansvariges räkning. Personuppgiftsbiträdet har dock rätt att bevara personuppgifter om det följer av gällande rätt eller annars för att dokumentera det specifika uppdraget.
            </p>
            <h3>Tillämplig lag och tvistelösning</h3><br>
            <p style="margin-top: 15px;width: 100%;">
                Biträdesavtalet ska tolkas och tillämpas i enlighet med bestämmelserna som i Uppdragsavtalet. <br>
                Tvistlösningsbestämmelsen i Uppdragsavtalet ska gälla.  <br><br>
                Biträdesavtalet har upprättats i två likalydande exemplar, varav Parterna tagit varsitt.  <br><br>
                {{data.contractor.city}} {{data.date}}
            </p>
        </ve-row>
    </div>
</template>

<script>

export default {
    name: "ve-preview-attachment",
    props: {
        data : {
            type: Object,
            default: () => { return {} }
        },
    },
    data() {
        return {
            validation_error: false
        }
    },
    methods: {
    },
    created() {
    }
}
</script>