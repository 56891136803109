<template>
    <div>
    <div class="card border-0 shadow-sm mt-4">
                      <div class="card-body pb-0">
                        <div class="row">
                          <ve-col xs12 sm3>
                            <ve-form-group>
                              <ve-label class="bold" :tip="{text:'verifyDetails'}">{{ __('moment') }}</ve-label>
                            </ve-form-group>
                          </ve-col>
                          <ve-col xs12 sm3>
                            <ve-form-group>
                              <ve-label class="bold" :tip="{text:'stateResponsible'}">{{ __('responsibilities') }}</ve-label>
                            </ve-form-group>
                          </ve-col>
                          <ve-col xs12 sm3>
                            <ve-form-group>
                              <ve-label class="bold" :tip="{text:'enterDeliveryTime'}">{{ __('deliveryCommitment') }}</ve-label>
                            </ve-form-group>
                          </ve-col>
                          <ve-col xs6 sm3>
                            <ve-form-group>
                              <ve-label class="bold">{{ __('comments') }}</ve-label>
                            </ve-form-group>
                          </ve-col>
                        </div>
                      </div>
                    </div>
                      <h4 class="mt-3">{{__('accountsAndReport')}}</h4>
                      <h5 class="mt-1">{{__('specs')}}</h5>
                      <div class="card border-0 shadow-sm">
                        <div class="card-body pb-0">
                          <div class="row">
                            <ve-col xs12 sm3>
                              <ve-form-group>
                                <ve-label>{{ __('separateSpecs') }}</ve-label>
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm3>
                              <ve-form-group>
                                <ve-select
                                  :searchable="false"
                                  :placeholder="__('empty')"
                                  v-if="responsible"
                                  @on-select="function(option){ud.t1.s1r1.responsible = option.value}"
                                  :options="responsible"
                                  :readonly="true"
                                />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm3>
                              <ve-form-group>
                                <ve-input 
                                  v-model="ud.t1.s1r1.delivery"
                                  :placeholder="__('deliveryDates')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm3>
                              <ve-form-group>
                                <ve-input
                                  v-model="ud.t1.s1r1.comments"
                                  :placeholder="__('comments')"
                                  />
                              </ve-form-group>
                            </ve-col>
                          </div>
                          
                          <div class="row" v-for="(sOth, index) in ud.t1.s1Other" v-bind:key="index">
                            <ve-col xs12 sm3>
                              <ve-form-group>
                                <ve-input 
                                  v-model="sOth.moment"
                                  :placeholder="__('otherSpecify')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm3>
                              <ve-form-group>
                                <ve-select
                                  :searchable="false"
                                  :placeholder="__('empty')"
                                  v-if="responsible"
                                  @on-select="function(option){sOth.responsible = option.value}"
                                  :options="responsible"
                                  :readonly="true"
                                />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm3>
                              <ve-form-group>
                                <ve-input 
                                  v-model="sOth.delivery"
                                  :placeholder="__('deliveryDates')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm3>
                              <ve-form-group>
                                <ve-input
                                  v-model="sOth.comments"
                                  :placeholder="__('comments')"
                                  />
                              </ve-form-group>
                            </ve-col>
                          </div>
                          <ve-col md6 xs12 >
                            <div  class="" style="padding:20px 40%;">
                              <div @click="removeOther('t1','s1Other')" class="btn-minus1">-</div>
                              <div @click="addOther('t1','s1Other')" class="btn-plus1">+</div>
                            </div>
                          </ve-col>
                        </div>
                      </div>
                      <h5 class="mt-3">{{__('prepAccounts')}}</h5>
                      <div class="card border-0 shadow-sm">
                        <div class="card-body pb-0">
                          <div class="row">
                            <ve-col xs12 sm3>
                              <ve-form-group>
                                <ve-label>{{ __('financialStatements') }}</ve-label>
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm3>
                              <ve-form-group>
                                <ve-select
                                  :searchable="false"
                                  :placeholder="__('empty')"
                                  v-if="responsible"
                                  @on-select="function(option){ud.t1.s2r1.responsible = option.value}"
                                  :options="responsible"
                                  :readonly="true"
                                />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm3>
                              <ve-form-group>
                                <ve-input 
                                  v-model="ud.t1.s2r1.delivery"
                                  :placeholder="__('deliveryDates')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm3>
                              <ve-form-group>
                                <ve-input
                                  v-model="ud.t1.s2r1.comments"
                                  :placeholder="__('comments')"
                                  />
                              </ve-form-group>
                            </ve-col>
                          </div>
                          <div class="row">
                            <ve-col xs12 sm3>
                              <ve-form-group>
                                <ve-label>{{ __('prepReport') }}</ve-label>
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm3>
                              <ve-form-group>
                                <ve-select
                                  :searchable="false"
                                  :placeholder="__('empty')"
                                  v-if="responsible"
                                  @on-select="function(option){ud.t1.s2r2.responsible = option.value}"
                                  :options="responsible"
                                  :readonly="true"
                                />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm3>
                              <ve-form-group>
                                <ve-input 
                                  v-model="ud.t1.s2r2.delivery"
                                  :placeholder="__('deliveryDates')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm3>
                              <ve-form-group>
                                <ve-input
                                  v-model="ud.t1.s2r2.comments"
                                  :placeholder="__('comments')"
                                  />
                              </ve-form-group>
                            </ve-col>
                          </div>
                          <div class="row">
                            <ve-col xs12 sm3>
                              <ve-form-group>
                                <ve-label>{{ __('keyAnalysis') }}</ve-label>
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm3>
                              <ve-form-group>
                                <ve-select
                                  :searchable="false"
                                  :placeholder="__('empty')"
                                  v-if="responsible"
                                  @on-select="function(option){ud.t1.s2r3.responsible = option.value}"
                                  :options="responsible"
                                  :readonly="true"
                                />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm3>
                              <ve-form-group>
                                <ve-input 
                                  v-model="ud.t1.s2r3.delivery"
                                  :placeholder="__('deliveryDates')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm3>
                              <ve-form-group>
                                <ve-input
                                  v-model="ud.t1.s2r3.comments"
                                  :placeholder="__('comments')"
                                  />
                              </ve-form-group>
                            </ve-col>
                          </div>
                          <div class="row">
                            <ve-col xs12 sm3>
                              <ve-form-group>
                                <ve-label>{{ __('reportToAuditor') }}</ve-label>
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm3>
                              <ve-form-group>
                                <ve-select
                                  :searchable="false"
                                  :placeholder="__('empty')"
                                  v-if="responsible"
                                  @on-select="function(option){ud.t1.s2r4.responsible = option.value}"
                                  :options="responsible"
                                  :readonly="true"
                                />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm3>
                              <ve-form-group>
                                <ve-input 
                                  v-model="ud.t1.s2r4.delivery"
                                  :placeholder="__('deliveryDates')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm3>
                              <ve-form-group>
                                <ve-input
                                  v-model="ud.t1.s2r4.comments"
                                  :placeholder="__('comments')"
                                  />
                              </ve-form-group>
                            </ve-col>
                          </div>
                          <div class="row">
                            <ve-col xs12 sm3>
                              <ve-form-group>
                                <ve-label>{{ __('inventoryAssistance') }}</ve-label>
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm3>
                              <ve-form-group>
                                <ve-select
                                  :searchable="false"
                                  :placeholder="__('empty')"
                                  v-if="responsible"
                                  @on-select="function(option){ud.t1.s2r5.responsible = option.value}"
                                  :options="responsible"
                                  :readonly="true"
                                />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm3>
                              <ve-form-group>
                                <ve-input 
                                  v-model="ud.t1.s2r5.delivery"
                                  :placeholder="__('deliveryDates')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm3>
                              <ve-form-group>
                                <ve-input
                                  v-model="ud.t1.s2r5.comments"
                                  :placeholder="__('comments')"
                                  />
                              </ve-form-group>
                            </ve-col>
                          </div>                                                                                     
                          <div class="row" v-for="(pOth, index) in ud.t1.s2Other" v-bind:key="index">
                            <ve-col xs12 sm3>
                              <ve-form-group>
                                <ve-input 
                                  v-model="pOth.moment"
                                  :placeholder="__('otherSpecify')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm3>
                              <ve-form-group>
                                <ve-select
                                  :searchable="false"
                                  :placeholder="__('empty')"
                                  v-if="responsible"
                                  @on-select="function(option){pOth.responsible = option.value}"
                                  :options="responsible"
                                  :readonly="true"
                                />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm3>
                              <ve-form-group>
                                <ve-input 
                                  v-model="pOth.delivery"
                                  :placeholder="__('deliveryDates')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm3>
                              <ve-form-group>
                                <ve-input
                                  v-model="pOth.comments"
                                  :placeholder="__('comments')"
                                  />
                              </ve-form-group>
                            </ve-col>
                          </div>
                           <ve-col md6 xs12 >
                            <div  class="" style="padding:20px 40%;">
                              <div @click="removeOther('t1','s2Other')" class="btn-minus1">-</div>
                              <div @click="addOther('t1','s2Other')" class="btn-plus1">+</div>
                            </div>
                          </ve-col>
                        </div>
                      </div>
                      <h5 class="mt-3">{{__('other')}}</h5>
                      <div class="card border-0 shadow-sm">
                        <div class="card-body pb-0">
                          <div class="row">
                            <ve-col xs12 sm3>
                              <ve-form-group>
                                <ve-label>{{ __('submitToCompRegOffice') }}</ve-label>
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm3>
                              <ve-form-group>
                                <ve-select
                                  :searchable="false"
                                  :placeholder="__('empty')"
                                  v-if="responsible"
                                  @on-select="function(option){ud.t1.s3r1.responsible = option.value}"
                                  :options="responsible"
                                  :readonly="true"
                                />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm3>
                              <ve-form-group>
                                <ve-input 
                                  v-model="ud.t1.s3r1.delivery"
                                  :placeholder="__('deliveryDates')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm3>
                              <ve-form-group>
                                <ve-input
                                  v-model="ud.t1.s3r1.comments"
                                  :placeholder="__('comments')"
                                  />
                              </ve-form-group>
                            </ve-col>
                          </div>
                          <div class="row">
                            <ve-col xs12 sm3>
                              <ve-form-group>
                                <ve-label>{{ __('establishDeclaration') }}</ve-label>
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm3>
                              <ve-form-group>
                                <ve-select
                                  :searchable="false"
                                  :placeholder="__('empty')"
                                  v-if="responsible"
                                  @on-select="function(option){ud.t1.s3r2.responsible = option.value}"
                                  :options="responsible"
                                  :readonly="true"
                                />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm3>
                              <ve-form-group>
                                <ve-input 
                                  v-model="ud.t1.s3r2.delivery"
                                  :placeholder="__('deliveryDates')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm3>
                              <ve-form-group>
                                <ve-input
                                  v-model="ud.t1.s3r2.comments"
                                  :placeholder="__('comments')"
                                  />
                              </ve-form-group>
                            </ve-col>
                          </div>
                          <div class="row" v-for="(oth, index) in ud.t1.s3Other" v-bind:key="index">
                            <ve-col xs12 sm3>
                              <ve-form-group>
                                <ve-input 
                                  v-model="oth.moment"
                                  :placeholder="__('otherSpecify')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm3>
                              <ve-form-group>
                                <ve-select
                                  :searchable="false"
                                  :placeholder="__('empty')"
                                  v-if="responsible"
                                  @on-select="function(option){oth.responsible = option.value}"
                                  :options="responsible"
                                  :readonly="true"
                                />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm3>
                              <ve-form-group>
                                <ve-input 
                                  v-model="oth.delivery"
                                  :placeholder="__('deliveryDates')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm3>
                              <ve-form-group>
                                <ve-input
                                  v-model="oth.comments"
                                  :placeholder="__('comments')"
                                  />
                              </ve-form-group>
                            </ve-col>
                          </div>
                           <ve-col md6 xs12 >
                            <div  class="" style="padding:20px 40%;">
                              <div @click="removeOther('t1','s3Other')" class="btn-minus1">-</div>
                              <div @click="addOther('t1','s3Other')" class="btn-plus1">+</div>
                            </div>
                          </ve-col>
                        </div>
                      </div>
                      <h5 class="mt-3">{{__('declarations')}}</h5>
                      <div class="card border-0 shadow-sm">
                        <div class="card-body pb-0">
                          <div class="row">
                            <ve-col xs12 sm3>
                              <ve-form-group>
                                <ve-label>{{ __('prepareITReturns') }}</ve-label>
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm3>
                              <ve-form-group>
                                <ve-select
                                  :searchable="false"
                                  :placeholder="__('empty')"
                                  v-if="responsible"
                                  @on-select="function(option){ud.t1.s4r1.responsible = option.value}"
                                  :options="responsible"
                                  :readonly="true"
                                />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm3>
                              <ve-form-group>
                                <ve-input 
                                  v-model="ud.t1.s4r1.delivery"
                                  :placeholder="__('deliveryDates')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm3>
                              <ve-form-group>
                                <ve-input
                                  v-model="ud.t1.s4r1.comments"
                                  :placeholder="__('comments')"
                                  />
                              </ve-form-group>
                            </ve-col>
                          </div>
                          <div class="row">
                            <ve-col xs12 sm3>
                              <ve-form-group>
                                <ve-label>{{ __('applicationEarlyRepayment') }}</ve-label>
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm3>
                              <ve-form-group>
                                <ve-select
                                  :searchable="false"
                                  :placeholder="__('empty')"
                                  v-if="responsible"
                                  @on-select="function(option){ud.t1.s4r2.responsible = option.value}"
                                  :options="responsible"
                                  :readonly="true"
                                />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm3>
                              <ve-form-group>
                                <ve-input 
                                  v-model="ud.t1.s4r2.delivery"
                                  :placeholder="__('deliveryDates')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm3>
                              <ve-form-group>
                                <ve-input
                                  v-model="ud.t1.s4r2.comments"
                                  :placeholder="__('comments')"
                                  />
                              </ve-form-group>
                            </ve-col>
                          </div>
                          <div class="row">
                            <ve-col xs12 sm3>
                              <ve-form-group>
                                <ve-label>{{ __('checkPrelimCharge') }}</ve-label>
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm3>
                              <ve-form-group>
                                <ve-select
                                  :searchable="false"
                                  :placeholder="__('empty')"
                                  v-if="responsible"
                                  @on-select="function(option){ud.t1.s4r3.responsible = option.value}"
                                  :options="responsible"
                                  :readonly="true"
                                />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm3>
                              <ve-form-group>
                                <ve-input 
                                  v-model="ud.t1.s4r3.delivery"
                                  :placeholder="__('deliveryDates')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm3>
                              <ve-form-group>
                                <ve-input
                                  v-model="ud.t1.s4r3.comments"
                                  :placeholder="__('comments')"
                                  />
                              </ve-form-group>
                            </ve-col>
                          </div>
                          <div class="row" v-for="(dOth, index) in ud.t1.s4Other" v-bind:key="index">
                            <ve-col xs12 sm3>
                              <ve-form-group>
                                <ve-input 
                                  v-model="dOth.moment"
                                  :placeholder="__('otherSpecify')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm3>
                              <ve-form-group>
                                <ve-select
                                  :searchable="false"
                                  :placeholder="__('empty')"
                                  v-if="responsible"
                                  @on-select="function(option){dOth.responsible = option.value}"
                                  :options="responsible"
                                  :readonly="true"
                                />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm3>
                              <ve-form-group>
                                <ve-input 
                                  v-model="dOth.delivery"
                                  :placeholder="__('deliveryDates')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm3>
                              <ve-form-group>
                                <ve-input
                                  v-model="dOth.comments"
                                  :placeholder="__('comments')"
                                  />
                              </ve-form-group>
                            </ve-col>
                          </div>
                           <ve-col md6 xs12 >
                            <div  class="" style="padding:20px 40%;">
                              <div @click="removeOther('t1','s4Other')" class="btn-minus1">-</div>
                              <div @click="addOther('t1','s4Other')" class="btn-plus1">+</div>
                            </div>
                          </ve-col>
                        </div>
                      </div>
                      <h5 class="mt-3">{{__('interimAccounts')}}</h5>
                      <div class="card border-0 shadow-sm">
                        <div class="card-body pb-0">
                          <div class="row">
                            <ve-col xs12 sm3>
                              <ve-form-group>
                                <ve-label>{{ __('prepareInterimAccounts') }}</ve-label>
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm3>
                              <ve-form-group>
                                <ve-select
                                  :searchable="false"
                                  :placeholder="__('empty')"
                                  v-if="responsible"
                                  @on-select="function(option){ud.t1.s5r1.responsible = option.value}"
                                  :options="responsible"
                                  :readonly="true"
                                />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm3>
                              <ve-form-group>
                                <ve-input 
                                  v-model="ud.t1.s5r1.delivery"
                                  :placeholder="__('deliveryDates')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm3>
                              <ve-form-group>
                                <ve-input
                                  v-model="ud.t1.s5r1.comments"
                                  :placeholder="__('comments')"
                                  />
                              </ve-form-group>
                            </ve-col>
                          </div>
                          <div class="row" v-for="(iOth, index) in ud.t1.s5Other" v-bind:key="index">
                            <ve-col xs12 sm3>
                              <ve-form-group>
                                <ve-input 
                                  v-model="iOth.moment"
                                  :placeholder="__('otherSpecify')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm3>
                              <ve-form-group>
                                <ve-select
                                  :searchable="false"
                                  :placeholder="__('empty')"
                                  v-if="responsible"
                                  @on-select="function(option){iOth.responsible = option.value}"
                                  :options="responsible"
                                  :readonly="true"
                                />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm3>
                              <ve-form-group>
                                <ve-input 
                                  v-model="iOth.delivery"
                                  :placeholder="__('deliveryDates')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm3>
                              <ve-form-group>
                                <ve-input
                                  v-model="iOth.comments"
                                  :placeholder="__('comments')"
                                  />
                              </ve-form-group>
                            </ve-col>
                          </div>
                           <ve-col md6 xs12 >
                            <div  class="" style="padding:20px 40%;">
                              <div @click="removeOther('t1','s5Other')" class="btn-minus1">-</div>
                              <div @click="addOther('t1','s5Other')" class="btn-plus1">+</div>
                            </div>
                          </ve-col>
                        </div>
                      </div>
                      <h5 class="mt-3">{{__('archiving')}}</h5>
                      <div class="card border-0 shadow-sm">
                        <div class="card-body pb-0">
                          <div class="row" v-for="(aOth, index) in ud.t1.s6Other" v-bind:key="index">
                            <ve-col xs12 sm3>
                              <ve-form-group>
                                <ve-input 
                                  v-model="aOth.moment"
                                  :placeholder="__('otherSpecify')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm3>
                              <ve-form-group>
                                <ve-select
                                  :searchable="false"
                                  :placeholder="__('empty')"
                                  v-if="responsible"
                                  @on-select="function(option){aOth.responsible = option.value}"
                                  :options="responsible"
                                  :readonly="true"
                                />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm3>
                              <ve-form-group>
                                <ve-input 
                                  v-model="aOth.delivery"
                                  :placeholder="__('deliveryDates')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm3>
                              <ve-form-group>
                                <ve-input
                                  v-model="aOth.comments"
                                  :placeholder="__('comments')"
                                  />
                              </ve-form-group>
                            </ve-col>
                          </div>
                           <ve-col md6 xs12 >
                            <div  class="" style="padding:20px 40%;">
                              <div @click="removeOther('t1','s6Other')" class="btn-minus1">-</div>
                              <div @click="addOther('t1','s6Other')" class="btn-plus1">+</div>
                            </div>
                          </ve-col>
                        </div>
                      </div>
    </div>
</template>

<script>

export default {
    name: "ve-tab-financial",
    props: {
        responsible : {
            type : Array,
            default: null
        },
        ud : {
            type: Object,
            default: () => { return {} }
        },
    },
    data() {
        return {
            validation_error: false
        }
    },
    methods: {
        addOther(tab,sec){
			this.ud[tab][sec].push({
						moment : null,
						frequency : null,
						responsible: null,
						delivery: null,
						amount : null,
						comments: null
					})
		},
		removeOther(tab,sec){
			this.ud[tab][sec].splice(this.ud[tab][sec].length-1, 1)
		},
        handleInput(e) {

        },
    },
    created() {
    }
}
</script>

<style lang="scss" scoped>


</style>
