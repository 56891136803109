import axios from 'axios'
import Vue from 'vue'
import moment from 'moment'
import * as libvf from '@verified/libvf'
import VueHtmlToPaper from 'vue-html-to-paper';
const options = {
  styles: [
    'https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/js/bootstrap.min.js',
	'preview.css',
	'https://use.fontawesome.com/releases/v5.7.0/css/all.css'
  ]
}
Vue.use(VueHtmlToPaper,options);
export default {
	name: "app",
	data() {
		return {
			step: 1,
			isBisnodeInProgress: false,
			selectedTab : 0,
			showPrint : false,
			ud: {
			terminationTextInfo: null,
				fileName : null,
				lang : 'sv_SE',
				date: moment().format('YYYY-MM-DD'),
				redirectURL : "https://www.firstledger.com/",
				toDate: null,
				contractor:{
					number: "556957-7694",
					name: "First Ledger",
					address: "Sveavägen 166",
					city: "Stockholm",
					zip: "113 46",
					contact: {
						firstName: "",
						lastName: "",
						email: "",
						phone: "",
						signingMethod: "bankid-se"
					},
				},
				organisation: {
					number: null,
					name: null,
					address: null,
					city: null,
					zip: null,
					contact: {
						firstName: "",
						lastName: "",
						email: "",
						phone: "",
						signingMethod: "bankid-se"
					},
				},
				missionType: {
					financial: false,
					salary: false,
					accounting: false
				},
				agreementDuration: {
					running: false,
					until: false,
					oneYear: false,
					existing: false
				},
				serviceProviderText: null,
				t1 : {
					serviceProvider: null,
					filling : {
						alternative1 :null,
						alternative2 :null
					},
					statements : {
						sekArray : [{}],
						priceType : {
							priceOp1 : false,
							priceOp2 : false,
							priceOp3 : false
						},
						fee : {
							index : null,
							startDate : null
						},
						sek: null,
						priceFrequency: null,
						sekforSalary: null,
						assignmentTime: null,
						timeFrequency: null,
						priceOp1: null,
						priceOp3: null,
					},
					annualReports : {
						sekArray : [{}],
						priceType : {
							priceOp1 : false,
							priceOp2 : false,
							priceOp3 : false
						},
						fee : {
							index : null,
							startDate : null
						},
						sek: null,
						priceFrequency: null,
						sekforSalary: null,
						assignmentTime: null,
						timeFrequency: null,
						priceOp1: null,
						priceOp3: null,
					},
					incomeTaxReturns : {
						sekArray : [{}],
						priceType : {
							priceOp1 : false,
							priceOp2 : false,
							priceOp3 : false
						},
						fee : {
							index : null,
							startDate : null
						},
						sek: null,
						priceFrequency: null,
						sekforSalary: null,
						assignmentTime: null,
						timeFrequency: null,
						priceOp1: null,
						priceOp3: null,
					},
					s1r1:{
						responsible: null,
						delivery: null,
						comments: null
					},
					s1Other:[{
						moment: null,
						responsible: null,
						delivery: null,
						comments: null
					}],
					s2r1: {
						responsible: null,
						delivery: null,
						comments: null
					},
					s2r2: {
						responsible: null,
						delivery: null,
						comments: null
					},
					s2r3: {
						responsible: null,
						delivery: null,
						comments: null
					},
					s2r4: {
						responsible: null,
						delivery: null,
						comments: null
					},
					s2r5: {
						responsible: null,
						delivery: null,
						comments: null
					},															
					s2Other:[{
						moment: null,
						responsible: null,
						delivery: null,
						comments: null
					}],
					s3r1: {
						responsible: null,
						delivery: null,
						comments: null
					},
					s3r2: {
						responsible: null,
						delivery: null,
						comments: null
					},															
					s3Other:[{
						moment: null,
						responsible: null,
						delivery: null,
						comments: null
					}],
					s4r1: {
						responsible: null,
						delivery: null,
						comments: null
					},
					s4r2: {
						responsible: null,
						delivery: null,
						comments: null
					},
					s4r3: {
						responsible: null,
						delivery: null,
						comments: null
					},															
					s4Other:[{
						moment: null,
						responsible: null,
						delivery: null,
						comments: null
					}],
					s5r1: {
						responsible: null,
						delivery: null,
						comments: null
					},															
					s5Other:[{
						moment: null,
						responsible: null,
						delivery: null,
						comments: null
					}],													
					s6Other:[{
						moment: null,
						responsible: null,
						delivery: null,
						comments: null
					}],
					priceOp3 : null,
					otherTreatment : null,
					purpose: null,
					otherCategories: null,
					registrants: null,
					recPersonel: null,
					technical: null,
					financialType: {
						statements: false,
						annualReports: false,
						incomeTaxReturns: false
					},
					terminationTextInfo: null,
					server: {}
				},
				t2 : {
					serviceProvider: null,
					sekArray : [{}],
					otherTreatment : null,
					terminationTextInfo: null,
					purpose: null,
					otherCategories: null,
					registrants: null,
					recPersonel: null,
					technical: null,
					server:{},
					priceType : {
						priceOp1 : false,
						priceOp2 : false,
						priceOp3 : false
					},
					priceOp3 : null,
					s1r1 : {
						frequency : null,
						responsible: null,
						delivery: null,
						amount : null,
						comments: null
					},
					s1r2 : {
						frequency : null,
						responsible: null,
						delivery: null,
						amount : null,
						comments: null
					},
					s1r3 : {
						frequency : null,
						responsible: null,
						delivery: null,
						amount : null,
						comments: null
					},
					s1r4 : {
						frequency : null,
						responsible: null,
						delivery: null,
						amount : null,
						comments: null
					},
					s1r5 : {
						frequency : null,
						responsible: null,
						delivery: null,
						amount : null,
						comments: null
					},
					s1r6 : {
						frequency : null,
						responsible: null,
						delivery: null,
						amount : null,
						comments: null
					},
					s1r7 : {
						frequency : null,
						responsible: null,
						delivery: null,
						amount : null,
						comments: null
					},
					s1r8 : {
						frequency : null,
						responsible: null,
						delivery: null,
						amount : null,
						comments: null
					},
					s1r9 : {
						frequency : null,
						responsible: null,
						delivery: null,
						amount : null,
						comments: null
					},
					s1r10 : {
						frequency : null,
						responsible: null,
						delivery: null,
						amount : null,
						comments: null
					},
					s1r11 : {
						frequency : null,
						responsible: null,
						delivery: null,
						amount : null,
						comments: null
					},
					s1Other : [{
						moment : null,
						frequency : null,
						responsible: null,
						delivery: null,
						amount : null,
						comments: null
					}],
					s2r1 : {
						frequency : null,
						responsible: null,
						delivery: null,
						amount : null,
						comments: null
					},
					s2r2 : {
						frequency : null,
						responsible: null,
						delivery: null,
						amount : null,
						comments: null
					},
					s2r3 : {
						frequency : null,
						responsible: null,
						delivery: null,
						amount : null,
						comments: null	
					},
					s2r4 : {
						frequency : null,
						responsible: null,
						delivery: null,
						amount : null,
						comments: null
					},
					s2r5 : {
						frequency : null,
						responsible: null,
						delivery: null,
						amount : null,
						comments: null
					},
					s2r6 : {
						frequency : null,
						responsible: null,
						delivery: null,
						amount : null,
						comments: null
					},
					s2r7 : {
						frequency : null,
						responsible: null,
						delivery: null,
						amount : null,
						comments: null
					},
					s2r8 : {
						frequency : null,
						responsible: null,
						delivery: null,
						amount : null,
						comments: null
					},
					s2r9 : {
						frequency : null,
						responsible: null,
						delivery: null,
						amount : null,
						comments: null
					},
					s2r10 : {
						frequency : null,
						responsible: null,
						delivery: null,
						amount : null,
						comments: null
					},
					s2r11 : {
						frequency : null,
						responsible: null,
						delivery: null,
						amount : null,
						comments: null
					},
					s2r12 : {
						frequency : null,
						responsible: null,
						delivery: null,
						amount : null,
						comments: null
					},
					s2Other : [{
						moment : null,
						frequency : null,
						responsible: null,
						delivery: null,
						amount : null,
						comments: null
					}],
					s3r1 : {
						frequency : null,
						responsible: null,
						delivery: null,
						amount : null,
						comments: null
					},
					s3r2 : {
						frequency : null,
						responsible: null,
						delivery: null,
						amount : null,
						comments: null
					},
					s3r3 : {
						frequency : null,
						responsible: null,
						delivery: null,
						amount : null,
						comments: null
					},
					s3r4 : {
						frequency : null,
						responsible: null,
						delivery: null,
						amount : null,
						comments: null
					},
					s3r5 : {
						frequency : null,
						responsible: null,
						delivery: null,
						amount : null,
						comments: null
					},
					s3Other : [{
						moment : null,
						frequency : null,
						responsible: null,
						delivery: null,
						amount : null,
						comments: null
					}],
					filling : {
						alternative1 :null,
						alternative2 :null
					},
					fee : {
						index : null,
						startDate : null
					}
				},
				t3 : {
					serviceProvider: null,
					sekArray : [{}],
					otherTreatment : null,
					purpose: null,
					otherCategories: null,
					terminationTextInfo: null,
					registrants: null,
					recPersonel: null,
					technical: null,
					server:{},
					priceType : {
						priceOp1 : false,
						priceOp2 : false,
						priceOp3 : false
					},
					priceOp3 : null,
					s1r1 : {
						frequency : null,
						responsible: null,
						delivery: null,
						amount : null,
						comments: null
					},
					s1r2 : {
						frequency : null,
						responsible: null,
						delivery: null,
						amount : null,
						comments: null
					},
					s1r3 : {
						frequency : null,
						responsible: null,
						delivery: null,
						amount : null,
						comments: null
					},
					s1r4 : {
						frequency : null,
						responsible: null,
						delivery: null,
						amount : null,
						comments: null
					},
					s1r5 : {
						frequency : null,
						responsible: null,
						delivery: null,
						amount : null,
						comments: null
					},
					s1Other : [{
						moment : null,
						frequency : null,
						responsible: null,
						delivery: null,
						amount : null,
						comments: null
					}],
					s2r1 : {
						frequency : null,
						responsible: null,
						delivery: null,
						amount : null,
						comments: null
					},
					s2r2 : {
						frequency : null,
						responsible: null,
						delivery: null,
						amount : null,
						comments: null
					},
					s2r3 : {
						frequency : null,
						responsible: null,
						delivery: null,
						amount : null,
						comments: null
					},
					s2Other : [{
						moment : null,
						frequency : null,
						responsible: null,
						delivery: null,
						amount : null,
						comments: null
					}],
					s3r1 : {
						frequency : null,
						responsible: null,
						delivery: null,
						amount : null,
						comments: null
					},
					s3r2 : {
						frequency : null,
						responsible: null,
						delivery: null,
						amount : null,
						comments: null
					},
					s3r3 : {
						frequency : null,
						responsible: null,
						delivery: null,
						amount : null,
						comments: null
					},
					s3r4 : {
						frequency : null,
						responsible: null,
						delivery: null,
						amount : null,
						comments: null
					},
					s3r5 : {
						frequency : null,
						responsible: null,
						delivery: null,
						amount : null,
						comments: null
					},
					s3r6 : {
						frequency : null,
						responsible: null,
						delivery: null,
						amount : null,
						comments: null
					},
					s3r7 : {
						frequency : null,
						responsible: null,
						delivery: null,
						amount : null,
						comments: null
					},
					s3r8 : {
						frequency : null,
						responsible: null,
						delivery: null,
						amount : null,
						comments: null
					},
					s3Other : [{
						moment : null,
						frequency : null,
						responsible: null,
						delivery: null,
						amount : null,
						comments: null
					}],
					s4r1 : {
                        frequency : null,
                        responsible: null,
                        delivery: null,
                        amount : null,
                        comments: null
                    },
                    s4r2 : {
                        frequency : null,
                        responsible: null,
                        delivery: null,
                        amount : null,
                        comments: null
                    },
                    s4r3 : {
                        frequency : null,
                        responsible: null,
                        delivery: null,
                        amount : null,
                        comments: null
                    },
                    s4r4 : {
                        frequency : null,
                        responsible: null,
                        delivery: null,
                        amount : null,
                        comments: null
                    },
                    s4r5 : {
                        frequency : null,
                        responsible: null,
                        delivery: null,
                        amount : null,
                        comments: null
                    },
                    s4r6 : {
                        frequency : null,
                        responsible: null,
                        delivery: null,
                        amount : null,
                        comments: null
                    },
                    s4r7 : {
                        frequency : null,
                        responsible: null,
                        delivery: null,
                        amount : null,
                        comments: null
                    },
                    s4r8 : {
                        frequency : null,
                        responsible: null,
                        delivery: null,
                        amount : null,
                        comments: null
                    },
                    s4r9 : {
                        frequency : null,
                        responsible: null,
                        delivery: null,
                        amount : null,
                        comments: null
                    },
                    s4r10 : {
                        frequency : null,
                        responsible: null,
                        delivery: null,
                        amount : null,
                        comments: null
                    },
                    s4Other : [{
                        moment : null,
                        frequency : null,
                        responsible: null,
                        delivery: null,
                        amount : null,
                        comments: null
                    }],
					s5r1 : {
						frequency : null,
						responsible: null,
						delivery: null,
						amount : null,
						comments: null
					},
					s5r2 : {
						frequency : null,
						responsible: null,
						delivery: null,
						amount : null,
						comments: null
					},
					s5r3 : {
						frequency : null,
						responsible: null,
						delivery: null,
						amount : null,
						comments: null
					},
					s5r4 : {
						frequency : null,
						responsible: null,
						delivery: null,
						amount : null,
						comments: null
					},
					s5r5 : {
						frequency : null,
						responsible: null,
						delivery: null,
						amount : null,
						comments: null
					},
					s5Other : [{
						moment : null,
						frequency : null,
						responsible: null,
						delivery: null,
						amount : null,
						comments: null
					}],
					s6r1 : {
						frequency : null,
						responsible: null,
						delivery: null,
						amount : null,
						comments: null
					},
					s6r2 : {
						frequency : null,
						responsible: null,
						delivery: null,
						amount : null,
						comments: null
					},
					s6r3 : {
						frequency : null,
						responsible: null,
						delivery: null,
						amount : null,
						comments: null
					},
					s6r4 : {
						frequency : null,
						responsible: null,
						delivery: null,
						amount : null,
						comments: null
					},
					s6r5 : {
						frequency : null,
						responsible: null,
						delivery: null,
						amount : null,
						comments: null
					},
					s6r6 : {
						frequency : null,
						responsible: null,
						delivery: null,
						amount : null,
						comments: null
					},
					s6r7 : {
						frequency : null,
						responsible: null,
						delivery: null,
						amount : null,
						comments: null
					},
					s6r8 : {
						frequency : null,
						responsible: null,
						delivery: null,
						amount : null,
						comments: null
					},
					s6Other : [{
						moment : null,
						frequency : null,
						responsible: null,
						delivery: null,
						amount : null,
						comments: null
					}],
					s7r1 : {
						frequency : null,
						responsible: null,
						delivery: null,
						amount : null,
						comments: null
					},
					s7r2 : {
						frequency : null,
						responsible: null,
						delivery: null,
						amount : null,
						comments: null
					},
					s7r3 : {
						frequency : null,
						responsible: null,
						delivery: null,
						amount : null,
						comments: null
					},
					s7Other : [{
						moment : null,
						frequency : null,
						responsible: null,
						delivery: null,
						amount : null,
						comments: null
					}],
					s8Other : [{
						moment : null,
						frequency : null,
						responsible: null,
						delivery: null,
						amount : null,
						comments: null
					}],
					s9r1 : {
                        frequency : null,
                        responsible: null,
                        delivery: null,
                        amount : null,
                        comments: null
                    },
                    s9r2 : {
                        frequency : null,
                        responsible: null,
                        delivery: null,
                        amount : null,
                        comments: null
                    },
                    s9r3 : {
                        frequency : null,
                        responsible: null,
                        delivery: null,
                        amount : null,
                        comments: null
                    },
                    s9r4 : {
                        frequency : null,
                        responsible: null,
                        delivery: null,
                        amount : null,
                        comments: null
                    },
                    s9r5 : {
                        frequency : null,
                        responsible: null,
                        delivery: null,
                        amount : null,
                        comments: null
                    },
                    s9r6 : {
                        frequency : null,
                        responsible: null,
                        delivery: null,
                        amount : null,
                        comments: null
                    },
                    s9r7 : {
                        frequency : null,
                        responsible: null,
                        delivery: null,
                        amount : null,
                        comments: null
                    },
                    s9r8 : {
                        frequency : null,
                        responsible: null,
                        delivery: null,
                        amount : null,
                        comments: null
                    },
                    s9r9 : {
                        frequency : null,
                        responsible: null,
                        delivery: null,
                        amount : null,
                        comments: null
                    },
                    s9Other : [{
                        moment : null,
                        frequency : null,
                        responsible: null,
                        delivery: null,
                        amount : null,
                        comments: null
                    }],
					s10r1 : {
                        frequency : null,
                        responsible: null,
                        delivery: null,
                        amount : null,
                        comments: null
                    },
                    s10Other : [{
                        moment : null,
                        frequency : null,
                        responsible: null,
                        delivery: null,
                        amount : null,
                        comments: null
                    }],
					filling : {
						alternative1 :null,
						alternative2 :null
					},
					fee : {
						index : null,
						startDate : null
					}
				},
				tab4Sec1 :null,
				tab4Sec2 : null,
				tab4Sec3 : null
			},
			envelope: null,
			signingMethods: null,
			responsible:null,
			frequency:null,
			priceFrequency:null,
			configuration: {},
			loading: true
		}
	},
	computed: {
		serviceProviderText(){
			let t1,t2,t3,text;
			let ud = this.ud;
			t1 = (ud.t1.serviceProvider && ud.missionType.financial === true) ? ud.t1.serviceProvider : "";
			t2 = (ud.t2.serviceProvider && ud.missionType.salary === true) ? ud.t2.serviceProvider : "";
			t3 = (ud.t3.serviceProvider && ud.missionType.accounting === true) ? ud.t3.serviceProvider : "";

			if(t1 && t2 && t3 ){
					text = `${t1}, ${t2} och ${t3}`
				}
			else if((t1 && t2) || (t2 && t3) || (t1 && t3)){
				if((t1 && t2)){
					text = `${t1} och ${t2} `
				}
				if((t2 && t3)){
					text = `${t2} och ${t3} `
				}
				if((t1 && t3)){
					text = `${t1} och ${t3} `
				}
				}
			else if(t1 != '' ||t2 != '' | t3 != ''){
					if(t1){
						text = t1
					}
					if(t2){
						text = t2
					}
					if(t3){
						text = t3
					}
				}
			ud.serviceProviderText = text
			return text
			
		} 
	},
	watch: {
		serviceProviderText(newValue){
			this.ud.serviceProviderText = newValue
		}
	},
	methods: {
		
		// Return value for given config key
		config(key) {
			if(this.configuration[key])
				return this.configuration[key][this.$root.locale] || this.configuration[key].value
		},
		openPreview(){
			this.showPrint = true;
		},
		closePreview(){
			this.showPrint = false;
		},
		steps() {
			return [
				{ title: this.__('step1') },
				{ title: this.__('step2') },
				{ title: this.__('step3'), button: this.__('sign') }
			]
		},
		addOther(tab,sec){
			this.ud[tab][sec].push({
						moment : null,
						frequency : null,
						responsible: null,
						delivery: null,
						amount : null,
						comments: null
					})
		},
		removeOther(tab,sec){
			this.ud[tab][sec].splice(this.ud[tab][sec].length-1, 1)
		},
		removeSek(type,subType){
			let parent = this.ud[type]
			if(subType){
				parent = parent[subType]
			}
			if(parent.sekArray.length>1)
				parent.sekArray.pop()
		},
		addSek(type,subType){
			let parent = this.ud[type]
			if(subType){
				parent = parent[subType]
			}
			parent.sekArray.push({})
		},
		async removeRecipients(){
			console.log(this.envelope)
			let promises = []
			if(this.envelope.data.recipients){
				this.envelope.data.recipients.forEach(function(recipient) {
					promises.push(axios.delete(`/api${recipient.uid}`,{
						headers:{
							'authorization' : `JWT ${libvf.auth.token}`,
							'x-namespace': libvf.auth.namespace
						}
					}))
				}, this);
			}
			return Promise.all(promises)
		},
		
		validateForm(form) {
			return this.$refs[form].validate()
		},
		selectTab(num){
			this.selectedTab = num
			window.scrollTo(0,0);
		},
		openModal() {
      console.log("openmodal called");
      document.getElementById("myModal").style.display = "block";
    },

    closeModal() {
      document.getElementById("myModal").style.display = "none";
    },
	doPrint () {
		this.$htmlToPaper('myModal-content');
    },
		backStep(){
			if(this.step<4 )
				this.step = this.step-1
			else if(this.step==4){
				if(this.selectedTab == 0)
					this.step = this.step-1
				else{
					this.selectedTab--
					if(!this.ud.missionType.accounting && this.selectedTab==2)
						this.selectedTab--
					if(!this.ud.missionType.salary && this.selectedTab==1)
						this.selectedTab--
					if(!this.ud.missionType.financial && this.selectedTab==0)
						this.step = this.step-1
				}
				
			}
			window.scrollTo(0,0);
		},
		
		nextStep() {
			console.log(this.ud)
			if(this.step < 4){
				if(!this.validateForm(`form${ this.step }`)) {
					return
				}
			}else{
				if(!this.validateForm(`form${ this.step }${this.selectedTab}`)) {
					return
				}
			}
			
			console.log("in nextstep = ",this.step)
			if(this.step == 3){
				console.log('in id 3=',this.ud.missionType)
				if(this.ud.missionType.financial)
					this.selectedTab = 0
				else if(this.ud.missionType.salary)
					this.selectedTab = 1
				else if(this.ud.missionType.accounting)
					this.selectedTab = 2
				else
					this.selectedTab = 3
			}
			if(this.step >= 4){
				if(this.selectedTab==3){
					return this.submit()
				}else{
					this.selectedTab++
					if(!this.ud.missionType.salary && this.selectedTab==1)
						this.selectedTab++
					if(!this.ud.missionType.accounting && this.selectedTab==2)
						this.selectedTab++
					
				}
			}
				
			console.log("selected tab ==",this.selectedTab)
			if(this.step!=4)
				this.step++
			window.scrollTo(0,0);
		},
		
		previousStep(n) {
			this.step = n
		},
		getDisabledDates(){
			return {
				to: moment(this.ud.date).toDate()
			}
		},
		fetchBisnode(){
			if(this.isBisnodeInProgress || !this.ud.organisation.number || this.ud.organisation.number.length < 11){
				return
			}
			this.isBisnodeInProgress = true
			this.ud.organisation.name = null
			var self = this

			libvf.bisnode.sweden.getCompanyInfo(this.ud.organisation.number.replace(/[^\d]/g,''))
			.then(function(data){
					if(!data.companyName){
						throw {message: 'not found'};
					}
					self.isBisnodeInProgress = false
					console.log(data);
					self.ud.organisation.name = data.companyName
					self.ud.organisation.address = data.address || data.postalAddress;
					self.ud.organisation.city = data.city
					self.ud.organisation.zip = data.postalCode

			}).catch(function(err){
				self.isBisnodeInProgress = false
			})

		},
		
		async submit() {
			//alert('Imagine a nice loader and then being redirected to the Verified document view for signing :)')
			
			console.log('Submit')
			var self = this
			this.loading = true;
			self.ud.fileName = this.ud.fileName+ this.ud.organisation.name +'-'+ this.ud.date +'.pdf'
			if(this.role === 'owner') {
				this.ud._isForwarded = true
				
				await this.removeRecipients()
				
				await this.envelope.addRecipient({
					"givenName": this.ud.contractor.contact.firstName,
					"familyName": this.ud.contractor.contact.lastName,
					"email": this.ud.contractor.contact.email,
					"language": "sv_SE",
					"signingMethod": this.ud.contractor.contact.signingMethod,
					"order": 1,
					"role": {
						"action": "sign",
						"label": "Signera",
						"name": "signer"
					}
				})
				// var signingMethod = "bankid-se"
				// if(this.ud.signingMethod.email){
				// 	signingMethod = "email"
				// }
				await this.envelope.addRecipient({
					"givenName": this.ud.organisation.contact.firstName,
					"familyName": this.ud.organisation.contact.lastName,
					"email": this.ud.organisation.contact.email,
					"language": "sv_SE",
					"signingMethod": this.ud.organisation.contact.signingMethod,
					"order": 2,
					"role": {
						"action": "sign",
						"label": "Signera",
						"name": "signer"
					}
				})
				// await this.envelope.addRecipient({
				// 	"givenName": this.ud.sender.givenName,
				// 	"familyName": this.ud.sender.familyName,
				// 	"email": this.ud.sender.email,
				// 	"language": "sv_SE",
				// 	"signingMethod": 'bankid-se',
				// 	"order": 3,
				// 	"role": {
				// 		"action": "sign",
				// 		"label": "Signera som uthyrare",
				// 		"name": "signer"
				// 	}
				// })
				
				await self.envelope.reflect()
				return Promise.resolve()
					.then(() => {
						return self.envelope.firstDocument().put({ name: self.ud.fileName });
					})
					.then(() => self.envelope.firstTemplate().setUserData(self.ud))
					.then(() => {
						self.send = true
						setTimeout(()=> window.close(), 5000)
					})
					.catch(err => {
						console.log('Error', err);
						this.loading = false;
						this.error = true;
						this.exceptions.push(err.message)
						this.busy = false;
						throw err;
					})	
			}
		}
	},
	
	async created() {
		
		// Set language to use
		this.setIso("sv_SE")
		
		const translations = await axios.get('https://sheets.web-services.verified.eu/firstledger-contract/translations')
		
		// This combines veform's default translations with the ones from sheets
		// duplicates are overwritten by the sheet
		this.mergeLocale(translations.data)
		this.ud.translations = translations.data
		this.signingMethods = [
			{value:'bankid-se', text:this.__('bankidSe')},
			{value:'bankid-no', text:this.__('bankidNo')},
			{value:'email', text:this.__('emailSign')}
		]
		this.responsible = [
			{value:null, text:this.__('empty')},
			{value:'office', text:this.__('office')},
			{value:'customer', text:this.__('customer')}
		]
		this.priceFrequency = [
			{value:null, text:this.__('empty')},
			{value:'quarter', text:this.__('quarter')},
			{value:'month', text:this.__('month')},
			{value:'year', text:this.__('year')},
			{value:'unit', text:this.__('unit')}
		]

		this.frequency = [
			{value:null, text:this.__('empty')},
			{value:'current', text:this.__('current')},
			{value:'monday', text:this.__('monday')},
			{value:'quarterly', text:this.__('quarterly')},
			{value:'tertiary', text:this.__('tertiary')},
			{value:'weekly', text:this.__('weekly')},
			{value:'yearly', text:this.__('yearly')}
		]
		

		libvf.auth.useTokenFromUrl()
		this.role = libvf.auth.role
		console.log('role ==',this.role)
		this.envelope = await libvf.envelopes.getFromUrl()

		let data = await this.envelope.firstTemplate().getUserData();
			console.log(data);
			// this.role = auth.role
			// this.ud.role =this.role;
			// if(this.role == 'editor'){
		if(data.contractor){
			this.ud = data;
			console.log(this.ud);
		}
		
			// }

		this.ud.contractor.contact.firstName = this.envelope.data.sender.givenName
		this.ud.contractor.contact.lastName = this.envelope.data.sender.familyName
		this.ud.contractor.contact.email = this.envelope.data.sender.email
		this.ud.t1.s1r1.comments = this.__('separateChecklist')
		this.ud.terminationTextInfo = this.__('terminationTextInfo')
		this.ud.t1.terminationTextInfo = this.__('terminationTextInfo')
		this.ud.t1.jobDesc        = this.__('jobDescValue')
		this.ud.t1.jobDesc1       = this.__('jobDescValue1')
		this.ud.t1.jobDesc2       = this.__('jobDescValue2')
		this.ud.t1.additions      = this.__('additionsValue')

		this.ud.t1.technical      = this.__('technicalVal')
		this.ud.t1.recPersonel    = this.__('recPersonelVal')
		this.ud.t1.registrants    = this.__('registrants')
		this.ud.t1.purpose        = this.__('purpose')
		this.ud.t2.terminationTextInfo = this.__('terminationTextInfo')

		this.ud.t2.purpose        = this.__('purpose1')
		this.ud.t3.purpose        = this.__('purpose2')
		
		this.ud.tab4Sec1          = this.__('tab4Sec1')
		this.ud.tab4Sec2          = this.__('tab4Sec2')
		this.ud.tab4Sec3          = this.__('tab4Sec3')
		// this.ud.t1.registrants    = this.__('registrants')

		//Customer only wants these text terms in T3
		// this.ud.t1.statements.priceOp1       = this.__('priceOption1Terms')
		// this.ud.t1.annualReports.priceOp1    = this.__('priceOption1Terms')
		// this.ud.t1.incomeTaxReturns.priceOp1 = this.__('priceOption1Terms')

		// this.ud.t2.priceOp1       = this.__('priceOption1Terms')
		this.ud.t3.priceOp1       = this.__('priceOption1Terms')
		this.ud.t3.terminationTextInfo = this.__('terminationTextInfo')

		this.ud.t1.statements.priceOp3       = this.__('priceOption3Terms')
		this.ud.t1.annualReports.priceOp3    = this.__('priceOption3Terms')
		this.ud.t1.incomeTaxReturns.priceOp3 = this.__('priceOption3Terms')

		this.ud.t2.priceOp3       = this.__('priceOption3Terms')
		this.ud.t3.priceOp3       = this.__('priceOption3Terms')

		this.ud.t2.registrants    = this.__('registrants2')
		this.ud.t2.recPersonel    = this.__('recPersonelVal2')
		this.ud.t2.technical      = this.__('technicalVal2')

		this.ud.t3.registrants    = this.__('registrants2')
		this.ud.t3.recPersonel    = this.__('recPersonelVal2')
		this.ud.t3.technical      = this.__('technicalVal2')

		this.ud.fileName = this.__('fileName')
		console.log(this.envelope)
		this.loading = false
	}
}