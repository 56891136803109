<template>
    <div>
        <!--<h3 v-if="data.missionType && data.missionType.financial">{{data.translations.tabFinancial[data.lang]}}</h3>-->
		<h3 v-if="data.missionType && data.missionType.financial&& data.t1">
			{{data.translations.bilaga[data.lang]}} 3: {{data.translations.missionFinancial[data.lang]}}
		</h3>
		<div class="no-page-break" v-if="data.missionType && data.missionType.financial && data.t1">
			<div class="flex-columns">
				<ve-col md12 xs12 v-if="data.t1.s1r1.responsible || data.t1.s1Other[0].moment" >
					<b>{{data.translations.specs[data.lang]}}</b>
				</ve-col>

				<ve-col md4 v-if="data.t1.s1r1.responsible">
					{{data.translations.separateSpecs[data.lang]}}
				</ve-col>
				<ve-col md2 v-if="data.t1.s1r1.responsible">{{data.translations.responsible[data.lang]}}:&nbsp;
					<b v-if="data.t1.s1r1.responsible">{{data.translations[data.t1.s1r1.responsible][data.lang]}}</b>
				</ve-col>
				<ve-col md3 v-if="data.t1.s1r1.responsible">{{data.translations.deliveryDates[data.lang]}}:&nbsp;
					<b v-if="data.t1.s1r1.delivery">{{data.t1.s1r1.delivery}}</b>
				</ve-col>
				<ve-col md3 v-if="data.t1.s1r1.responsible">{{data.translations.comments[data.lang]}}:&nbsp;
					<b v-if="data.t1.s1r1.comments"> {{data.t1.s1r1.comments}}</b>
				</ve-col>
			</div>
            <div class="flex-columns" v-for="oth in data.t1.s1Other">
                <ve-col md4 v-if="oth.moment&&oth.responsible">{{oth.moment}}</ve-col>
                <ve-col md2 v-if="oth.moment&&oth.responsible">{{data.translations.responsible[data.lang]}}:&nbsp;
                    <b v-if="oth.responsible">{{data.translations[oth.responsible][data.lang]}}</b>
                </ve-col>
                <ve-col md3 v-if="oth.moment&&oth.responsible">{{data.translations.deliveryDates[data.lang]}}:&nbsp;
                    <b v-if="oth.delivery">{{oth.delivery}}</b>
                </ve-col>
                <ve-col md3 v-if="oth.moment&&oth.responsible">{{data.translations.comments[data.lang]}}:&nbsp;
                    <b v-if="oth.comments"> {{oth.comments}}</b>
                </ve-col>
            </div>
		</div>
		<div class="no-page-break" v-if="data.missionType && data.missionType.financial && data.t1">
			<div class="flex-columns">
				<ve-col md12 v-if="data.t1.s2r1.responsible || data.t1.s2r2.responsible || data.t1.s2r3.responsible || data.t1.s2r4.responsible || data.t1.s2r5.responsible || data.t1.s2Other[0].moment" >
					<b>{{data.translations.prepAccounts[data.lang]}}</b>
				</ve-col>

				<ve-col md4 v-if="data.t1.s2r1.responsible">
					{{data.translations.financialStatements[data.lang]}}
				</ve-col>
				<ve-col md2 v-if="data.t1.s2r1.responsible">{{data.translations.responsible[data.lang]}}:&nbsp;
					<b v-if="data.t1.s2r1.responsible">{{data.translations[data.t1.s2r1.responsible][data.lang]}}</b>
				</ve-col>
				<ve-col md3 v-if="data.t1.s2r1.responsible">{{data.translations.deliveryDates[data.lang]}}:&nbsp;
					<b v-if="data.t1.s2r1.delivery">{{data.t1.s2r1.delivery}}</b>
				</ve-col>
				<ve-col md3 v-if="data.t1.s2r1.responsible">{{data.translations.comments[data.lang]}}:&nbsp;
					<b v-if="data.t1.s2r1.comments"> {{data.t1.s2r1.comments}}</b>
				</ve-col>

				<ve-col md4 v-if="data.t1.s2r2.responsible">
					{{data.translations.prepReport[data.lang]}}
				</ve-col>
				<ve-col md2 v-if="data.t1.s2r2.responsible">{{data.translations.responsible[data.lang]}}:&nbsp;
					<b v-if="data.t1.s2r2.responsible">{{data.translations[data.t1.s2r2.responsible][data.lang]}}</b>
				</ve-col>
				<ve-col md3 v-if="data.t1.s2r2.responsible">{{data.translations.deliveryDates[data.lang]}}:&nbsp;
					<b v-if="data.t1.s2r2.delivery">{{data.t1.s2r2.delivery}}</b>
				</ve-col>
				<ve-col md3 v-if="data.t1.s2r2.responsible">{{data.translations.comments[data.lang]}}:&nbsp;
					<b v-if="data.t1.s2r2.comments"> {{data.t1.s2r2.comments}}</b>
				</ve-col>

				<ve-col md4 v-if="data.t1.s2r3.responsible">
					{{data.translations.keyAnalysis[data.lang]}}
				</ve-col>
				<ve-col md2 v-if="data.t1.s2r3.responsible">{{data.translations.responsible[data.lang]}}:&nbsp;
					<b v-if="data.t1.s2r3.responsible">{{data.translations[data.t1.s2r3.responsible][data.lang]}}</b>
				</ve-col>
				<ve-col md3 v-if="data.t1.s2r3.responsible">{{data.translations.deliveryDates[data.lang]}}:&nbsp;
					<b v-if="data.t1.s2r3.delivery">{{data.t1.s2r3.delivery}}</b>
				</ve-col>
				<ve-col md3 v-if="data.t1.s2r3.responsible">{{data.translations.comments[data.lang]}}:&nbsp;
					<b v-if="data.t1.s2r3.comments"> {{data.t1.s2r3.comments}}</b>
				</ve-col>

				<ve-col md4 v-if="data.t1.s2r4.responsible">
					{{data.translations.reportToAuditor[data.lang]}}
				</ve-col>
				<ve-col md2 v-if="data.t1.s2r4.responsible">{{data.translations.responsible[data.lang]}}:&nbsp;
					<b v-if="data.t1.s2r4.responsible">{{data.translations[data.t1.s2r4.responsible][data.lang]}}</b>
				</ve-col>
				<ve-col md3 v-if="data.t1.s2r4.responsible">{{data.translations.deliveryDates[data.lang]}}:&nbsp;
					<b v-if="data.t1.s2r4.delivery">{{data.t1.s2r4.delivery}}</b>
				</ve-col>
				<ve-col md3 v-if="data.t1.s2r4.responsible">{{data.translations.comments[data.lang]}}:&nbsp;
					<b v-if="data.t1.s2r4.comments"> {{data.t1.s2r4.comments}}</b>
				</ve-col>

				<ve-col md4 v-if="data.t1.s2r5.responsible">
					{{data.translations.inventoryAssistance[data.lang]}}
				</ve-col>
				<ve-col md2 v-if="data.t1.s2r5.responsible">{{data.translations.responsible[data.lang]}}:&nbsp;
					<b v-if="data.t1.s2r5.responsible">{{data.translations[data.t1.s2r5.responsible][data.lang]}}</b>
				</ve-col>
				<ve-col md3 v-if="data.t1.s2r5.responsible">{{data.translations.deliveryDates[data.lang]}}:&nbsp;
					<b v-if="data.t1.s2r5.delivery">{{data.t1.s2r5.delivery}}</b>
				</ve-col>
				<ve-col md3 v-if="data.t1.s2r5.responsible">{{data.translations.comments[data.lang]}}:&nbsp;
					<b v-if="data.t1.s2r5.comments"> {{data.t1.s2r5.comments}}</b>
				</ve-col>
			</div>
            <div class="flex-columns" v-for="oth2 in data.t1.s2Other">
                <ve-col md4 v-if="oth2.moment&&oth2.responsible">{{oth2.moment}}</ve-col>
                <ve-col md2 v-if="oth2.moment&&oth2.responsible">{{data.translations.responsible[data.lang]}}:&nbsp;
                    <b v-if="oth2.responsible">{{data.translations[oth2.responsible][data.lang]}}</b>
                </ve-col>
                <ve-col md3 v-if="oth2.moment&&oth2.responsible">{{data.translations.deliveryDates[data.lang]}}:&nbsp;
                    <b v-if="oth2.delivery">{{oth2.delivery}}</b>
                </ve-col>
                <ve-col md3 v-if="oth2.moment&&oth2.responsible">{{data.translations.comments[data.lang]}}:&nbsp;
                    <b v-if="oth2.comments"> {{oth2.comments}}</b>
                </ve-col>
            </div>
		</div>
		<div class="no-page-break" v-if="data.missionType && data.missionType.financial && data.t1">
			<div class="flex-columns">
				<ve-col md12 v-if="data.t1.s3r1.responsible ||data.t1.s3r2.responsible ||data.t1.s3Other[0].moment" >
					<b>{{data.translations.other[data.lang]}}</b>
				</ve-col>

				<ve-col md4 v-if="data.t1.s3r1.responsible">
					{{data.translations.submitToCompRegOffice[data.lang]}}
				</ve-col>
				<ve-col md2 v-if="data.t1.s3r1.responsible">{{data.translations.responsible[data.lang]}}:&nbsp;
					<b v-if="data.t1.s3r1.responsible">{{data.translations[data.t1.s3r1.responsible][data.lang]}}</b>
				</ve-col>
				<ve-col md3 v-if="data.t1.s3r1.responsible">{{data.translations.deliveryDates[data.lang]}}:&nbsp;
					<b v-if="data.t1.s3r1.delivery">{{data.t1.s3r1.delivery}}</b>
				</ve-col>
				<ve-col md3 v-if="data.t1.s3r1.responsible">{{data.translations.comments[data.lang]}}:&nbsp;
					<b v-if="data.t1.s3r1.comments"> {{data.t1.s3r1.comments}}</b>
				</ve-col>

				<ve-col md4 v-if="data.t1.s3r2.responsible">
					{{data.translations.establishDeclaration[data.lang]}}
				</ve-col>
				<ve-col md2 v-if="data.t1.s3r2.responsible">{{data.translations.responsible[data.lang]}}:&nbsp;
					<b v-if="data.t1.s3r2.responsible">{{data.translations[data.t1.s3r2.responsible][data.lang]}}</b>
				</ve-col>
				<ve-col md3 v-if="data.t1.s3r2.responsible">{{data.translations.deliveryDates[data.lang]}}:&nbsp;
					<b v-if="data.t1.s3r2.delivery">{{data.t1.s3r2.delivery}}</b>
				</ve-col>
				<ve-col md3 v-if="data.t1.s3r2.responsible">{{data.translations.comments[data.lang]}}:&nbsp;
					<b v-if="data.t1.s3r2.comments"> {{data.t1.s3r2.comments}}</b>
				</ve-col>
			</div>
            <div class="flex-columns" v-for="oth3 in data.t1.s3Other">
                <ve-col md4 v-if="oth3.moment&&oth3.responsible">{{oth3.moment}}</ve-col>
                <ve-col md2 v-if="oth3.moment&&oth3.responsible">{{data.translations.responsible[data.lang]}}:&nbsp;
                    <b v-if="oth3.responsible">{{data.translations[oth3.responsible][data.lang]}}</b>
                </ve-col>
                <ve-col md3 v-if="oth3.moment&&oth3.responsible">{{data.translations.deliveryDates[data.lang]}}:&nbsp;
                    <b v-if="oth3.delivery">{{oth3.delivery}}</b>
                </ve-col>
                <ve-col md3 v-if="oth3.moment&&oth3.responsible">{{data.translations.comments[data.lang]}}:&nbsp;
                    <b v-if="oth3.comments"> {{oth3.comments}}</b>
                </ve-col>
            </div>
		</div>
		<div class="no-page-break" v-if="data.missionType && data.missionType.financial && data.t1">
			<div class="flex-columns">
				<ve-col md12 v-if="data.t1.s4r1.responsible ||data.t1.s4r2.responsible ||data.t1.s4r3.responsible ||data.t1.s4Other[0].moment" >
					<b>{{data.translations.declarations[data.lang]}}</b>
				</ve-col>

				<ve-col md4 v-if="data.t1.s4r1.responsible">
					{{data.translations.prepareITReturns[data.lang]}}
				</ve-col>
				<ve-col md2 v-if="data.t1.s4r1.responsible">{{data.translations.responsible[data.lang]}}:&nbsp;
					<b v-if="data.t1.s4r1.responsible">{{data.translations[data.t1.s4r1.responsible][data.lang]}}</b>
				</ve-col>
				<ve-col md3 v-if="data.t1.s4r1.responsible">{{data.translations.deliveryDates[data.lang]}}:&nbsp;
					<b v-if="data.t1.s4r1.delivery">{{data.t1.s4r1.delivery}}</b>
				</ve-col>
				<ve-col md3 v-if="data.t1.s4r1.responsible">{{data.translations.comments[data.lang]}}:&nbsp;
					<b v-if="data.t1.s4r1.comments"> {{data.t1.s4r1.comments}}</b>
				</ve-col>

				<ve-col md4 v-if="data.t1.s4r2.responsible">
					{{data.translations.applicationEarlyRepayment[data.lang]}}
				</ve-col>
				<ve-col md2 v-if="data.t1.s4r2.responsible">{{data.translations.responsible[data.lang]}}:&nbsp;
					<b v-if="data.t1.s4r2.responsible">{{data.translations[data.t1.s4r2.responsible][data.lang]}}</b>
				</ve-col>
				<ve-col md3 v-if="data.t1.s4r2.responsible">{{data.translations.deliveryDates[data.lang]}}:&nbsp;
					<b v-if="data.t1.s4r2.delivery">{{data.t1.s4r2.delivery}}</b>
				</ve-col>
				<ve-col md3 v-if="data.t1.s4r2.responsible">{{data.translations.comments[data.lang]}}:&nbsp;
					<b v-if="data.t1.s4r2.comments"> {{data.t1.s4r2.comments}}</b>
				</ve-col>

				<ve-col md4 v-if="data.t1.s4r3.responsible">
					{{data.translations.checkPrelimCharge[data.lang]}}
				</ve-col>
				<ve-col md2 v-if="data.t1.s4r3.responsible">{{data.translations.responsible[data.lang]}}:&nbsp;
					<b v-if="data.t1.s4r3.responsible">{{data.translations[data.t1.s4r3.responsible][data.lang]}}</b>
				</ve-col>
				<ve-col md3 v-if="data.t1.s4r3.responsible">{{data.translations.deliveryDates[data.lang]}}:&nbsp;
					<b v-if="data.t1.s4r3.delivery">{{data.t1.s4r3.delivery}}</b>
				</ve-col>
				<ve-col md3 v-if="data.t1.s4r3.responsible">{{data.translations.comments[data.lang]}}:&nbsp;
					<b v-if="data.t1.s4r3.comments"> {{data.t1.s4r3.comments}}</b>
				</ve-col>
			</div>
            <div class="flex-columns" v-for="oth4 in data.t1.s4Other">
                <ve-col md4 v-if="oth4.moment&&oth4.responsible">{{oth4.moment}}</ve-col>
                <ve-col md2 v-if="oth4.moment&&oth4.responsible">{{data.translations.responsible[data.lang]}}:&nbsp;
                    <b v-if="oth4.responsible">{{data.translations[oth4.responsible][data.lang]}}</b>
                </ve-col>
                <ve-col md3 v-if="oth4.moment&&oth4.responsible">{{data.translations.deliveryDates[data.lang]}}:&nbsp;
                    <b v-if="oth4.delivery">{{oth4.delivery}}</b>
                </ve-col>
                <ve-col md3 v-if="oth4.moment&&oth4.responsible">{{data.translations.comments[data.lang]}}:&nbsp;
                    <b v-if="oth4.comments"> {{oth4.comments}}</b>
                </ve-col>
            </div>
		</div>
		<div class="no-page-break" v-if="data.missionType && data.missionType.financial && data.t1">
			<div class="flex-columns">
				<ve-col md12 v-if="data.t1.s5r1.responsible || data.t1.s5Other[0].moment" >
					<b>{{data.translations.interimAccounts[data.lang]}}</b>
				</ve-col>

				<ve-col md4 v-if="data.t1.s5r1.responsible">
					{{data.translations.prepareInterimAccounts[data.lang]}}
				</ve-col>
				<ve-col md2 v-if="data.t1.s5r1.responsible">{{data.translations.responsible[data.lang]}}:&nbsp;
					<b v-if="data.t1.s5r1.responsible">{{data.translations[data.t1.s5r1.responsible][data.lang]}}</b>
				</ve-col>
				<ve-col md3 v-if="data.t1.s5r1.responsible">{{data.translations.deliveryDates[data.lang]}}:&nbsp;
					<b v-if="data.t1.s5r1.delivery">{{data.t1.s5r1.delivery}}</b>
				</ve-col>
				<ve-col md3 v-if="data.t1.s5r1.responsible">{{data.translations.comments[data.lang]}}:&nbsp;
					<b v-if="data.t1.s5r1.comments"> {{data.t1.s5r1.comments}}</b>
				</ve-col>
			</div>
            <div class="flex-columns" v-for="oth5 in data.t1.s5Other">
                <ve-col md4 v-if="oth5.moment&&oth5.responsible">{{oth5.moment}}</ve-col>
                <ve-col md2 v-if="oth5.moment&&oth5.responsible">{{data.translations.responsible[data.lang]}}:&nbsp;
                    <b v-if="oth5.responsible">{{data.translations[oth5.responsible][data.lang]}}</b>
                </ve-col>
                <ve-col md3 v-if="oth5.moment&&oth5.responsible">{{data.translations.deliveryDates[data.lang]}}:&nbsp;
                    <b v-if="oth5.delivery">{{oth5.delivery}}</b>
                </ve-col>
                <ve-col md3 v-if="oth5.moment&&oth5.responsible">{{data.translations.comments[data.lang]}}:&nbsp;
                    <b v-if="oth5.comments"> {{oth5.comments}}</b>
                </ve-col>
            </div>
		</div>
		<div class="no-page-break" v-if="data.missionType && data.missionType.financial && data.t1">
			<div class="flex-columns">
				<ve-col md12 v-if="data.t1.s6Other[0].moment" >
					<b>{{data.translations.archiving[data.lang]}}</b>
				</ve-col>
			</div>
            <div class="flex-columns" v-for="oth6 in data.t1.s6Other">
                <ve-col md4 v-if="oth6.moment&&oth6.responsible">{{oth6.moment}}</ve-col>
                <ve-col md2 v-if="oth6.moment&&oth6.responsible">{{data.translations.responsible[data.lang]}}:&nbsp;
                    <b v-if="oth6.responsible">{{data.translations[oth6.responsible][data.lang]}}</b>
                </ve-col>
                <ve-col md3 v-if="oth6.moment&&oth6.responsible">{{data.translations.deliveryDates[data.lang]}}:&nbsp;
                    <b v-if="oth6.delivery">{{oth6.delivery}}</b>
                </ve-col>
                <ve-col md3 v-if="oth6.moment&&oth6.responsible">{{data.translations.comments[data.lang]}}:&nbsp;
                    <b v-if="oth6.comments"> {{oth6.comments}}</b>
                </ve-col>
            </div>
		</div>
		<br>
		<!--<div class="no-page-break" v-if="data.missionType && data.missionType.financial && data.t1">
			<div class="flex-columns">
				<ve-col md4 v-if="data.t1.assignments">
					<span>{{data.translations.assignments[data.lang]}}:<b>{{data.t1.assignments}}</b></span>
				</ve-col>
				<ve-col md4 v-if="data.t1.schedule">
					<span>{{data.translations.schedule[data.lang]}}:<b>{{data.t1.schedule}}</b></span>
				</ve-col>
				<ve-col md4 v-if="data.t1.serviceProvider">
					<span>{{data.translations.serviceProvider[data.lang]}}:<b>{{data.t1.serviceProvider}}</b></span>
				</ve-col>
			</div>
		</div>-->
		<!--<div class="no-page-break" v-if="data.missionType && data.missionType.financial && data.t1">
			<div class="flex-columns">
				<ve-col md12>
					<span>{{data.translations.filling[data.lang]}}:
						<b v-if="data.t1.filling.alternative1">{{data.translations.alternative1[data.lang]}}</b>
						<b v-if="data.t1.filling.alternative2">{{data.translations.alternative2[data.lang]}}</b>
					</span>
					<p v-if="data.t1.filling.alternative1">
						{{data.translations.alternative1Info[data.lang]}}
					</p>
				</ve-col>
				<ve-col md12>
					<span>{{data.translations.server[data.lang]}}:   
					</span>
                        <div class="custom-control custom-control-inline custom-checkbox mb-1">
                            <input type="checkbox" class="custom-control-input" :checked=(data.t1.server.own) disabled/>
                            <label class="custom-control-label custom-checkbox-label" for="">{{data.translations.own[data.lang]}}</label>&nbsp
                        </div>
                        <div class="custom-control custom-control-inline custom-checkbox mb-1">
                            <input type="checkbox" class="custom-control-input" :checked=(data.t1.server.provider) disabled/>
                            <label class="custom-control-label custom-checkbox-label" for="">{{data.translations.provider[data.lang]}}</label>
                        </div>
				</ve-col>
			</div>
		</div>
		<div class="no-page-break" v-if="data.missionType && data.missionType.financial && data.t1">
			<div class="flex-columns">
				<ve-col md12>
					<h3>{{data.translations.fee[data.lang]}}</h3>
					<span>
						<b v-if="data.t1.priceType.priceOp1">{{data.translations.priceOp1[data.lang]}}</b>
						<b v-if="data.t1.priceType.priceOp2">{{data.translations.priceOp2[data.lang]}}</b>
						<b v-if="data.t1.priceType.priceOp3">{{data.translations.priceOp3[data.lang]}}</b>
					</span>
					<p v-if="data.t1.priceType.priceOp3">
						{{data.t1.priceOp3}}
					</p>
				</ve-col>
				<ve-col md6 v-if="data.t1.priceType.priceOp1">
					<span>{{data.translations.sek[data.lang]}}: <b>{{data.t1.sek}}</b></span>
				</ve-col>
				<ve-col md6 v-if="data.t1.priceType.priceOp1&&data.t1.priceFrequency">
					<span>{{data.translations.frequency[data.lang]}}: <b>{{data.translations[data.t1.priceFrequency][data.lang]}}</b></span>
				</ve-col>
			</div>
		</div>
		<div class="no-page-break" v-if="data.missionType && data.missionType.financial && data.t1">
			<div class="flex-columns">
				<ve-col md6 v-if="data.t1.priceType.priceOp2">
					<span>{{data.translations.sekforSalary[data.lang]}}: <b>{{data.t1.sekforSalary}}</b></span>
				</ve-col>
				<ve-col md6 v-if="data.t1.priceType.priceOp2">
					<span>{{data.translations.sekforOther[data.lang]}}:</span><br>
					<div v-for="sek in data.t1.sekArray">
					 	<span ><b>{{sek.type}} : {{sek.value}}</b></span><br>
					</div>
				</ve-col>
				<ve-col md6 v-if="data.t1.priceType.priceOp2&&data.t1.assignmentTime">
					<span>{{data.translations.assignmentTime[data.lang]}}: <b>{{data.t1.assignmentTime}}</b></span>
				</ve-col>
				<ve-col md6 v-if="data.t1.priceType.priceOp2&&data.t1.timeFrequency">
					<span>{{data.translations.frequency[data.lang]}}: <b>{{data.translations[data.t1.timeFrequency][data.lang]}}</b></span>
				</ve-col>
				<ve-col md6 v-if="(data.t1.priceType.priceOp2||data.t1.priceType.priceOp1)&&data.t1.fee.index">
					<span>{{data.translations.feeIndex[data.lang]}}: <b>{{data.t1.fee.index}}</b></span>
				</ve-col>
				<ve-col md6 v-if="data.t1.priceType.priceOp2&&data.t1.fee.startDate">
					<span>{{data.translations.feeStartDate[data.lang]}}: <b>{{data.t1.fee.startDate}}</b></span>
				</ve-col>
			</div>
		</div>
		<div class="no-page-break" v-if="data.missionType && data.missionType.financial && data.t1">
			<div class="flex-columns">
				<ve-col md12 v-if="data.t1.assignmentPeriod">
					<span>{{data.translations.assignmentPeriod[data.lang]}}: <b>{{data.t1.assignmentPeriod}}</b></span>
				</ve-col>
				<ve-col md12 v-if="data.t1.noticePeriod">
					<span>{{data.translations.noticePeriod[data.lang]}}: <b>{{data.t1.noticePeriod}}</b></span>
				</ve-col>
				<ve-col md12 v-if="data.t1.treatment&&(data.t1.treatment.treatmentOp1||data.t1.treatment.treatmentOp2||data.t1.treatment.treatmentOp3||data.t1.treatment.treatmentOp4||data.t1.treatment.treatmentOp5||data.t1.treatment.t3TreatmetOp1||data.t1.treatment.t3TreatmetOp2||data.t1.treatment.t3TreatmetOp3)">
					<span><b>{{data.translations.treatment[data.lang]}}: </b><br>
						<span v-if="data.t1.treatment.t3TreatmetOp1">- {{data.translations.t3TreatmetOp1[data.lang]}}<br></span>
						<span v-if="data.t1.treatment.t3TreatmetOp2">- {{data.translations.t3TreatmetOp2[data.lang]}}<br></span>
						<span v-if="data.t1.treatment.t3TreatmetOp3">- {{data.translations.t3TreatmetOp3[data.lang]}}<br></span>
						<span v-if="data.t1.treatment.treatmentOp1">- {{data.translations.treatmentOp1[data.lang]}}<br></span>
						<span v-if="data.t1.treatment.treatmentOp2">- {{data.translations.treatmentOp2[data.lang]}}<br></span>
						<span v-if="data.t1.treatment.treatmentOp3">- {{data.translations.treatmentOp3[data.lang]}}<br></span>
						<span v-if="data.t1.treatment.treatmentOp4">- {{data.translations.treatmentOp4[data.lang]}}<br></span>
						<span v-if="data.t1.treatment.treatmentOp5">- {{data.translations.treatmentOp5[data.lang]}}<br></span>
					</span><br>
					<p v-if="data.t1.otherTreatment">{{data.t1.otherTreatment}}</p>
				</ve-col>
			</div>
		</div>
		<div class="no-page-break" v-if="data.missionType && data.missionType.financial && data.t1">
			<div class="flex-columns">
				<ve-col md12 v-if="data.t1.purpose">
					<b>{{data.translations.purposeHead[data.lang]}}: </b>
					<p>{{data.t1.purpose}}</p>
				</ve-col>
				<ve-col md12 v-if="data.t1.categories.categoriesOp1||data.t1.categories.categoriesOp2||data.t1.categories.categoriesOp3||data.t1.categories.categoriesOp4||data.t1.categories.categoriesOp5||data.t1.categories.categoriesOp6||data.t1.categories.categoriesOp7||data.t1.categories.categoriesOp8||data.t1.categories.categoriesOp9||data.t1.categories.categoriesOp10||data.t1.categories.categoriesOp11||data.t1.categories.t3CategoriesOp2||data.t1.categories.t3CategoriesOp3||data.t1.categories.t3CategoriesOp4||data.t1.categories.t3CategoriesOp7
				||data.t1.categories.t3CategoriesOp8||data.t1.categories.t3CategoriesOp9">
					<span><b>{{data.translations.categories[data.lang]}}:</b> <br>
						<span v-if="data.t1.categories.t3CategoriesOp2">- {{data.translations.t3CategoriesOp2[data.lang]}}<br></span>
						<span v-if="data.t1.categories.t3CategoriesOp3">- {{data.translations.t3CategoriesOp3[data.lang]}}<br></span>
						<span v-if="data.t1.categories.t3CategoriesOp4">- {{data.translations.t3CategoriesOp4[data.lang]}}<br></span>
						<span v-if="data.t1.categories.t3CategoriesOp7">- {{data.translations.t3CategoriesOp7[data.lang]}}<br></span>
						<span v-if="data.t1.categories.t3CategoriesOp8">- {{data.translations.t3CategoriesOp8[data.lang]}}<br></span>
						<span v-if="data.t1.categories.t3CategoriesOp9">- {{data.translations.t3CategoriesOp9[data.lang]}}<br></span>
						<span v-if="data.t1.categories.categoriesOp1">- {{data.translations.categoriesOp1[data.lang]}}<br></span>
						<span v-if="data.t1.categories.categoriesOp2">- {{data.translations.categoriesOp2[data.lang]}}<br></span>
						<span v-if="data.t1.categories.categoriesOp3">- {{data.translations.categoriesOp3[data.lang]}}<br></span>
						<span v-if="data.t1.categories.categoriesOp4">- {{data.translations.categoriesOp4[data.lang]}}<br></span>
						<span v-if="data.t1.categories.categoriesOp5">- {{data.translations.categoriesOp5[data.lang]}}<br></span>
						<span v-if="data.t1.categories.categoriesOp6">- {{data.translations.categoriesOp6[data.lang]}}<br></span>
						<span v-if="data.t1.categories.categoriesOp7">- {{data.translations.categoriesOp7[data.lang]}}<br></span>
						<span v-if="data.t1.categories.categoriesOp8">- {{data.translations.categoriesOp8[data.lang]}}<br></span>
						<span v-if="data.t1.categories.categoriesOp9">- {{data.translations.categoriesOp9[data.lang]}}<br></span>
						<span v-if="data.t1.categories.categoriesOp10">- {{data.translations.categoriesOp10[data.lang]}}<br></span>
						<span v-if="data.t1.categories.categoriesOp11">- {{data.translations.categoriesOp11[data.lang]}}<br></span>
					
					</span><br>
					<p v-if="data.t1.otherCategories">{{data.t1.otherCategories}}</p>
				</ve-col>
			</div>
		</div>
		<div class="no-page-break" v-if="data.missionType && data.missionType.financial && data.t1">
			<div class="flex-columns">
				<ve-col md12 v-if="data.t1.registrants">
					<b>{{data.translations.registrantsHead[data.lang]}}: </b>
					<p>{{data.t1.registrants}}</p>
				</ve-col>
				<ve-col md12 v-if="data.t1.recPersonel">
					<b>{{data.translations.recPersonel[data.lang]}}: </b>
					<p>{{data.t1.recPersonel}}</p>
				</ve-col>
				<ve-col md12 v-if="data.t1.technical">
					<b>{{data.translations.technical[data.lang]}}: </b>
					<p>{{data.t1.technical}}</p>
				</ve-col>
			</div>
		</div>
		<br>-->
    </div>
</template>

<script>

export default {
    name: "ve-preview-financial",
    props: {
        data : {
            type: Object,
            default: () => { return {} }
        },
    },
    data() {
        return {
            validation_error: false
        }
    },
    methods: {
    },
    created() {
    }
}
</script>

<style lang="scss" scoped>

.flex-columns{display:-webkit-box;display:-moz-box;display:-ms-flexbox;display:-webkit-flex;display:flex;-webkit-box-direction:normal;-moz-box-direction:normal;-webkit-box-orient:horizontal;-moz-box-orient:horizontal;-webkit-flex-direction:row;-ms-flex-direction:row;flex-direction:row;-webkit-box-pack:stretch;-moz-box-pack:stretch;-webkit-justify-content:stretch;-ms-flex-pack:stretch;justify-content:stretch;-webkit-flex-wrap:wrap;-moz-flex-wrap:wrap;-ms-flex-wrap:wrap;flex-wrap:wrap;-webkit-flex-wrap:wrap;-moz-flex-wrap:wrap;-ms-flex-wrap:wrap;flex-wrap:wrap}.flex-columns .col{flex-grow:1;-webkit-flex-grow:1;-moz-flex-grow:1;-ms-flex-grow:1;background-color:#f3f3f3;padding:8px;border:1px solid #fff;box-sizing:border-box}.flex-columns .col-1{width:100%}.flex-columns .col-2{width:50%}.flex-columns .col-3{width:33.3%}.flex-columns .col-4{width:25%}.flex-columns .col-5{width:20%}.flex-columns .col-6{width:16.6%}.flex-columns .col-7{width:14.28%}.flex-columns .col-8{width:12.5%}.flex-columns .col-9{width:11.1%}.flex-columns .col-10{width:10%}.flex-columns.transparent .col{background-color:transparent;border-color:transparent}table{border-collapse:collapse}table,table td{border:1px solid #eee;background:transparent}table td{padding:8px 12px}table.stripped,table.stripped td{border:0}table.stripped td{border-bottom:1px solid #eee}.flex-columns{margin-left:1px}.flex-columns .col{border:1px solid #eee;background:transparent;padding:8px 12px;margin:-1px 0 0 -1px}.flex-columns.stripped .col{border-left:0;border-right:0}.flex-columns.transparent .col{border:0}
b{font-weight:bold!important;}
.myModal-content, .myModal-content label{
    font-size:12px!important;
}
</style>
