<template>
    <div>
        <div class="card border-0 shadow-sm mt-4">
                      <div class="card-body pb-0">
                        <div class="row">
                          <ve-col xs12 sm2>
                            <ve-form-group>
                              <ve-label class="bold" :tip="{text:'verifyDetails'}">{{ __('moment') }}</ve-label>
                            </ve-form-group>
                          </ve-col>
                          <ve-col xs12 sm2>
                            <ve-form-group>
                              <ve-label class="bold" :tip="{text:'frequencyInfo'}">{{ __('frequency') }}</ve-label>
                            </ve-form-group>
                          </ve-col>
                          <ve-col xs12 sm2>
                            <ve-form-group>
                              <ve-label class="bold" :tip="{text:'stateResponsible'}">{{ __('responsibilities') }}</ve-label>
                            </ve-form-group>
                          </ve-col>
                          <ve-col xs12 sm2>
                            <ve-form-group>
                              <ve-label class="bold" :tip="{text:'deliveryCommitmentInfo2'}">{{ __('deliveryCommitment') }}</ve-label>
                            </ve-form-group>
                          </ve-col>
                          <ve-col xs12 sm2>
                            <ve-form-group>
                              <ve-label class="bold" :tip="{text:'estimateAmountInfo'}">{{ __('amount') }}</ve-label>
                            </ve-form-group>
                          </ve-col>
                          <ve-col xs12 sm2>
                            <ve-form-group>
                              <ve-label class="bold">{{ __('comments') }}</ve-label>
                            </ve-form-group>
                          </ve-col>
                        </div>
                      </div>
                    </div>
                      <h4 class="mt-1">{{__('tab2Sec1Head')}}</h4>
                      <!--<h5 class="mt-1">{{__('specs')}}</h5>-->
                      <div class="card border-0 shadow-sm">
                        <div class="card-body pb-0">
                          <div class="row">
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-label>{{ __('tab2Sec1Op1') }}</ve-label>
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                  <ve-select
                                  :searchable="false"
                                  :placeholder="__('empty')"
                                  v-if="frequency"
                                  @on-select="function(option){ud.t2.s1r1.frequency = option.value}"
                                  :options="frequency"
                                  :readonly="true"
                                />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-select
                                  :searchable="false"
                                  :placeholder="__('empty')"
                                  v-if="responsible"
                                  @on-select="function(option){ud.t2.s1r1.responsible = option.value}"
                                  :options="responsible"
                                  :readonly="true"
                                />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-input 
                                  v-model="ud.t2.s1r1.delivery"
                                  :placeholder="__('deliveryDates')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                <ve-input
                                  v-model="ud.t2.s1r1.amount"
                                  type="number"                                   
                                  :placeholder="__('amount')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                <ve-input
                                  v-model="ud.t2.s1r1.comments"
                                  :placeholder="__('comments')"
                                  />
                              </ve-form-group>
                            </ve-col>
                          </div>
                          <div class="row">
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-label>{{ __('tab2Sec1Op2') }}</ve-label>
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                  <ve-select
                                  :searchable="false"
                                  :placeholder="__('empty')"
                                  v-if="frequency"
                                  @on-select="function(option){ud.t2.s1r2.frequency = option.value}"
                                  :options="frequency"
                                  :readonly="true"
                                />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-select
                                  :searchable="false"
                                  :placeholder="__('empty')"
                                  v-if="responsible"
                                  @on-select="function(option){ud.t2.s1r2.responsible = option.value}"
                                  :options="responsible"
                                  :readonly="true"
                                />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-input 
                                  v-model="ud.t2.s1r2.delivery"
                                  :placeholder="__('deliveryDates')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                <ve-input
                                  v-model="ud.t2.s1r2.amount"
                                  type="number"                                   
                                  :placeholder="__('amount')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                <ve-input
                                  v-model="ud.t2.s1r2.comments"
                                  :placeholder="__('comments')"
                                  />
                              </ve-form-group>
                            </ve-col>
                          </div>
                          <div class="row">
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-label>{{ __('tab2Sec1Op3') }}</ve-label>
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                  <ve-select
                                  :searchable="false"
                                  :placeholder="__('empty')"
                                  v-if="frequency"
                                  @on-select="function(option){ud.t2.s1r3.frequency = option.value}"
                                  :options="frequency"
                                  :readonly="true"
                                />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-select
                                  :searchable="false"
                                  :placeholder="__('empty')"
                                  v-if="responsible"
                                  @on-select="function(option){ud.t2.s1r3.responsible = option.value}"
                                  :options="responsible"
                                  :readonly="true"
                                />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-input 
                                  v-model="ud.t2.s1r3.delivery"
                                  :placeholder="__('deliveryDates')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                <ve-input
                                  v-model="ud.t2.s1r3.amount"
                                  type="number"                                   
                                  :placeholder="__('amount')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                <ve-input
                                  v-model="ud.t2.s1r3.comments"
                                  :placeholder="__('comments')"
                                  />
                              </ve-form-group>
                            </ve-col>
                          </div>
                          <div class="row">
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-label>{{ __('tab2Sec1Op4') }}</ve-label>
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                  <ve-select
                                  :searchable="false"
                                  :placeholder="__('empty')"
                                  v-if="frequency"
                                  @on-select="function(option){ud.t2.s1r4.frequency = option.value}"
                                  :options="frequency"
                                  :readonly="true"
                                />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-select
                                  :searchable="false"
                                  :placeholder="__('empty')"
                                  v-if="responsible"
                                  @on-select="function(option){ud.t2.s1r4.responsible = option.value}"
                                  :options="responsible"
                                  :readonly="true"
                                />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-input 
                                  v-model="ud.t2.s1r4.delivery"
                                  :placeholder="__('deliveryDates')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                <ve-input
                                  v-model="ud.t2.s1r4.amount"
                                  type="number"                                   
                                  :placeholder="__('amount')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                <ve-input
                                  v-model="ud.t2.s1r4.comments"
                                  :placeholder="__('comments')"
                                  />
                              </ve-form-group>
                            </ve-col>
                          </div>
                          <div class="row">
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-label>{{ __('tab2Sec1Op5') }}</ve-label>
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                  <ve-select
                                  :searchable="false"
                                  :placeholder="__('empty')"
                                  v-if="frequency"
                                  @on-select="function(option){ud.t2.s1r5.frequency = option.value}"
                                  :options="frequency"
                                  :readonly="true"
                                />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-select
                                  :searchable="false"
                                  :placeholder="__('empty')"
                                  v-if="responsible"
                                  @on-select="function(option){ud.t2.s1r5.responsible = option.value}"
                                  :options="responsible"
                                  :readonly="true"
                                />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-input 
                                  v-model="ud.t2.s1r5.delivery"
                                  :placeholder="__('deliveryDates')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                <ve-input
                                  v-model="ud.t2.s1r5.amount"
                                  type="number"                                   
                                  :placeholder="__('amount')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                <ve-input
                                  v-model="ud.t2.s1r5.comments"
                                  :placeholder="__('comments')"
                                  />
                              </ve-form-group>
                            </ve-col>
                          </div>
                          <div class="row">
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-label>{{ __('tab2Sec1Op6') }}</ve-label>
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                  <ve-select
                                  :searchable="false"
                                  :placeholder="__('empty')"
                                  v-if="frequency"
                                  @on-select="function(option){ud.t2.s1r6.frequency = option.value}"
                                  :options="frequency"
                                  :readonly="true"
                                />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-select
                                  :searchable="false"
                                  :placeholder="__('empty')"
                                  v-if="responsible"
                                  @on-select="function(option){ud.t2.s1r6.responsible = option.value}"
                                  :options="responsible"
                                  :readonly="true"
                                />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-input 
                                  v-model="ud.t2.s1r6.delivery"
                                  :placeholder="__('deliveryDates')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                <ve-input
                                  v-model="ud.t2.s1r6.amount"
                                  type="number"                                   
                                  :placeholder="__('amount')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                <ve-input
                                  v-model="ud.t2.s1r6.comments"
                                  :placeholder="__('comments')"
                                  />
                              </ve-form-group>
                            </ve-col>
                          </div>
                          <div class="row">
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-label>{{ __('tab2Sec1Op7') }}</ve-label>
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                  <ve-select
                                  :searchable="false"
                                  :placeholder="__('empty')"
                                  v-if="frequency"
                                  @on-select="function(option){ud.t2.s1r7.frequency = option.value}"
                                  :options="frequency"
                                  :readonly="true"
                                />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-select
                                  :searchable="false"
                                  :placeholder="__('empty')"
                                  v-if="responsible"
                                  @on-select="function(option){ud.t2.s1r7.responsible = option.value}"
                                  :options="responsible"
                                  :readonly="true"
                                />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-input 
                                  v-model="ud.t2.s1r7.delivery"
                                  :placeholder="__('deliveryDates')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                <ve-input
                                  v-model="ud.t2.s1r7.amount"
                                  type="number"                                   
                                  :placeholder="__('amount')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                <ve-input
                                  v-model="ud.t2.s1r7.comments"
                                  :placeholder="__('comments')"
                                  />
                              </ve-form-group>
                            </ve-col>
                          </div>
                          <div class="row">
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-label>{{ __('tab2Sec1Op8') }}</ve-label>
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                  <ve-select
                                  :searchable="false"
                                  :placeholder="__('empty')"
                                  v-if="frequency"
                                  @on-select="function(option){ud.t2.s1r8.frequency = option.value}"
                                  :options="frequency"
                                  :readonly="true"
                                />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-select
                                  :searchable="false"
                                  :placeholder="__('empty')"
                                  v-if="responsible"
                                  @on-select="function(option){ud.t2.s1r8.responsible = option.value}"
                                  :options="responsible"
                                  :readonly="true"
                                />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-input 
                                  v-model="ud.t2.s1r8.delivery"
                                  :placeholder="__('deliveryDates')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                <ve-input
                                  v-model="ud.t2.s1r8.amount"
                                  type="number"                                   
                                  :placeholder="__('amount')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                <ve-input
                                  v-model="ud.t2.s1r8.comments"
                                  :placeholder="__('comments')"
                                  />
                              </ve-form-group>
                            </ve-col>
                          </div>
                          <div class="row">
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-label>{{ __('tab2Sec1Op9') }}</ve-label>
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                  <ve-select
                                  :searchable="false"
                                  :placeholder="__('empty')"
                                  v-if="frequency"
                                  @on-select="function(option){ud.t2.s1r9.frequency = option.value}"
                                  :options="frequency"
                                  :readonly="true"
                                />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-select
                                  :searchable="false"
                                  :placeholder="__('empty')"
                                  v-if="responsible"
                                  @on-select="function(option){ud.t2.s1r9.responsible = option.value}"
                                  :options="responsible"
                                  :readonly="true"
                                />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-input 
                                  v-model="ud.t2.s1r9.delivery"
                                  :placeholder="__('deliveryDates')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                <ve-input
                                  v-model="ud.t2.s1r9.amount"
                                  type="number"                                   
                                  :placeholder="__('amount')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                <ve-input
                                  v-model="ud.t2.s1r9.comments"
                                  :placeholder="__('comments')"
                                  />
                              </ve-form-group>
                            </ve-col>
                          </div>
                          <div class="row">
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-label>{{ __('tab2Sec1Op10') }}</ve-label>
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                  <ve-select
                                  :searchable="false"
                                  :placeholder="__('empty')"
                                  v-if="frequency"
                                  @on-select="function(option){ud.t2.s1r10.frequency = option.value}"
                                  :options="frequency"
                                  :readonly="true"
                                />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-select
                                  :searchable="false"
                                  :placeholder="__('empty')"
                                  v-if="responsible"
                                  @on-select="function(option){ud.t2.s1r10.responsible = option.value}"
                                  :options="responsible"
                                  :readonly="true"
                                />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-input 
                                  v-model="ud.t2.s1r10.delivery"
                                  :placeholder="__('deliveryDates')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                <ve-input
                                  v-model="ud.t2.s1r10.amount"
                                  type="number"                                   
                                  :placeholder="__('amount')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                <ve-input
                                  v-model="ud.t2.s1r10.comments"
                                  :placeholder="__('comments')"
                                  />
                              </ve-form-group>
                            </ve-col>
                          </div>
                          <div class="row">
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-label>{{ __('tab2Sec1Op11') }}</ve-label>
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                  <ve-select
                                  :searchable="false"
                                  :placeholder="__('empty')"
                                  v-if="frequency"
                                  @on-select="function(option){ud.t2.s1r11.frequency = option.value}"
                                  :options="frequency"
                                  :readonly="true"
                                />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-select
                                  :searchable="false"
                                  :placeholder="__('empty')"
                                  v-if="responsible"
                                  @on-select="function(option){ud.t2.s1r11.responsible = option.value}"
                                  :options="responsible"
                                  :readonly="true"
                                />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-input 
                                  v-model="ud.t2.s1r11.delivery"
                                  :placeholder="__('deliveryDates')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                <ve-input
                                  v-model="ud.t2.s1r11.amount"
                                  type="number"                                   
                                  :placeholder="__('amount')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                <ve-input
                                  v-model="ud.t2.s1r11.comments"
                                  :placeholder="__('comments')"
                                  />
                              </ve-form-group>
                            </ve-col>
                          </div>
                          <div class="row" v-for="(s1Ot, index) in ud.t2.s1Other" v-bind:key="index">
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-input 
                                  v-model="s1Ot.moment"
                                  :placeholder="__('otherSpecify')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                  <ve-select
                                  :searchable="false"
                                  :placeholder="__('empty')"
                                  v-if="frequency"
                                  @on-select="function(option){s1Ot.frequency = option.value}"
                                  :options="frequency"
                                  :readonly="true"
                                />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-select
                                  :searchable="false"
                                  :placeholder="__('empty')"
                                  v-if="responsible"
                                  @on-select="function(option){s1Ot.responsible = option.value}"
                                  :options="responsible"
                                  :readonly="true"
                                />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-input 
                                  v-model="s1Ot.delivery"
                                  :placeholder="__('deliveryDates')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                <ve-input
                                  v-model="s1Ot.amount"
                                  type="number"
                                  :placeholder="__('amount')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                <ve-input
                                  v-model="s1Ot.comments"
                                  :placeholder="__('comments')"
                                  />
                              </ve-form-group>
                            </ve-col>
                          </div>
                          <ve-col md6 xs12 >
                            <div  class="" style="padding:20px 40%;">
                              <div @click="removeOther('t2','s1Other')" class="btn-minus1">-</div>
                              <div @click="addOther('t2','s1Other')" class="btn-plus1">+</div>
                            </div>
                          </ve-col>
                        </div>
                      </div>
                      <h5 class="mt-3">{{__('tab2Sec2Head')}}</h5>
                      <div class="card border-0 shadow-sm">
                        <div class="card-body pb-0">
                          <div class="row">
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-label>{{ __('tab2Sec2Op1') }}</ve-label>
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                  <ve-select
                                  :searchable="false"
                                  :placeholder="__('empty')"
                                  v-if="frequency"
                                  @on-select="function(option){ud.t2.s2r1.frequency = option.value}"
                                  :options="frequency"
                                  :readonly="true"
                                />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-select
                                  :searchable="false"
                                  :placeholder="__('empty')"
                                  v-if="responsible"
                                  @on-select="function(option){ud.t2.s2r1.responsible = option.value}"
                                  :options="responsible"
                                  :readonly="true"
                                />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-input 
                                  v-model="ud.t2.s2r1.delivery"
                                  :placeholder="__('deliveryDates')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                <ve-input
                                  v-model="ud.t2.s2r1.amount"
                                  type="number"                                   
                                  :placeholder="__('amount')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                <ve-input
                                  v-model="ud.t2.s2r1.comments"
                                  :placeholder="__('comments')"
                                  />
                              </ve-form-group>
                            </ve-col>
                          </div>
                           <div class="row">
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-label>{{ __('tab2Sec2Op2') }}</ve-label>
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                  <ve-select
                                  :searchable="false"
                                  :placeholder="__('empty')"
                                  v-if="frequency"
                                  @on-select="function(option){ud.t2.s2r2.frequency = option.value}"
                                  :options="frequency"
                                  :readonly="true"
                                />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-select
                                  :searchable="false"
                                  :placeholder="__('empty')"
                                  v-if="responsible"
                                  @on-select="function(option){ud.t2.s2r2.responsible = option.value}"
                                  :options="responsible"
                                  :readonly="true"
                                />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-input 
                                  v-model="ud.t2.s2r2.delivery"
                                  :placeholder="__('deliveryDates')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                <ve-input
                                  v-model="ud.t2.s2r2.amount"
                                  type="number"                                   
                                  :placeholder="__('amount')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                <ve-input
                                  v-model="ud.t2.s2r2.comments"
                                  :placeholder="__('comments')"
                                  />
                              </ve-form-group>
                            </ve-col>
                          </div>
                           <div class="row">
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-label>{{ __('tab2Sec2Op3') }}</ve-label>
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                  <ve-select
                                  :searchable="false"
                                  :placeholder="__('empty')"
                                  v-if="frequency"
                                  @on-select="function(option){ud.t2.s2r3.frequency = option.value}"
                                  :options="frequency"
                                  :readonly="true"
                                />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-select
                                  :searchable="false"
                                  :placeholder="__('empty')"
                                  v-if="responsible"
                                  @on-select="function(option){ud.t2.s2r3.responsible = option.value}"
                                  :options="responsible"
                                  :readonly="true"
                                />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-input 
                                  v-model="ud.t2.s2r3.delivery"
                                  :placeholder="__('deliveryDates')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                <ve-input
                                  v-model="ud.t2.s2r3.amount"
                                  type="number"                                   
                                  :placeholder="__('amount')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                <ve-input
                                  v-model="ud.t2.s2r3.comments"
                                  :placeholder="__('comments')"
                                  />
                              </ve-form-group>
                            </ve-col>
                          </div>
                           <div class="row">
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-label>{{ __('tab2Sec2Op4') }}</ve-label>
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                  <ve-select
                                  :searchable="false"
                                  :placeholder="__('empty')"
                                  v-if="frequency"
                                  @on-select="function(option){ud.t2.s2r4.frequency = option.value}"
                                  :options="frequency"
                                  :readonly="true"
                                />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-select
                                  :searchable="false"
                                  :placeholder="__('empty')"
                                  v-if="responsible"
                                  @on-select="function(option){ud.t2.s2r4.responsible = option.value}"
                                  :options="responsible"
                                  :readonly="true"
                                />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-input 
                                  v-model="ud.t2.s2r4.delivery"
                                  :placeholder="__('deliveryDates')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                <ve-input
                                  v-model="ud.t2.s2r4.amount"
                                  type="number"                                   
                                  :placeholder="__('amount')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                <ve-input
                                  v-model="ud.t2.s2r4.comments"
                                  :placeholder="__('comments')"
                                  />
                              </ve-form-group>
                            </ve-col>
                          </div>
                           <div class="row">
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-label>{{ __('tab2Sec2Op5') }}</ve-label>
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                  <ve-select
                                  :searchable="false"
                                  :placeholder="__('empty')"
                                  v-if="frequency"
                                  @on-select="function(option){ud.t2.s2r5.frequency = option.value}"
                                  :options="frequency"
                                  :readonly="true"
                                />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-select
                                  :searchable="false"
                                  :placeholder="__('empty')"
                                  v-if="responsible"
                                  @on-select="function(option){ud.t2.s2r5.responsible = option.value}"
                                  :options="responsible"
                                  :readonly="true"
                                />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-input 
                                  v-model="ud.t2.s2r5.delivery"
                                  :placeholder="__('deliveryDates')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                <ve-input
                                  v-model="ud.t2.s2r5.amount"
                                  type="number"                                   
                                  :placeholder="__('amount')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                <ve-input
                                  v-model="ud.t2.s2r5.comments"
                                  :placeholder="__('comments')"
                                  />
                              </ve-form-group>
                            </ve-col>
                          </div>
                           <div class="row">
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-label>{{ __('tab2Sec2Op6') }}</ve-label>
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                  <ve-select
                                  :searchable="false"
                                  :placeholder="__('empty')"
                                  v-if="frequency"
                                  @on-select="function(option){ud.t2.s2r6.frequency = option.value}"
                                  :options="frequency"
                                  :readonly="true"
                                />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-select
                                  :searchable="false"
                                  :placeholder="__('empty')"
                                  v-if="responsible"
                                  @on-select="function(option){ud.t2.s2r6.responsible = option.value}"
                                  :options="responsible"
                                  :readonly="true"
                                />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-input 
                                  v-model="ud.t2.s2r6.delivery"
                                  :placeholder="__('deliveryDates')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                <ve-input
                                  v-model="ud.t2.s2r6.amount"
                                  type="number"                                   
                                  :placeholder="__('amount')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                <ve-input
                                  v-model="ud.t2.s2r6.comments"
                                  :placeholder="__('comments')"
                                  />
                              </ve-form-group>
                            </ve-col>
                          </div>
                           <div class="row">
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-label>{{ __('tab2Sec2Op7') }}</ve-label>
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                  <ve-select
                                  :searchable="false"
                                  :placeholder="__('empty')"
                                  v-if="frequency"
                                  @on-select="function(option){ud.t2.s2r7.frequency = option.value}"
                                  :options="frequency"
                                  :readonly="true"
                                />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-select
                                  :searchable="false"
                                  :placeholder="__('empty')"
                                  v-if="responsible"
                                  @on-select="function(option){ud.t2.s2r7.responsible = option.value}"
                                  :options="responsible"
                                  :readonly="true"
                                />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-input 
                                  v-model="ud.t2.s2r7.delivery"
                                  :placeholder="__('deliveryDates')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                <ve-input
                                  v-model="ud.t2.s2r7.amount"
                                  type="number"                                   
                                  :placeholder="__('amount')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                <ve-input
                                  v-model="ud.t2.s2r7.comments"
                                  :placeholder="__('comments')"
                                  />
                              </ve-form-group>
                            </ve-col>
                          </div>
                           <div class="row">
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-label>{{ __('tab2Sec2Op8') }}</ve-label>
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                  <ve-select
                                  :searchable="false"
                                  :placeholder="__('empty')"
                                  v-if="frequency"
                                  @on-select="function(option){ud.t2.s2r8.frequency = option.value}"
                                  :options="frequency"
                                  :readonly="true"
                                />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-select
                                  :searchable="false"
                                  :placeholder="__('empty')"
                                  v-if="responsible"
                                  @on-select="function(option){ud.t2.s2r8.responsible = option.value}"
                                  :options="responsible"
                                  :readonly="true"
                                />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-input 
                                  v-model="ud.t2.s2r8.delivery"
                                  :placeholder="__('deliveryDates')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                <ve-input
                                  v-model="ud.t2.s2r8.amount"
                                  type="number"                                   
                                  :placeholder="__('amount')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                <ve-input
                                  v-model="ud.t2.s2r8.comments"
                                  :placeholder="__('comments')"
                                  />
                              </ve-form-group>
                            </ve-col>
                          </div>
                           <div class="row">
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-label>{{ __('tab2Sec2Op9') }}</ve-label>
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                  <ve-select
                                  :searchable="false"
                                  :placeholder="__('empty')"
                                  v-if="frequency"
                                  @on-select="function(option){ud.t2.s2r9.frequency = option.value}"
                                  :options="frequency"
                                  :readonly="true"
                                />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-select
                                  :searchable="false"
                                  :placeholder="__('empty')"
                                  v-if="responsible"
                                  @on-select="function(option){ud.t2.s2r9.responsible = option.value}"
                                  :options="responsible"
                                  :readonly="true"
                                />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-input 
                                  v-model="ud.t2.s2r9.delivery"
                                  :placeholder="__('deliveryDates')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                <ve-input
                                  v-model="ud.t2.s2r9.amount"
                                  type="number"                                   
                                  :placeholder="__('amount')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                <ve-input
                                  v-model="ud.t2.s2r9.comments"
                                  :placeholder="__('comments')"
                                  />
                              </ve-form-group>
                            </ve-col>
                          </div>
                           <div class="row">
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-label>{{ __('tab2Sec2Op10') }}</ve-label>
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                  <ve-select
                                  :searchable="false"
                                  :placeholder="__('empty')"
                                  v-if="frequency"
                                  @on-select="function(option){ud.t2.s2r10.frequency = option.value}"
                                  :options="frequency"
                                  :readonly="true"
                                />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-select
                                  :searchable="false"
                                  :placeholder="__('empty')"
                                  v-if="responsible"
                                  @on-select="function(option){ud.t2.s2r10.responsible = option.value}"
                                  :options="responsible"
                                  :readonly="true"
                                />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-input 
                                  v-model="ud.t2.s2r10.delivery"
                                  :placeholder="__('deliveryDates')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                <ve-input
                                  v-model="ud.t2.s2r10.amount"
                                  type="number"                                   
                                  :placeholder="__('amount')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                <ve-input
                                  v-model="ud.t2.s2r10.comments"
                                  :placeholder="__('comments')"
                                  />
                              </ve-form-group>
                            </ve-col>
                          </div>
                           <div class="row">
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-label>{{ __('tab2Sec2Op11') }}</ve-label>
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                  <ve-select
                                  :searchable="false"
                                  :placeholder="__('empty')"
                                  v-if="frequency"
                                  @on-select="function(option){ud.t2.s2r11.frequency = option.value}"
                                  :options="frequency"
                                  :readonly="true"
                                />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-select
                                  :searchable="false"
                                  :placeholder="__('empty')"
                                  v-if="responsible"
                                  @on-select="function(option){ud.t2.s2r11.responsible = option.value}"
                                  :options="responsible"
                                  :readonly="true"
                                />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-input 
                                  v-model="ud.t2.s2r11.delivery"
                                  :placeholder="__('deliveryDates')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                <ve-input
                                  v-model="ud.t2.s2r11.amount"
                                  type="number"                                   
                                  :placeholder="__('amount')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                <ve-input
                                  v-model="ud.t2.s2r11.comments"
                                  :placeholder="__('comments')"
                                  />
                              </ve-form-group>
                            </ve-col>
                          </div>
                           <div class="row">
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-label>{{ __('tab2Sec2Op12') }}</ve-label>
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                  <ve-select
                                  :searchable="false"
                                  :placeholder="__('empty')"
                                  v-if="frequency"
                                  @on-select="function(option){ud.t2.s2r12.frequency = option.value}"
                                  :options="frequency"
                                  :readonly="true"
                                />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-select
                                  :searchable="false"
                                  :placeholder="__('empty')"
                                  v-if="responsible"
                                  @on-select="function(option){ud.t2.s2r12.responsible = option.value}"
                                  :options="responsible"
                                  :readonly="true"
                                />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-input 
                                  v-model="ud.t2.s2r12.delivery"
                                  :placeholder="__('deliveryDates')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                <ve-input
                                  v-model="ud.t2.s2r12.amount"
                                  type="number"                                   
                                  :placeholder="__('amount')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                <ve-input
                                  v-model="ud.t2.s2r12.comments"
                                  :placeholder="__('comments')"
                                  />
                              </ve-form-group>
                            </ve-col>
                          </div>
                           <div class="row" v-for="(s2Ot, index) in ud.t2.s2Other" v-bind:key="index">
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-input 
                                  v-model="s2Ot.moment"
                                  :placeholder="__('otherSpecify')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                  <ve-select
                                  :searchable="false"
                                  :placeholder="__('empty')"
                                  v-if="frequency"
                                  @on-select="function(option){s2Ot.frequency = option.value}"
                                  :options="frequency"
                                  :readonly="true"
                                />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-select
                                  :searchable="false"
                                  :placeholder="__('empty')"
                                  v-if="responsible"
                                  @on-select="function(option){s2Ot.responsible = option.value}"
                                  :options="responsible"
                                  :readonly="true"
                                />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-input 
                                  v-model="s2Ot.delivery"
                                  :placeholder="__('deliveryDates')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                <ve-input
                                  v-model="s2Ot.amount"
                                  type="number"                                   
                                  :placeholder="__('amount')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                <ve-input
                                  v-model="s2Ot.comments"
                                  :placeholder="__('comments')"
                                  />
                              </ve-form-group>
                            </ve-col>
                          </div>
                          <ve-col md6 xs12 >
                            <div  class="" style="padding:20px 40%;">
                              <div @click="removeOther('t2','s2Other')" class="btn-minus1">-</div>
                              <div @click="addOther('t2','s2Other')" class="btn-plus1">+</div>
                            </div>
                          </ve-col>

                        </div>
                      </div>
                      <h5 class="mt-3">{{__('tab2Sec3Head')}}</h5>
                      <div class="card border-0 shadow-sm">
                        <div class="card-body pb-0">
                           <div class="row">
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-label>{{ __('tab2Sec3Op1') }}</ve-label>
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                  <ve-select
                                  :searchable="false"
                                  :placeholder="__('empty')"
                                  v-if="frequency"
                                  @on-select="function(option){ud.t2.s3r1.frequency = option.value}"
                                  :options="frequency"
                                  :readonly="true"
                                />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-select
                                  :searchable="false"
                                  :placeholder="__('empty')"
                                  v-if="responsible"
                                  @on-select="function(option){ud.t2.s3r1.responsible = option.value}"
                                  :options="responsible"
                                  :readonly="true"
                                />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-input 
                                  v-model="ud.t2.s3r1.delivery"
                                  :placeholder="__('deliveryDates')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                <ve-input
                                  v-model="ud.t2.s3r1.amount"
                                  type="number"                                   
                                  :placeholder="__('amount')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                <ve-input
                                  v-model="ud.t2.s3r1.comments"
                                  :placeholder="__('comments')"
                                  />
                              </ve-form-group>
                            </ve-col>
                          </div>
                          <div class="row">
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-label>{{ __('tab2Sec3Op2') }}</ve-label>
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                  <ve-select
                                  :searchable="false"
                                  :placeholder="__('empty')"
                                  v-if="frequency"
                                  @on-select="function(option){ud.t2.s3r2.frequency = option.value}"
                                  :options="frequency"
                                  :readonly="true"
                                />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-select
                                  :searchable="false"
                                  :placeholder="__('empty')"
                                  v-if="responsible"
                                  @on-select="function(option){ud.t2.s3r2.responsible = option.value}"
                                  :options="responsible"
                                  :readonly="true"
                                />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-input 
                                  v-model="ud.t2.s3r2.delivery"
                                  :placeholder="__('deliveryDates')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                <ve-input
                                  v-model="ud.t2.s3r2.amount"
                                  type="number"                                   
                                  :placeholder="__('amount')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                <ve-input
                                  v-model="ud.t2.s3r2.comments"
                                  :placeholder="__('comments')"
                                  />
                              </ve-form-group>
                            </ve-col>
                          </div>
                          <div class="row">
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-label>{{ __('tab2Sec3Op3') }}</ve-label>
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                  <ve-select
                                  :searchable="false"
                                  :placeholder="__('empty')"
                                  v-if="frequency"
                                  @on-select="function(option){ud.t2.s3r3.frequency = option.value}"
                                  :options="frequency"
                                  :readonly="true"
                                />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-select
                                  :searchable="false"
                                  :placeholder="__('empty')"
                                  v-if="responsible"
                                  @on-select="function(option){ud.t2.s3r3.responsible = option.value}"
                                  :options="responsible"
                                  :readonly="true"
                                />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-input 
                                  v-model="ud.t2.s3r3.delivery"
                                  :placeholder="__('deliveryDates')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                <ve-input
                                  v-model="ud.t2.s3r3.amount"
                                  type="number"                                   
                                  :placeholder="__('amount')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                <ve-input
                                  v-model="ud.t2.s3r3.comments"
                                  :placeholder="__('comments')"
                                  />
                              </ve-form-group>
                            </ve-col>
                          </div>
                          <div class="row">
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-label>{{ __('tab2Sec3Op4') }}</ve-label>
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                  <ve-select
                                  :searchable="false"
                                  :placeholder="__('empty')"
                                  v-if="frequency"
                                  @on-select="function(option){ud.t2.s3r4.frequency = option.value}"
                                  :options="frequency"
                                  :readonly="true"
                                />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-select
                                  :searchable="false"
                                  :placeholder="__('empty')"
                                  v-if="responsible"
                                  @on-select="function(option){ud.t2.s3r4.responsible = option.value}"
                                  :options="responsible"
                                  :readonly="true"
                                />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-input 
                                  v-model="ud.t2.s3r4.delivery"
                                  :placeholder="__('deliveryDates')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                <ve-input
                                  v-model="ud.t2.s3r4.amount"
                                  type="number"                                   
                                  :placeholder="__('amount')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                <ve-input
                                  v-model="ud.t2.s3r4.comments"
                                  :placeholder="__('comments')"
                                  />
                              </ve-form-group>
                            </ve-col>
                          </div>
                          <div class="row">
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-label>{{ __('tab2Sec3Op5') }}</ve-label>
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                  <ve-select
                                  :searchable="false"
                                  :placeholder="__('empty')"
                                  v-if="frequency"
                                  @on-select="function(option){ud.t2.s3r5.frequency = option.value}"
                                  :options="frequency"
                                  :readonly="true"
                                />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-select
                                  :searchable="false"
                                  :placeholder="__('empty')"
                                  v-if="responsible"
                                  @on-select="function(option){ud.t2.s3r5.responsible = option.value}"
                                  :options="responsible"
                                  :readonly="true"
                                />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-input 
                                  v-model="ud.t2.s3r5.delivery"
                                  :placeholder="__('deliveryDates')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                <ve-input
                                  v-model="ud.t2.s3r5.amount"
                                  type="number"                                   
                                  :placeholder="__('amount')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                <ve-input
                                  v-model="ud.t2.s3r5.comments"
                                  :placeholder="__('comments')"
                                  />
                              </ve-form-group>
                            </ve-col>
                          </div>
                          <div class="row" v-for="(s3Ot, index) in ud.t2.s3Other" v-bind:key="index">
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-input 
                                  v-model="s3Ot.moment"
                                  :placeholder="__('otherSpecify')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                  <ve-select
                                  :searchable="false"
                                  :placeholder="__('empty')"
                                  v-if="frequency"
                                  @on-select="function(option){s3Ot.frequency = option.value}"
                                  :options="frequency"
                                  :readonly="true"
                                />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-select
                                  :searchable="false"
                                  :placeholder="__('empty')"
                                  v-if="responsible"
                                  @on-select="function(option){s3Ot.responsible = option.value}"
                                  :options="responsible"
                                  :readonly="true"
                                />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs12 sm2>
                              <ve-form-group>
                                <ve-input 
                                  v-model="s3Ot.delivery"
                                  :placeholder="__('deliveryDates')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                <ve-input
                                  v-model="s3Ot.amount"
                                  type="number"                                   
                                  :placeholder="__('amount')"
                                  />
                              </ve-form-group>
                            </ve-col>
                            <ve-col xs6 sm2>
                              <ve-form-group>
                                <ve-input
                                  v-model="s3Ot.comments"
                                  :placeholder="__('comments')"
                                  />
                              </ve-form-group>
                            </ve-col>
                          </div>
                          <ve-col md6 xs12 >
                            <div  class="" style="padding:20px 40%;">
                              <div @click="removeOther('t2','s3Other')" class="btn-minus1">-</div>
                              <div @click="addOther('t2','s3Other')" class="btn-plus1">+</div>
                            </div>
                          </ve-col>

                        </div>
                      </div>
    </div>
</template>

<script>

export default {
    name: "ve-tab-salary",
    props: {
        responsible : {
            type : Array,
            default: null
        },
        frequency : {
            type : Array,
            default: null
        },
        ud : {
            type: Object,
            default: () => { return {} }
        },
    },
    data() {
        return {
            validation_error: false
        }
    },
    methods: {
        addOther(tab,sec){
			this.ud[tab][sec].push({
						moment : null,
						frequency : null,
						responsible: null,
						delivery: null,
						amount : null,
						comments: null
					})
		},
		removeOther(tab,sec){
			this.ud[tab][sec].splice(this.ud[tab][sec].length-1, 1)
		},
        handleInput(e) {

        },
    },
    created() {
    }
}
</script>

<style lang="scss" scoped>


</style>
