<template>
    <div>
        <!--<h3 v-if="data.missionType && data.missionType.salary">{{data.translations.tabSalary[data.lang]}}</h3>-->
		<h3 v-if="data.missionType && data.missionType.salary&& data.t2">
				{{data.translations.bilaga[data.lang]}} 
					<span v-if="data.missionType && data.missionType.financial">4</span>
					<span v-else>3</span>: {{data.translations.missionSalary[data.lang]}}
		</h3>
		<div class="no-page-break" v-if="data.missionType && data.missionType.salary && data.t2">
			<div class="flex-columns">
				<ve-col md12 v-if="data.t2.s1r1.responsible||data.t2.s1r2.responsible||data.t2.s1r3.responsible||data.t2.s1r4.responsible||data.t2.s1r5.responsible||data.t2.s1r6.responsible||data.t2.s1r7.responsible||data.t2.s1r8.responsible||data.t2.s1r9.responsible||data.t2.s1r10.responsible||data.t2.s1r11.responsible||data.t2.s1Other[0].moment">
					<b>{{data.translations.tab2Sec1Head[data.lang]}}</b>
				</ve-col>

				<ve-col md2 v-if="data.t2.s1r1.responsible">
					{{data.translations.tab2Sec1Op1[data.lang]}}
				</ve-col>
				<ve-col md2 v-if="data.t2.s1r1.responsible">{{data.translations.frequency[data.lang]}}:&nbsp;
					<b v-if="data.t2.s1r1.frequency">{{data.translations[data.t2.s1r1.frequency][data.lang]}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t2.s1r1.responsible">{{data.translations.responsible[data.lang]}}:&nbsp;
					<b v-if="data.t2.s1r1.responsible">{{data.translations[data.t2.s1r1.responsible][data.lang]}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t2.s1r1.responsible">{{data.translations.deliveryDates[data.lang]}}:&nbsp;
					<b v-if="data.t2.s1r1.delivery">{{data.t2.s1r1.delivery}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t2.s1r1.responsible">{{data.translations.amount[data.lang]}}:&nbsp;
					<b v-if="data.t2.s1r1.amount">{{data.t2.s1r1.amount}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t2.s1r1.responsible">{{data.translations.comments[data.lang]}}:&nbsp;
					<b v-if="data.t2.s1r1.comments"> {{data.t2.s1r1.comments}}</b>
				</ve-col>

				<ve-col md2 v-if="data.t2.s1r2.responsible">
					{{data.translations.tab2Sec1Op2[data.lang]}}
				</ve-col>
				<ve-col md2 v-if="data.t2.s1r2.responsible">{{data.translations.frequency[data.lang]}}:&nbsp;
					<b v-if="data.t2.s1r2.frequency">{{data.translations[data.t2.s1r2.frequency][data.lang]}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t2.s1r2.responsible">{{data.translations.responsible[data.lang]}}:&nbsp;
					<b v-if="data.t2.s1r2.responsible">{{data.translations[data.t2.s1r2.responsible][data.lang]}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t2.s1r2.responsible">{{data.translations.deliveryDates[data.lang]}}:&nbsp;
					<b v-if="data.t2.s1r2.delivery">{{data.t2.s1r2.delivery}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t2.s1r2.responsible">{{data.translations.amount[data.lang]}}:&nbsp;
					<b v-if="data.t2.s1r2.amount">{{data.t2.s1r2.amount}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t2.s1r2.responsible">{{data.translations.comments[data.lang]}}:&nbsp;
					<b v-if="data.t2.s1r2.comments"> {{data.t2.s1r2.comments}}</b>
				</ve-col>

				<ve-col md2 v-if="data.t2.s1r3.responsible">
					{{data.translations.tab2Sec1Op3[data.lang]}}
				</ve-col>
				<ve-col md2 v-if="data.t2.s1r3.responsible">{{data.translations.frequency[data.lang]}}:&nbsp;
					<b v-if="data.t2.s1r3.frequency">{{data.translations[data.t2.s1r3.frequency][data.lang]}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t2.s1r3.responsible">{{data.translations.responsible[data.lang]}}:&nbsp;
					<b v-if="data.t2.s1r3.responsible">{{data.translations[data.t2.s1r3.responsible][data.lang]}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t2.s1r3.responsible">{{data.translations.deliveryDates[data.lang]}}:&nbsp;
					<b v-if="data.t2.s1r3.delivery">{{data.t2.s1r3.delivery}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t2.s1r3.responsible">{{data.translations.amount[data.lang]}}:&nbsp;
					<b v-if="data.t2.s1r3.amount">{{data.t2.s1r3.amount}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t2.s1r3.responsible">{{data.translations.comments[data.lang]}}:&nbsp;
					<b v-if="data.t2.s1r3.comments"> {{data.t2.s1r3.comments}}</b>
				</ve-col>

				<ve-col md2 v-if="data.t2.s1r4.responsible">
					{{data.translations.tab2Sec1Op4[data.lang]}}
				</ve-col>
				<ve-col md2 v-if="data.t2.s1r4.responsible">{{data.translations.frequency[data.lang]}}:&nbsp;
					<b v-if="data.t2.s1r4.frequency">{{data.translations[data.t2.s1r4.frequency][data.lang]}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t2.s1r4.responsible">{{data.translations.responsible[data.lang]}}:&nbsp;
					<b v-if="data.t2.s1r4.responsible">{{data.translations[data.t2.s1r4.responsible][data.lang]}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t2.s1r4.responsible">{{data.translations.deliveryDates[data.lang]}}:&nbsp;
					<b v-if="data.t2.s1r4.delivery">{{data.t2.s1r4.delivery}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t2.s1r4.responsible">{{data.translations.amount[data.lang]}}:&nbsp;
					<b v-if="data.t2.s1r4.amount">{{data.t2.s1r4.amount}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t2.s1r4.responsible">{{data.translations.comments[data.lang]}}:&nbsp;
					<b v-if="data.t2.s1r4.comments"> {{data.t2.s1r4.comments}}</b>
				</ve-col>

				<ve-col md2 v-if="data.t2.s1r5.responsible">
					{{data.translations.tab2Sec1Op5[data.lang]}}
				</ve-col>
				<ve-col md2 v-if="data.t2.s1r5.responsible">{{data.translations.frequency[data.lang]}}:&nbsp;
					<b v-if="data.t2.s1r5.frequency">{{data.translations[data.t2.s1r5.frequency][data.lang]}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t2.s1r5.responsible">{{data.translations.responsible[data.lang]}}:&nbsp;
					<b v-if="data.t2.s1r5.responsible">{{data.translations[data.t2.s1r5.responsible][data.lang]}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t2.s1r5.responsible">{{data.translations.deliveryDates[data.lang]}}:&nbsp;
					<b v-if="data.t2.s1r5.delivery">{{data.t2.s1r5.delivery}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t2.s1r5.responsible">{{data.translations.amount[data.lang]}}:&nbsp;
					<b v-if="data.t2.s1r5.amount">{{data.t2.s1r5.amount}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t2.s1r5.responsible">{{data.translations.comments[data.lang]}}:&nbsp;
					<b v-if="data.t2.s1r5.comments"> {{data.t2.s1r5.comments}}</b>
				</ve-col>

				<ve-col md2 v-if="data.t2.s1r6.responsible">
					{{data.translations.tab2Sec1Op6[data.lang]}}
				</ve-col>
				<ve-col md2 v-if="data.t2.s1r6.responsible">{{data.translations.frequency[data.lang]}}:&nbsp;
					<b v-if="data.t2.s1r6.frequency">{{data.translations[data.t2.s1r6.frequency][data.lang]}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t2.s1r6.responsible">{{data.translations.responsible[data.lang]}}:&nbsp;
					<b v-if="data.t2.s1r6.responsible">{{data.translations[data.t2.s1r6.responsible][data.lang]}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t2.s1r6.responsible">{{data.translations.deliveryDates[data.lang]}}:&nbsp;
					<b v-if="data.t2.s1r6.delivery">{{data.t2.s1r6.delivery}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t2.s1r6.responsible">{{data.translations.amount[data.lang]}}:&nbsp;
					<b v-if="data.t2.s1r6.amount">{{data.t2.s1r6.amount}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t2.s1r6.responsible">{{data.translations.comments[data.lang]}}:&nbsp;
					<b v-if="data.t2.s1r6.comments"> {{data.t2.s1r6.comments}}</b>
				</ve-col>

				<ve-col md2 v-if="data.t2.s1r7.responsible">
					{{data.translations.tab2Sec1Op7[data.lang]}}
				</ve-col>
				<ve-col md2 v-if="data.t2.s1r7.responsible">{{data.translations.frequency[data.lang]}}:&nbsp;
					<b v-if="data.t2.s1r7.frequency">{{data.translations[data.t2.s1r7.frequency][data.lang]}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t2.s1r7.responsible">{{data.translations.responsible[data.lang]}}:&nbsp;
					<b v-if="data.t2.s1r7.responsible">{{data.translations[data.t2.s1r7.responsible][data.lang]}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t2.s1r7.responsible">{{data.translations.deliveryDates[data.lang]}}:&nbsp;
					<b v-if="data.t2.s1r7.delivery">{{data.t2.s1r7.delivery}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t2.s1r7.responsible">{{data.translations.amount[data.lang]}}:&nbsp;
					<b v-if="data.t2.s1r7.amount">{{data.t2.s1r7.amount}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t2.s1r7.responsible">{{data.translations.comments[data.lang]}}:&nbsp;
					<b v-if="data.t2.s1r7.comments"> {{data.t2.s1r7.comments}}</b>
				</ve-col>

				<ve-col md2 v-if="data.t2.s1r8.responsible">
					{{data.translations.tab2Sec1Op8[data.lang]}}
				</ve-col>
				<ve-col md2 v-if="data.t2.s1r8.responsible">{{data.translations.frequency[data.lang]}}:&nbsp;
					<b v-if="data.t2.s1r8.frequency">{{data.translations[data.t2.s1r8.frequency][data.lang]}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t2.s1r8.responsible">{{data.translations.responsible[data.lang]}}:&nbsp;
					<b v-if="data.t2.s1r8.responsible">{{data.translations[data.t2.s1r8.responsible][data.lang]}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t2.s1r8.responsible">{{data.translations.deliveryDates[data.lang]}}:&nbsp;
					<b v-if="data.t2.s1r8.delivery">{{data.t2.s1r8.delivery}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t2.s1r8.responsible">{{data.translations.amount[data.lang]}}:&nbsp;
					<b v-if="data.t2.s1r8.amount">{{data.t2.s1r8.amount}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t2.s1r8.responsible">{{data.translations.comments[data.lang]}}:&nbsp;
					<b v-if="data.t2.s1r8.comments"> {{data.t2.s1r8.comments}}</b>
				</ve-col>

				<ve-col md2 v-if="data.t2.s1r9.responsible">
					{{data.translations.tab2Sec1Op9[data.lang]}}
				</ve-col>
				<ve-col md2 v-if="data.t2.s1r9.responsible">{{data.translations.frequency[data.lang]}}:&nbsp;
					<b v-if="data.t2.s1r9.frequency">{{data.translations[data.t2.s1r9.frequency][data.lang]}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t2.s1r9.responsible">{{data.translations.responsible[data.lang]}}:&nbsp;
					<b v-if="data.t2.s1r9.responsible">{{data.translations[data.t2.s1r9.responsible][data.lang]}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t2.s1r9.responsible">{{data.translations.deliveryDates[data.lang]}}:&nbsp;
					<b v-if="data.t2.s1r9.delivery">{{data.t2.s1r9.delivery}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t2.s1r9.responsible">{{data.translations.amount[data.lang]}}:&nbsp;
					<b v-if="data.t2.s1r9.amount">{{data.t2.s1r9.amount}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t2.s1r9.responsible">{{data.translations.comments[data.lang]}}:&nbsp;
					<b v-if="data.t2.s1r9.comments"> {{data.t2.s1r9.comments}}</b>
				</ve-col>

				<ve-col md2 v-if="data.t2.s1r10.responsible">
					{{data.translations.tab2Sec1Op10[data.lang]}}
				</ve-col>
				<ve-col md2 v-if="data.t2.s1r10.responsible">{{data.translations.frequency[data.lang]}}:&nbsp;
					<b v-if="data.t2.s1r10.frequency">{{data.translations[data.t2.s1r10.frequency][data.lang]}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t2.s1r10.responsible">{{data.translations.responsible[data.lang]}}:&nbsp;
					<b v-if="data.t2.s1r10.responsible">{{data.translations[data.t2.s1r10.responsible][data.lang]}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t2.s1r10.responsible">{{data.translations.deliveryDates[data.lang]}}:&nbsp;
					<b v-if="data.t2.s1r10.delivery">{{data.t2.s1r10.delivery}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t2.s1r10.responsible">{{data.translations.amount[data.lang]}}:&nbsp;
					<b v-if="data.t2.s1r10.amount">{{data.t2.s1r10.amount}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t2.s1r10.responsible">{{data.translations.comments[data.lang]}}:&nbsp;
					<b v-if="data.t2.s1r10.comments"> {{data.t2.s1r10.comments}}</b>
				</ve-col>

				<ve-col md2 v-if="data.t2.s1r11.responsible">
					{{data.translations.tab2Sec1Op11[data.lang]}}
				</ve-col>
				<ve-col md2 v-if="data.t2.s1r11.responsible">{{data.translations.frequency[data.lang]}}:&nbsp;
					<b v-if="data.t2.s1r11.frequency">{{data.translations[data.t2.s1r11.frequency][data.lang]}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t2.s1r11.responsible">{{data.translations.responsible[data.lang]}}:&nbsp;
					<b v-if="data.t2.s1r11.responsible">{{data.translations[data.t2.s1r11.responsible][data.lang]}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t2.s1r11.responsible">{{data.translations.deliveryDates[data.lang]}}:&nbsp;
					<b v-if="data.t2.s1r11.delivery">{{data.t2.s1r11.delivery}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t2.s1r11.responsible">{{data.translations.amount[data.lang]}}:&nbsp;
					<b v-if="data.t2.s1r11.amount">{{data.t2.s1r11.amount}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t2.s1r11.responsible">{{data.translations.comments[data.lang]}}:&nbsp;
					<b v-if="data.t2.s1r11.comments"> {{data.t2.s1r11.comments}}</b>
				</ve-col>
			</div>
            <div class="flex-columns" v-for="t2Oth in data.t2.s1Other">
					<ve-col md2 v-if="t2Oth.moment&&t2Oth.responsible">{{t2Oth.moment}}</ve-col>
					<ve-col md2 v-if="t2Oth.moment&&t2Oth.responsible">{{data.translations.frequency[data.lang]}}:&nbsp;
						<b v-if="t2Oth.frequency">{{data.translations[t2Oth.frequency][data.lang]}}</b>
					</ve-col>
					<ve-col md2 v-if="t2Oth.moment&&t2Oth.responsible">{{data.translations.responsible[data.lang]}}:&nbsp;
						<b v-if="t2Oth.responsible">{{data.translations[t2Oth.responsible][data.lang]}}</b>
					</ve-col>
					<ve-col md2 v-if="t2Oth.moment&&t2Oth.responsible">{{data.translations.deliveryDates[data.lang]}}:&nbsp;
						<b v-if="t2Oth.delivery">{{t2Oth.delivery}}</b>
					</ve-col>
					<ve-col md2 v-if="t2Oth.moment&&t2Oth.responsible">{{data.translations.amount[data.lang]}}:&nbsp;
						<b v-if="t2Oth.amount">{{t2Oth.amount}}</b>
					</ve-col>
					<ve-col md2 v-if="t2Oth.moment&&t2Oth.responsible">{{data.translations.comments[data.lang]}}:&nbsp;
						<b v-if="t2Oth.comments"> {{t2Oth.comments}}</b>
					</ve-col>
				</div>
		</div>
		<div class="no-page-break" v-if="data.missionType && data.missionType.salary && data.t2">
			<div class="flex-columns">
				<ve-col md12 v-if="data.t2.s2r1.responsible||data.t2.s2r2.responsible||data.t2.s2r3.responsible||data.t2.s2r4.responsible||data.t2.s2r5.responsible||data.t2.s2r6.responsible||data.t2.s2r7.responsible||data.t2.s2r8.responsible||data.t2.s2r9.responsible||data.t2.s2r10.responsible||data.t2.s2r11.responsible||data.t2.s2r12.responsible||data.t2.s2Other[0].moment">
					<b>{{data.translations.tab2Sec2Head[data.lang]}}</b>
				</ve-col>

				<ve-col md2 v-if="data.t2.s2r1.responsible">
					{{data.translations.tab2Sec2Op1[data.lang]}}
				</ve-col>
				<ve-col md2 v-if="data.t2.s2r1.responsible">{{data.translations.frequency[data.lang]}}:&nbsp;
					<b v-if="data.t2.s2r1.frequency">{{data.translations[data.t2.s2r1.frequency][data.lang]}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t2.s2r1.responsible">{{data.translations.responsible[data.lang]}}:&nbsp;
					<b v-if="data.t2.s2r1.responsible">{{data.translations[data.t2.s2r1.responsible][data.lang]}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t2.s2r1.responsible">{{data.translations.deliveryDates[data.lang]}}:&nbsp;
					<b v-if="data.t2.s2r1.delivery">{{data.t2.s2r1.delivery}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t2.s2r1.responsible">{{data.translations.amount[data.lang]}}:&nbsp;
					<b v-if="data.t2.s2r1.amount">{{data.t2.s2r1.amount}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t2.s2r1.responsible">{{data.translations.comments[data.lang]}}:&nbsp;
					<b v-if="data.t2.s2r1.comments"> {{data.t2.s2r1.comments}}</b>
				</ve-col>

				<ve-col md2 v-if="data.t2.s2r2.responsible">
					{{data.translations.tab2Sec2Op2[data.lang]}}
				</ve-col>
				<ve-col md2 v-if="data.t2.s2r2.responsible">{{data.translations.frequency[data.lang]}}:&nbsp;
					<b v-if="data.t2.s2r2.frequency">{{data.translations[data.t2.s2r2.frequency][data.lang]}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t2.s2r2.responsible">{{data.translations.responsible[data.lang]}}:&nbsp;
					<b v-if="data.t2.s2r2.responsible">{{data.translations[data.t2.s2r2.responsible][data.lang]}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t2.s2r2.responsible">{{data.translations.deliveryDates[data.lang]}}:&nbsp;
					<b v-if="data.t2.s2r2.delivery">{{data.t2.s2r2.delivery}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t2.s2r2.responsible">{{data.translations.amount[data.lang]}}:&nbsp;
					<b v-if="data.t2.s2r2.amount">{{data.t2.s2r2.amount}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t2.s2r2.responsible">{{data.translations.comments[data.lang]}}:&nbsp;
					<b v-if="data.t2.s2r2.comments"> {{data.t2.s2r2.comments}}</b>
				</ve-col>

				<ve-col md2 v-if="data.t2.s2r3.responsible">
					{{data.translations.tab2Sec2Op3[data.lang]}}
				</ve-col>
				<ve-col md2 v-if="data.t2.s2r3.responsible">{{data.translations.frequency[data.lang]}}:&nbsp;
					<b v-if="data.t2.s2r3.frequency">{{data.translations[data.t2.s2r3.frequency][data.lang]}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t2.s2r3.responsible">{{data.translations.responsible[data.lang]}}:&nbsp;
					<b v-if="data.t2.s2r3.responsible">{{data.translations[data.t2.s2r3.responsible][data.lang]}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t2.s2r3.responsible">{{data.translations.deliveryDates[data.lang]}}:&nbsp;
					<b v-if="data.t2.s2r3.delivery">{{data.t2.s2r3.delivery}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t2.s2r3.responsible">{{data.translations.amount[data.lang]}}:&nbsp;
					<b v-if="data.t2.s2r3.amount">{{data.t2.s2r3.amount}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t2.s2r3.responsible">{{data.translations.comments[data.lang]}}:&nbsp;
					<b v-if="data.t2.s2r3.comments"> {{data.t2.s2r3.comments}}</b>
				</ve-col>

				<ve-col md2 v-if="data.t2.s2r4.responsible">
					{{data.translations.tab2Sec2Op4[data.lang]}}
				</ve-col>
				<ve-col md2 v-if="data.t2.s2r4.responsible">{{data.translations.frequency[data.lang]}}:&nbsp;
					<b v-if="data.t2.s2r4.frequency">{{data.translations[data.t2.s2r4.frequency][data.lang]}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t2.s2r4.responsible">{{data.translations.responsible[data.lang]}}:&nbsp;
					<b v-if="data.t2.s2r4.responsible">{{data.translations[data.t2.s2r4.responsible][data.lang]}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t2.s2r4.responsible">{{data.translations.deliveryDates[data.lang]}}:&nbsp;
					<b v-if="data.t2.s2r4.delivery">{{data.t2.s2r4.delivery}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t2.s2r4.responsible">{{data.translations.amount[data.lang]}}:&nbsp;
					<b v-if="data.t2.s2r4.amount">{{data.t2.s2r4.amount}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t2.s2r4.responsible">{{data.translations.comments[data.lang]}}:&nbsp;
					<b v-if="data.t2.s2r4.comments"> {{data.t2.s2r4.comments}}</b>
				</ve-col>

				<ve-col md2 v-if="data.t2.s2r5.responsible">
					{{data.translations.tab2Sec2Op5[data.lang]}}
				</ve-col>
				<ve-col md2 v-if="data.t2.s2r5.responsible">{{data.translations.frequency[data.lang]}}:&nbsp;
					<b v-if="data.t2.s2r5.frequency">{{data.translations[data.t2.s2r5.frequency][data.lang]}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t2.s2r5.responsible">{{data.translations.responsible[data.lang]}}:&nbsp;
					<b v-if="data.t2.s2r5.responsible">{{data.translations[data.t2.s2r5.responsible][data.lang]}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t2.s2r5.responsible">{{data.translations.deliveryDates[data.lang]}}:&nbsp;
					<b v-if="data.t2.s2r5.delivery">{{data.t2.s2r5.delivery}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t2.s2r5.responsible">{{data.translations.amount[data.lang]}}:&nbsp;
					<b v-if="data.t2.s2r5.amount">{{data.t2.s2r5.amount}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t2.s2r5.responsible">{{data.translations.comments[data.lang]}}:&nbsp;
					<b v-if="data.t2.s2r5.comments"> {{data.t2.s2r5.comments}}</b>
				</ve-col>

				<ve-col md2 v-if="data.t2.s2r6.responsible">
					{{data.translations.tab2Sec2Op6[data.lang]}}
				</ve-col>
				<ve-col md2 v-if="data.t2.s2r6.responsible">{{data.translations.frequency[data.lang]}}:&nbsp;
					<b v-if="data.t2.s2r6.frequency">{{data.translations[data.t2.s2r6.frequency][data.lang]}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t2.s2r6.responsible">{{data.translations.responsible[data.lang]}}:&nbsp;
					<b v-if="data.t2.s2r6.responsible">{{data.translations[data.t2.s2r6.responsible][data.lang]}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t2.s2r6.responsible">{{data.translations.deliveryDates[data.lang]}}:&nbsp;
					<b v-if="data.t2.s2r6.delivery">{{data.t2.s2r6.delivery}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t2.s2r6.responsible">{{data.translations.amount[data.lang]}}:&nbsp;
					<b v-if="data.t2.s2r6.amount">{{data.t2.s2r6.amount}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t2.s2r6.responsible">{{data.translations.comments[data.lang]}}:&nbsp;
					<b v-if="data.t2.s2r6.comments"> {{data.t2.s2r6.comments}}</b>
				</ve-col>

				<ve-col md2 v-if="data.t2.s2r7.responsible">
					{{data.translations.tab2Sec2Op7[data.lang]}}
				</ve-col>
				<ve-col md2 v-if="data.t2.s2r7.responsible">{{data.translations.frequency[data.lang]}}:&nbsp;
					<b v-if="data.t2.s2r7.frequency">{{data.translations[data.t2.s2r7.frequency][data.lang]}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t2.s2r7.responsible">{{data.translations.responsible[data.lang]}}:&nbsp;
					<b v-if="data.t2.s2r7.responsible">{{data.translations[data.t2.s2r7.responsible][data.lang]}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t2.s2r7.responsible">{{data.translations.deliveryDates[data.lang]}}:&nbsp;
					<b v-if="data.t2.s2r7.delivery">{{data.t2.s2r7.delivery}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t2.s2r7.responsible">{{data.translations.amount[data.lang]}}:&nbsp;
					<b v-if="data.t2.s2r7.amount">{{data.t2.s2r7.amount}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t2.s2r7.responsible">{{data.translations.comments[data.lang]}}:&nbsp;
					<b v-if="data.t2.s2r7.comments"> {{data.t2.s2r7.comments}}</b>
				</ve-col>

				<ve-col md2 v-if="data.t2.s2r8.responsible">
					{{data.translations.tab2Sec2Op8[data.lang]}}
				</ve-col>
				<ve-col md2 v-if="data.t2.s2r8.responsible">{{data.translations.frequency[data.lang]}}:&nbsp;
					<b v-if="data.t2.s2r8.frequency">{{data.translations[data.t2.s2r8.frequency][data.lang]}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t2.s2r8.responsible">{{data.translations.responsible[data.lang]}}:&nbsp;
					<b v-if="data.t2.s2r8.responsible">{{data.translations[data.t2.s2r8.responsible][data.lang]}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t2.s2r8.responsible">{{data.translations.deliveryDates[data.lang]}}:&nbsp;
					<b v-if="data.t2.s2r8.delivery">{{data.t2.s2r8.delivery}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t2.s2r8.responsible">{{data.translations.amount[data.lang]}}:&nbsp;
					<b v-if="data.t2.s2r8.amount">{{data.t2.s2r8.amount}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t2.s2r8.responsible">{{data.translations.comments[data.lang]}}:&nbsp;
					<b v-if="data.t2.s2r8.comments"> {{data.t2.s2r8.comments}}</b>
				</ve-col>

				<ve-col md2 v-if="data.t2.s2r9.responsible">
					{{data.translations.tab2Sec2Op9[data.lang]}}
				</ve-col>
				<ve-col md2 v-if="data.t2.s2r9.responsible">{{data.translations.frequency[data.lang]}}:&nbsp;
					<b v-if="data.t2.s2r9.frequency">{{data.translations[data.t2.s2r9.frequency][data.lang]}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t2.s2r9.responsible">{{data.translations.responsible[data.lang]}}:&nbsp;
					<b v-if="data.t2.s2r9.responsible">{{data.translations[data.t2.s2r9.responsible][data.lang]}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t2.s2r9.responsible">{{data.translations.deliveryDates[data.lang]}}:&nbsp;
					<b v-if="data.t2.s2r9.delivery">{{data.t2.s2r9.delivery}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t2.s2r9.responsible">{{data.translations.amount[data.lang]}}:&nbsp;
					<b v-if="data.t2.s2r9.amount">{{data.t2.s2r9.amount}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t2.s2r9.responsible">{{data.translations.comments[data.lang]}}:&nbsp;
					<b v-if="data.t2.s2r9.comments"> {{data.t2.s2r9.comments}}</b>
				</ve-col>

				<ve-col md2 v-if="data.t2.s2r10.responsible">
					{{data.translations.tab2Sec2Op10[data.lang]}}
				</ve-col>
				<ve-col md2 v-if="data.t2.s2r10.responsible">{{data.translations.frequency[data.lang]}}:&nbsp;
					<b v-if="data.t2.s2r10.frequency">{{data.translations[data.t2.s2r10.frequency][data.lang]}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t2.s2r10.responsible">{{data.translations.responsible[data.lang]}}:&nbsp;
					<b v-if="data.t2.s2r10.responsible">{{data.translations[data.t2.s2r10.responsible][data.lang]}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t2.s2r10.responsible">{{data.translations.deliveryDates[data.lang]}}:&nbsp;
					<b v-if="data.t2.s2r10.delivery">{{data.t2.s2r10.delivery}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t2.s2r10.responsible">{{data.translations.amount[data.lang]}}:&nbsp;
					<b v-if="data.t2.s2r10.amount">{{data.t2.s2r10.amount}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t2.s2r10.responsible">{{data.translations.comments[data.lang]}}:&nbsp;
					<b v-if="data.t2.s2r10.comments"> {{data.t2.s2r10.comments}}</b>
				</ve-col>

				<ve-col md2 v-if="data.t2.s2r11.responsible">
					{{data.translations.tab2Sec2Op11[data.lang]}}
				</ve-col>
				<ve-col md2 v-if="data.t2.s2r11.responsible">{{data.translations.frequency[data.lang]}}:&nbsp;
					<b v-if="data.t2.s2r11.frequency">{{data.translations[data.t2.s2r11.frequency][data.lang]}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t2.s2r11.responsible">{{data.translations.responsible[data.lang]}}:&nbsp;
					<b v-if="data.t2.s2r11.responsible">{{data.translations[data.t2.s2r11.responsible][data.lang]}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t2.s2r11.responsible">{{data.translations.deliveryDates[data.lang]}}:&nbsp;
					<b v-if="data.t2.s2r11.delivery">{{data.t2.s2r11.delivery}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t2.s2r11.responsible">{{data.translations.amount[data.lang]}}:&nbsp;
					<b v-if="data.t2.s2r11.amount">{{data.t2.s2r11.amount}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t2.s2r11.responsible">{{data.translations.comments[data.lang]}}:&nbsp;
					<b v-if="data.t2.s2r11.comments"> {{data.t2.s2r11.comments}}</b>
				</ve-col>

				<ve-col md2 v-if="data.t2.s2r12.responsible">
					{{data.translations.tab2Sec2Op12[data.lang]}}
				</ve-col>
				<ve-col md2 v-if="data.t2.s2r12.responsible">{{data.translations.frequency[data.lang]}}:&nbsp;
					<b v-if="data.t2.s2r12.frequency">{{data.translations[data.t2.s2r12.frequency][data.lang]}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t2.s2r12.responsible">{{data.translations.responsible[data.lang]}}:&nbsp;
					<b v-if="data.t2.s2r12.responsible">{{data.translations[data.t2.s2r12.responsible][data.lang]}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t2.s2r12.responsible">{{data.translations.deliveryDates[data.lang]}}:&nbsp;
					<b v-if="data.t2.s2r12.delivery">{{data.t2.s2r12.delivery}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t2.s2r12.responsible">{{data.translations.amount[data.lang]}}:&nbsp;
					<b v-if="data.t2.s2r12.amount">{{data.t2.s2r12.amount}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t2.s2r12.responsible">{{data.translations.comments[data.lang]}}:&nbsp;
					<b v-if="data.t2.s2r12.comments"> {{data.t2.s2r12.comments}}</b>
				</ve-col>
			</div>
			<div class="flex-columns" v-for="t2Oth in data.t2.s2Other">
                <ve-col md2 v-if="t2Oth.moment&&t2Oth.responsible">{{t2Oth.moment}}</ve-col>
                <ve-col md2 v-if="t2Oth.moment&&t2Oth.responsible">{{data.translations.frequency[data.lang]}}:&nbsp;
                    <b v-if="t2Oth.frequency">{{data.translations[t2Oth.frequency][data.lang]}}</b>
                </ve-col>
                <ve-col md2 v-if="t2Oth.moment&&t2Oth.responsible">{{data.translations.responsible[data.lang]}}:&nbsp;
                    <b v-if="t2Oth.responsible">{{data.translations[t2Oth.responsible][data.lang]}}</b>
                </ve-col>
                <ve-col md2 v-if="t2Oth.moment&&t2Oth.responsible">{{data.translations.deliveryDates[data.lang]}}:&nbsp;
                    <b v-if="t2Oth.delivery">{{t2Oth.delivery}}</b>
                </ve-col>
                <ve-col md2 v-if="t2Oth.moment&&t2Oth.responsible">{{data.translations.amount[data.lang]}}:&nbsp;
                    <b v-if="t2Oth.amount">{{t2Oth.amount}}</b>
                </ve-col>
                <ve-col md2 v-if="t2Oth.moment&&t2Oth.responsible">{{data.translations.comments[data.lang]}}:&nbsp;
                    <b v-if="t2Oth.comments"> {{t2Oth.comments}}</b>
                </ve-col>
            </div>
		</div>
		<div class="no-page-break" v-if="data.missionType && data.missionType.salary && data.t2">
			<div class="flex-columns">
				<ve-col md12 v-if="data.t2.s3r1.responsible||data.t2.s3r2.responsible||data.t2.s3r3.responsible||data.t2.s3r4.responsible||data.t2.s3r5.responsible||data.t2.s3Other[0].moment">
					<b>{{data.translations.tab2Sec3Head[data.lang]}}</b>
				</ve-col>

				<ve-col md2 v-if="data.t2.s3r1.responsible">
					{{data.translations.tab2Sec3Op1[data.lang]}}
				</ve-col>
				<ve-col md2 v-if="data.t2.s3r1.responsible">{{data.translations.frequency[data.lang]}}:&nbsp;
					<b v-if="data.t2.s3r1.frequency">{{data.translations[data.t2.s3r1.frequency][data.lang]}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t2.s3r1.responsible">{{data.translations.responsible[data.lang]}}:&nbsp;
					<b v-if="data.t2.s3r1.responsible">{{data.translations[data.t2.s3r1.responsible][data.lang]}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t2.s3r1.responsible">{{data.translations.deliveryDates[data.lang]}}:&nbsp;
					<b v-if="data.t2.s3r1.delivery">{{data.t2.s3r1.delivery}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t2.s3r1.responsible">{{data.translations.amount[data.lang]}}:&nbsp;
					<b v-if="data.t2.s3r1.amount">{{data.t2.s3r1.amount}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t2.s3r1.responsible">{{data.translations.comments[data.lang]}}:&nbsp;
					<b v-if="data.t2.s3r1.comments"> {{data.t2.s3r1.comments}}</b>
				</ve-col>

				<ve-col md2 v-if="data.t2.s3r2.responsible">
					{{data.translations.tab2Sec3Op2[data.lang]}}
				</ve-col>
				<ve-col md2 v-if="data.t2.s3r2.responsible">{{data.translations.frequency[data.lang]}}:&nbsp;
					<b v-if="data.t2.s3r2.frequency">{{data.translations[data.t2.s3r2.frequency][data.lang]}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t2.s3r2.responsible">{{data.translations.responsible[data.lang]}}:&nbsp;
					<b v-if="data.t2.s3r2.responsible">{{data.translations[data.t2.s3r2.responsible][data.lang]}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t2.s3r2.responsible">{{data.translations.deliveryDates[data.lang]}}:&nbsp;
					<b v-if="data.t2.s3r2.delivery">{{data.t2.s3r2.delivery}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t2.s3r2.responsible">{{data.translations.amount[data.lang]}}:&nbsp;
					<b v-if="data.t2.s3r2.amount">{{data.t2.s3r2.amount}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t2.s3r2.responsible">{{data.translations.comments[data.lang]}}:&nbsp;
					<b v-if="data.t2.s3r2.comments"> {{data.t2.s3r2.comments}}</b>
				</ve-col>

				<ve-col md2 v-if="data.t2.s3r3.responsible">
					{{data.translations.tab2Sec3Op3[data.lang]}}
				</ve-col>
				<ve-col md2 v-if="data.t2.s3r3.responsible">{{data.translations.frequency[data.lang]}}:&nbsp;
					<b v-if="data.t2.s3r3.frequency">{{data.translations[data.t2.s3r3.frequency][data.lang]}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t2.s3r3.responsible">{{data.translations.responsible[data.lang]}}:&nbsp;
					<b v-if="data.t2.s3r3.responsible">{{data.translations[data.t2.s3r3.responsible][data.lang]}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t2.s3r3.responsible">{{data.translations.deliveryDates[data.lang]}}:&nbsp;
					<b v-if="data.t2.s3r3.delivery">{{data.t2.s3r3.delivery}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t2.s3r3.responsible">{{data.translations.amount[data.lang]}}:&nbsp;
					<b v-if="data.t2.s3r3.amount">{{data.t2.s3r3.amount}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t2.s3r3.responsible">{{data.translations.comments[data.lang]}}:&nbsp;
					<b v-if="data.t2.s3r3.comments"> {{data.t2.s3r3.comments}}</b>
				</ve-col>

				<ve-col md2 v-if="data.t2.s3r4.responsible">
					{{data.translations.tab2Sec3Op4[data.lang]}}
				</ve-col>
				<ve-col md2 v-if="data.t2.s3r4.responsible">{{data.translations.frequency[data.lang]}}:&nbsp;
					<b v-if="data.t2.s3r4.frequency">{{data.translations[data.t2.s3r4.frequency][data.lang]}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t2.s3r4.responsible">{{data.translations.responsible[data.lang]}}:&nbsp;
					<b v-if="data.t2.s3r4.responsible">{{data.translations[data.t2.s3r4.responsible][data.lang]}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t2.s3r4.responsible">{{data.translations.deliveryDates[data.lang]}}:&nbsp;
					<b v-if="data.t2.s3r4.delivery">{{data.t2.s3r4.delivery}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t2.s3r4.responsible">{{data.translations.amount[data.lang]}}:&nbsp;
					<b v-if="data.t2.s3r4.amount">{{data.t2.s3r4.amount}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t2.s3r4.responsible">{{data.translations.comments[data.lang]}}:&nbsp;
					<b v-if="data.t2.s3r4.comments"> {{data.t2.s3r4.comments}}</b>
				</ve-col>

				<ve-col md2 v-if="data.t2.s3r5.responsible">
					{{data.translations.tab2Sec3Op5[data.lang]}}
				</ve-col>
				<ve-col md2 v-if="data.t2.s3r5.responsible">{{data.translations.frequency[data.lang]}}:&nbsp;
					<b v-if="data.t2.s3r5.frequency">{{data.translations[data.t2.s3r5.frequency][data.lang]}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t2.s3r5.responsible">{{data.translations.responsible[data.lang]}}:&nbsp;
					<b v-if="data.t2.s3r5.responsible">{{data.translations[data.t2.s3r5.responsible][data.lang]}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t2.s3r5.responsible">{{data.translations.deliveryDates[data.lang]}}:&nbsp;
					<b v-if="data.t2.s3r5.delivery">{{data.t2.s3r5.delivery}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t2.s3r5.responsible">{{data.translations.amount[data.lang]}}:&nbsp;
					<b v-if="data.t2.s3r5.amount">{{data.t2.s3r5.amount}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t2.s3r5.responsible">{{data.translations.comments[data.lang]}}:&nbsp;
					<b v-if="data.t2.s3r5.comments"> {{data.t2.s3r5.comments}}</b>
				</ve-col>

				
			</div>
            <div class="flex-columns" v-for="t2Oth in data.t2.s3Other">
					<ve-col md2 v-if="t2Oth.moment&&t2Oth.responsible">{{t2Oth.moment}}</ve-col>
					<ve-col md2 v-if="t2Oth.moment&&t2Oth.responsible">{{data.translations.frequency[data.lang]}}:&nbsp;
						<b v-if="t2Oth.frequency">{{data.translations[t2Oth.frequency][data.lang]}}</b>
					</ve-col>
					<ve-col md2 v-if="t2Oth.moment&&t2Oth.responsible">{{data.translations.responsible[data.lang]}}:&nbsp;
						<b v-if="t2Oth.responsible">{{data.translations[t2Oth.responsible][data.lang]}}</b>
					</ve-col>
					<ve-col md2 v-if="t2Oth.moment&&t2Oth.responsible">{{data.translations.deliveryDates[data.lang]}}:&nbsp;
						<b v-if="t2Oth.delivery">{{t2Oth.delivery}}</b>
					</ve-col>
					<ve-col md2 v-if="t2Oth.moment&&t2Oth.responsible">{{data.translations.amount[data.lang]}}:&nbsp;
						<b v-if="t2Oth.amount">{{t2Oth.amount}}</b>
					</ve-col>
					<ve-col md2 v-if="t2Oth.moment&&t2Oth.responsible">{{data.translations.comments[data.lang]}}:&nbsp;
						<b v-if="t2Oth.comments"> {{t2Oth.comments}}</b>
					</ve-col>
				</div>
		</div>
		<!--<div class="no-page-break" v-if="data.missionType && data.missionType.salary && data.t2">
			<div class="flex-columns">
				<ve-col md4 v-if="data.t2.assignments">
					<span>{{data.translations.assignments[data.lang]}}:<b>{{data.t2.assignments}}</b></span>
				</ve-col>
				<ve-col md4 v-if="data.t2.schedule">
					<span>{{data.translations.schedule[data.lang]}}:<b>{{data.t2.schedule}}</b></span>
				</ve-col>
				<ve-col md4 v-if="data.t2.serviceProvider">
					<span>{{data.translations.serviceProvider[data.lang]}}:<b>{{data.t2.serviceProvider}}</b></span>
				</ve-col>
			</div>
		</div>
		<div class="no-page-break" v-if="data.missionType && data.missionType.salary && data.t2">
			<div class="flex-columns">
				<ve-col md12>
					<span>{{data.translations.filling[data.lang]}}:
						<b v-if="data.t2.filling.alternative1">{{data.translations.alternative1[data.lang]}}</b>
						<b v-if="data.t2.filling.alternative2">{{data.translations.alternative2[data.lang]}}</b>
					</span>
					<p v-if="data.t2.filling.alternative1">
						{{data.translations.alternative1Info[data.lang]}}
					</p>
				</ve-col>
				<ve-col md12>
					<span>{{data.translations.server[data.lang]}}:   </span>
					  <div class="custom-control custom-control-inline custom-checkbox mb-1">
                            <input type="checkbox" class="custom-control-input" :checked=(data.t2.server.own) disabled/>
                            <label class="custom-control-label custom-checkbox-label" for="">{{data.translations.own[data.lang]}}</label>&nbsp
                        </div>
                        <div class="custom-control custom-control-inline custom-checkbox mb-1">
                            <input type="checkbox" class="custom-control-input" :checked=(data.t2.server.provider) disabled/>
                            <label class="custom-control-label custom-checkbox-label" for="">{{data.translations.provider[data.lang]}}</label>
                        </div>
				</ve-col>
			</div>
		</div>
		<div class="no-page-break" v-if="data.missionType && data.missionType.salary && data.t2">
			<div class="flex-columns">
				<ve-col md12>
					<h3>{{data.translations.fee[data.lang]}}</h3>
					<span>
						<b v-if="data.t2.priceType.priceOp1">{{data.translations.priceOp1[data.lang]}}</b>
						<b v-if="data.t2.priceType.priceOp2">{{data.translations.priceOp2[data.lang]}}</b>
						<b v-if="data.t2.priceType.priceOp3">{{data.translations.priceOp3[data.lang]}}</b>
					</span>
					<p v-if="data.t2.priceType.priceOp3">
						{{data.t2.priceOp3}}
					</p>
				</ve-col>
				<ve-col md6 v-if="data.t2.priceType.priceOp1&&data.t2.sek">
					<span>{{data.translations.sek[data.lang]}}: <b>{{data.t2.sek}}</b></span>
				</ve-col>
				<ve-col md6 v-if="data.t2.priceType.priceOp1&&data.t2.priceFrequency">
					<span>{{data.translations.frequency[data.lang]}}: <b>{{data.translations[data.t2.priceFrequency][data.lang]}}</b></span>
				</ve-col>
			</div>
		</div>
		<div class="no-page-break" v-if="data.missionType && data.missionType.salary && data.t2">
			<div class="flex-columns">
				<ve-col md6 v-if="data.t2.priceType.priceOp2&&data.t2.sekforSalary">
					<span>{{data.translations.sekforSalary[data.lang]}}: <b>{{data.t2.sekforSalary}}</b></span>
				</ve-col>
				<ve-col md6 v-if="data.t2.priceType.priceOp2&&data.t2.sekArray.length>0">
					<span>{{data.translations.sekforOther[data.lang]}}:</span><br>
					<div v-for="sek in data.t2.sekArray">
					 	<span ><b>{{sek.type}} : {{sek.value}}</b></span><br>
					</div>
				</ve-col>
				<ve-col md6 v-if="data.t2.priceType.priceOp2&&data.t2.assignmentTime">
					<span>{{data.translations.assignmentTime[data.lang]}}: <b>{{data.t2.assignmentTime}}</b></span>
				</ve-col>
				<ve-col md6 v-if="data.t2.priceType.priceOp2&&data.t2.timeFrequency">
					<span>{{data.translations.frequency[data.lang]}}: <b>{{data.translations[data.t2.timeFrequency][data.lang]}}</b></span>
				</ve-col>
				<ve-col md6 v-if="(data.t2.priceType.priceOp2||data.t2.priceType.priceOp1)&&data.t2.fee.index">
					<span>{{data.translations.feeIndex[data.lang]}}: <b>{{data.t2.fee.index}}</b></span>
				</ve-col>
				<ve-col md6 v-if="data.t2.priceType.priceOp2&&data.t2.fee.startDate">
					<span>{{data.translations.feeStartDate[data.lang]}}: <b>{{data.t2.fee.startDate}}</b></span>
				</ve-col>
			</div>
		</div>
		<div class="no-page-break" v-if="data.missionType && data.missionType.salary && data.t2">
			<div class="flex-columns">
				<ve-col md12 v-if="data.t2.assignmentPeriod">
					<span>{{data.translations.assignmentPeriod[data.lang]}}: <b>{{data.t2.assignmentPeriod}}</b></span>
				</ve-col>
				<ve-col md12 v-if="data.t2.noticePeriod">
					<span>{{data.translations.noticePeriod[data.lang]}}: <b>{{data.t2.noticePeriod}}</b></span>
				</ve-col>
				<ve-col md12 v-if="data.t2.treatment.t2TreatmetOp1||data.t2.treatment.t2TreatmetOp2||data.t2.treatment.t2TreatmetOp3||data.t2.treatment.t2TreatmetOp4||data.t2.treatment.t2TreatmetOp5||data.t2.treatment.t2TreatmetOp6||data.t2.treatment.t2TreatmetOp7||data.t2.treatment.t2TreatmetOp8">
					<span><b>{{data.translations.treatment[data.lang]}}: </b><br>
						<span v-if="data.t2.treatment.t2TreatmetOp1">- {{data.translations.t2TreatmetOp1[data.lang]}}<br></span>
						<span v-if="data.t2.treatment.t2TreatmetOp2">- {{data.translations.t2TreatmetOp2[data.lang]}}<br></span>
						<span v-if="data.t2.treatment.t2TreatmetOp3">- {{data.translations.t2TreatmetOp3[data.lang]}}<br></span>
						<span v-if="data.t2.treatment.t2TreatmetOp4">- {{data.translations.t2TreatmetOp4[data.lang]}}<br></span>
						<span v-if="data.t2.treatment.t2TreatmetOp5">- {{data.translations.t2TreatmetOp5[data.lang]}}<br></span>
						<span v-if="data.t2.treatment.t2TreatmetOp6">- {{data.translations.t2TreatmetOp6[data.lang]}}<br></span>
						<span v-if="data.t2.treatment.t2TreatmetOp7">- {{data.translations.t2TreatmetOp7[data.lang]}}<br></span>
						<span v-if="data.t2.treatment.t2TreatmetOp8">- {{data.translations.t2TreatmetOp8[data.lang]}}<br></span>
					</span><br>
					<p v-if="data.t2.otherTreatment">{{data.t2.otherTreatment}}</p>
				</ve-col>
			</div>
		</div>
		<div class="no-page-break" v-if="data.missionType && data.missionType.salary && data.t2">
			<div class="flex-columns">
				<ve-col md12 v-if="data.t2.purpose">
					<b>{{data.translations.purposeHead[data.lang]}}: </b>
					<p>{{data.t2.purpose}}</p>
				</ve-col>
				<ve-col md12 v-if="data.t2.categories.t2CategoriesOp1||data.t2.categories.t2CategoriesOp2||data.t2.categories.t2CategoriesOp3||data.t2.categories.t2CategoriesOp4||data.t2.categories.t2CategoriesOp5||data.t2.categories.t2CategoriesOp6||data.t2.categories.t2CategoriesOp7">
					<span><b>{{data.translations.categories[data.lang]}}:</b> <br>
						<span v-if="data.t2.categories.t2CategoriesOp1">- {{data.translations.t2CategoriesOp1[data.lang]}}<br></span>
						<span v-if="data.t2.categories.t2CategoriesOp2">- {{data.translations.t2CategoriesOp2[data.lang]}}<br></span>
						<span v-if="data.t2.categories.t2CategoriesOp3">- {{data.translations.t2CategoriesOp3[data.lang]}}<br></span>
						<span v-if="data.t2.categories.t2CategoriesOp4">- {{data.translations.t2CategoriesOp4[data.lang]}}<br></span>
						<span v-if="data.t2.categories.t2CategoriesOp5">- {{data.translations.t2CategoriesOp5[data.lang]}}<br></span>
						<span v-if="data.t2.categories.t2CategoriesOp6">- {{data.translations.t2CategoriesOp6[data.lang]}}<br></span>
						<span v-if="data.t2.categories.t2CategoriesOp7">- {{data.translations.t2CategoriesOp7[data.lang]}}<br></span>
					</span><br>
					<p v-if="data.t2.otherCategories">{{data.t2.otherCategories}}</p>
				</ve-col>
			</div>
		</div>
		<div class="no-page-break" v-if="data.missionType && data.missionType.salary && data.t2">
			<div class="flex-columns">
				<ve-col md12 v-if="data.t2.registrants">
					<b>{{data.translations.registrantsHead[data.lang]}}: </b>
					<p>{{data.t2.registrants}}</p>
				</ve-col>
				<ve-col md12 v-if="data.t2.recPersonel">
					<b>{{data.translations.recPersonel[data.lang]}}: </b>
					<p>{{data.t2.recPersonel}}</p>
				</ve-col>
				<ve-col md12 v-if="data.t2.technical">
					<b>{{data.translations.technical[data.lang]}}: </b>
					<p>{{data.t2.technical}}</p>
				</ve-col>
			</div>
		</div>-->
		<br>
    </div>
</template>

<script>

export default {
    name: "ve-preview-salary",
    props: {
        data : {
            type: Object,
            default: () => { return {} }
        },
    },
    data() {
        return {
            validation_error: false
        }
    },
    methods: {
    },
    created() {
    }
}
</script>

<style lang="scss" scoped>

.flex-columns{display:-webkit-box;display:-moz-box;display:-ms-flexbox;display:-webkit-flex;display:flex;-webkit-box-direction:normal;-moz-box-direction:normal;-webkit-box-orient:horizontal;-moz-box-orient:horizontal;-webkit-flex-direction:row;-ms-flex-direction:row;flex-direction:row;-webkit-box-pack:stretch;-moz-box-pack:stretch;-webkit-justify-content:stretch;-ms-flex-pack:stretch;justify-content:stretch;-webkit-flex-wrap:wrap;-moz-flex-wrap:wrap;-ms-flex-wrap:wrap;flex-wrap:wrap;-webkit-flex-wrap:wrap;-moz-flex-wrap:wrap;-ms-flex-wrap:wrap;flex-wrap:wrap}.flex-columns .col{flex-grow:1;-webkit-flex-grow:1;-moz-flex-grow:1;-ms-flex-grow:1;background-color:#f3f3f3;padding:8px;border:1px solid #fff;box-sizing:border-box}.flex-columns .col-1{width:100%}.flex-columns .col-2{width:50%}.flex-columns .col-3{width:33.3%}.flex-columns .col-4{width:25%}.flex-columns .col-5{width:20%}.flex-columns .col-6{width:16.6%}.flex-columns .col-7{width:14.28%}.flex-columns .col-8{width:12.5%}.flex-columns .col-9{width:11.1%}.flex-columns .col-10{width:10%}.flex-columns.transparent .col{background-color:transparent;border-color:transparent}table{border-collapse:collapse}table,table td{border:1px solid #eee;background:transparent}table td{padding:8px 12px}table.stripped,table.stripped td{border:0}table.stripped td{border-bottom:1px solid #eee}.flex-columns{margin-left:1px}.flex-columns .col{border:1px solid #eee;background:transparent;padding:8px 12px;margin:-1px 0 0 -1px}.flex-columns.stripped .col{border-left:0;border-right:0}.flex-columns.transparent .col{border:0}
b{font-weight:bold!important;}
.myModal-content, .myModal-content label{
    font-size:12px!important;
}
</style>
