<template>
    <div v-if="data.t1||data.t2||data.t3">
        <h3>{{data.translations.pdfInfo50[data.lang]}}</h3>
        <p v-html="data.translations.pdfInfo51[data.lang]">
            <!-- {{data.translations.pdfInfo51[data.lang]}}  -->
        </p>
        <h3>{{data.translations.treatment[data.lang]}}</h3>
        <p>{{data.translations.pdfInfo52[data.lang]}} </p>
        <p v-if="data.missionType.financial&&data.t1.treatment">
            <span v-if="data.t1.treatment.t3TreatmetOp1">  - {{data.translations.t3TreatmetOp1[data.lang]}} <br></span>
            <span v-if="data.t1.treatment.t3TreatmetOp2">  - {{data.translations.t3TreatmetOp2[data.lang]}} <br></span>
            <span v-if="data.t1.treatment.t3TreatmetOp3">  - {{data.translations.t3TreatmetOp3[data.lang]}} <br></span>
            <span v-if="data.t1.treatment.treatmentOp1">  - {{data.translations.treatmentOp1[data.lang]}} <br></span>
            <span v-if="data.t1.treatment.treatmentOp2">  - {{data.translations.treatmentOp2[data.lang]}} <br></span>
            <span v-if="data.t1.treatment.treatmentOp3">  - {{data.translations.treatmentOp3[data.lang]}} <br></span>
            <span v-if="data.t1.treatment.treatmentOp4">  - {{data.translations.treatmentOp4[data.lang]}} <br></span>
            <span v-if="data.t1.treatment.treatmentOp5">  - {{data.translations.treatmentOp5[data.lang]}} <br></span>
            {{data.t1.otherTreatment}}
        </p>
        <p v-if="data.missionType.salary&&data.t2.treatment">
            <span v-if="data.t2.treatment.t2TreatmetOp1">  - {{data.translations.t2TreatmetOp1[data.lang]}} <br></span>
            <span v-if="data.t2.treatment.t2TreatmetOp2">  - {{data.translations.t2TreatmetOp2[data.lang]}} <br></span>
            <span v-if="data.t2.treatment.t2TreatmetOp3">  - {{data.translations.t2TreatmetOp3[data.lang]}} <br></span>
            <span v-if="data.t2.treatment.t2TreatmetOp4">  - {{data.translations.t2TreatmetOp4[data.lang]}} <br></span>
            <span v-if="data.t2.treatment.t2TreatmetOp5">  - {{data.translations.t2TreatmetOp5[data.lang]}} <br></span>
            <span v-if="data.t2.treatment.t2TreatmetOp6">  - {{data.translations.t2TreatmetOp6[data.lang]}} <br></span>
            <span v-if="data.t2.treatment.t2TreatmetOp7">  - {{data.translations.t2TreatmetOp7[data.lang]}} <br></span>
            <span v-if="data.t2.treatment.t2TreatmetOp8">  - {{data.translations.t2TreatmetOp8[data.lang]}} <br></span>
            {{data.t2.otherTreatment}}
        </p>
        <p v-if="data.missionType.accounting&&data.t3.treatment">
            <span v-if="data.t3.treatment.t3TreatmetOp1">  - {{data.translations.t3TreatmetOp1[data.lang]}} <br></span>
            <span v-if="data.t3.treatment.t3TreatmetOp2">  - {{data.translations.t3TreatmetOp2[data.lang]}} <br></span>
            <span v-if="data.t3.treatment.t3TreatmetOp3">  - {{data.translations.t3TreatmetOp3[data.lang]}} <br></span>
            <span v-if="data.t3.treatment.t3TreatmetOp4">  - {{data.translations.t3TreatmetOp4[data.lang]}} <br></span>
            {{data.t3.otherTreatment}}
        </p>
        <h3>{{data.translations.purposeHead[data.lang]}}</h3>
        <p>
            {{data.translations.pdfInfo53[data.lang]}} 
            <span v-if="data.missionType.financial">{{data.t1.purpose}}</span>
            <span v-else-if="data.missionType.salary">{{data.t2.purpose}} </span>
            <span v-else-if="data.missionType.accounting">{{data.t3.purpose}} </span>
        </p>
        <h3>{{data.translations.categories[data.lang]}}</h3>
        <p>{{data.translations.pdfInfo54[data.lang]}}</p>
        <p v-if="data.missionType.financial&&data.t1.categories">
            <span v-if="data.t1.categories.t3CategoriesOp2">  - {{data.translations.t3CategoriesOp2[data.lang]}} <br></span>
            <span v-if="data.t1.categories.t3CategoriesOp3">  - {{data.translations.t3CategoriesOp3[data.lang]}} <br></span>
            <span v-if="data.t1.categories.t3CategoriesOp4">  - {{data.translations.t3CategoriesOp4[data.lang]}} <br></span>
            <span v-if="data.t1.categories.t3CategoriesOp7">  - {{data.translations.t3CategoriesOp7[data.lang]}} <br></span>
            <span v-if="data.t1.categories.t3CategoriesOp8">  - {{data.translations.t3CategoriesOp8[data.lang]}} <br></span>
            <span v-if="data.t1.categories.t3CategoriesOp9">  - {{data.translations.t3CategoriesOp9[data.lang]}} <br></span>
            <span v-if="data.t1.categories.categoriesOp1">  - {{data.translations.categoriesOp1[data.lang]}} <br></span>
            <span v-if="data.t1.categories.categoriesOp2">  - {{data.translations.categoriesOp2[data.lang]}} <br></span>
            <span v-if="data.t1.categories.categoriesOp3">  - {{data.translations.categoriesOp3[data.lang]}} <br></span>
            <span v-if="data.t1.categories.categoriesOp4">  - {{data.translations.categoriesOp4[data.lang]}} <br></span>
            <span v-if="data.t1.categories.categoriesOp5">  - {{data.translations.categoriesOp5[data.lang]}} <br></span>
            <span v-if="data.t1.categories.categoriesOp6">  - {{data.translations.categoriesOp6[data.lang]}} <br></span>
            <span v-if="data.t1.categories.categoriesOp7">  - {{data.translations.categoriesOp7[data.lang]}} <br></span>
            <span v-if="data.t1.categories.categoriesOp8">  - {{data.translations.categoriesOp8[data.lang]}} <br></span>
            <span v-if="data.t1.categories.categoriesOp9">  - {{data.translations.categoriesOp9[data.lang]}} <br></span>
            <span v-if="data.t1.categories.categoriesOp10">  - {{data.translations.categoriesOp10[data.lang]}} <br></span>
            <span v-if="data.t1.categories.categoriesOp11">  - {{data.translations.categoriesOp11[data.lang]}} <br></span>
            {{data.t1.otherCategories}}
        </p>
        <p v-if="data.missionType.salary&&data.t2.categories">
            <span v-if="data.t2.categories.t2CategoriesOp1">  - {{data.translations.t2CategoriesOp1[data.lang]}} <br></span>
            <span v-if="data.t2.categories.t2CategoriesOp2">  - {{data.translations.t2CategoriesOp2[data.lang]}} <br></span>
            <span v-if="data.t2.categories.t2CategoriesOp3">  - {{data.translations.t2CategoriesOp3[data.lang]}} <br></span>
            <span v-if="data.t2.categories.t2CategoriesOp4">  - {{data.translations.t2CategoriesOp4[data.lang]}} <br></span>
            <span v-if="data.t2.categories.t2CategoriesOp5">  - {{data.translations.t2CategoriesOp5[data.lang]}} <br></span>
            <span v-if="data.t2.categories.t2CategoriesOp6">  - {{data.translations.t2CategoriesOp6[data.lang]}} <br></span>
            <span v-if="data.t2.categories.t2CategoriesOp7">  - {{data.translations.t2CategoriesOp7[data.lang]}} <br></span>
            {{data.t2.otherCategories}}
        </p>
        <p v-if="data.missionType.accounting&&data.t3.categories">
            <span v-if="data.t3.categories.t3CategoriesOp1">  - {{data.translations.t3CategoriesOp1[data.lang]}} <br></span>
            <span v-if="data.t3.categories.t3CategoriesOp2">  - {{data.translations.t3CategoriesOp2[data.lang]}} <br></span>
            <span v-if="data.t3.categories.t3CategoriesOp3">  - {{data.translations.t3CategoriesOp3[data.lang]}} <br></span>
            <span v-if="data.t3.categories.t3CategoriesOp4">  - {{data.translations.t3CategoriesOp4[data.lang]}} <br></span>
            <span v-if="data.t3.categories.t3CategoriesOp5">  - {{data.translations.t3CategoriesOp5[data.lang]}} <br></span>
            <span v-if="data.t3.categories.t3CategoriesOp6">  - {{data.translations.t3CategoriesOp6[data.lang]}} <br></span>
            <span v-if="data.t3.categories.t3CategoriesOp7">  - {{data.translations.t3CategoriesOp7[data.lang]}} <br></span>
            <span v-if="data.t3.categories.t3CategoriesOp8">  - {{data.translations.t3CategoriesOp8[data.lang]}} <br></span>
            <span v-if="data.t3.categories.t3CategoriesOp9">  - {{data.translations.t3CategoriesOp9[data.lang]}} <br></span>
            {{data.t3.otherCategories}}
        </p>
        <h3>{{data.translations.registrantsHead[data.lang]}}</h3>
        <p>
            {{data.translations.pdfInfo55[data.lang]}} 
            <span v-if="data.missionType.financial">{{data.t1.registrants}}</span>
            <span v-else-if="data.missionType.salary">{{data.t2.registrants}} </span>
            <span v-else-if="data.missionType.accounting">{{data.t3.registrants}} </span>
        </p>
        <h3>{{data.translations.recPersonel[data.lang]}}</h3>
        <p>
            {{data.translations.pdfInfo56[data.lang]}} 
            <span v-if="data.missionType.financial">{{data.t1.recPersonel}}</span>
            <span v-else-if="data.missionType.salary">{{data.t2.recPersonel}} </span>
            <span v-else-if="data.missionType.accounting">{{data.t3.recPersonel}} </span>
        </p>
        <h3>{{data.translations.technical[data.lang]}}</h3>
        <p >
            {{data.translations.pdfInfo57[data.lang]}} 
            <span v-if="data.missionType.financial">{{data.t1.technical}}</span>
            <span v-else-if="data.missionType.salary">{{data.t2.technical}} </span>
            <span v-else-if="data.missionType.accounting">{{data.t3.technical}} </span>
        </p>
    </div>
</template>

<script>

export default {
    name: "ve-preview-bilaga",
    props: {
        data : {
            type: Object,
            default: () => { return {} }
        },
    },
    data() {
        return {
            validation_error: false
        }
    },
    methods: {
    },
    created() {
    }
}
</script>

