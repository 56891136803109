<template>
    <div>
         <!--<h3 v-if="data.missionType && data.missionType.accounting">{{data.translations.tabAccounting[data.lang]}}</h3>-->
		 <h3 v-if="data.missionType && data.missionType.accounting&& data.t3">
			{{data.translations.bilaga[data.lang]}} 
			<span v-if="data.missionType.financial&&data.missionType.salary">5</span>
			<span v-else-if="!data.missionType.financial&&!data.missionType.salary">3</span>
			<span v-else>4</span>: {{data.translations.missionAccounting[data.lang]}}
		</h3>
		<div class="no-page-break" v-if="data.missionType && data.missionType.accounting && data.t3">
			<div class="flex-columns">
				<ve-col md12 v-if="data.t3.s1r1.responsible||data.t3.s1r2.responsible||data.t3.s1r3.responsible||data.t3.s1r4.responsible||data.t3.s1r5.responsible||data.t3.s1Other[0].moment">
					<b>{{data.translations.tab3Sec1Head[data.lang]}}</b>
				</ve-col>

				<ve-col md2 v-if="data.t3.s1r1.responsible">
					{{data.translations.tab3Sec1Op1[data.lang]}}
				</ve-col>
				<ve-col md2 v-if="data.t3.s1r1.responsible">{{data.translations.frequency[data.lang]}};&nbsp;
					<b v-if="data.t3.s1r1.frequency">{{data.translations[data.t3.s1r1.frequency][data.lang]}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t3.s1r1.responsible">{{data.translations.responsible[data.lang]}};&nbsp;
					<b v-if="data.t3.s1r1.responsible">{{data.translations[data.t3.s1r1.responsible][data.lang]}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t3.s1r1.responsible">{{data.translations.deliveryDates[data.lang]}};&nbsp;
					<b v-if="data.t3.s1r1.delivery">{{data.t3.s1r1.delivery}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t3.s1r1.responsible">{{data.translations.amount[data.lang]}};&nbsp;
					<b v-if="data.t3.s1r1.amount">{{data.t3.s1r1.amount}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t3.s1r1.responsible">{{data.translations.comments[data.lang]}};&nbsp;
					<b v-if="data.t3.s1r1.comments"> {{data.t3.s1r1.comments}}</b>
				</ve-col>

				<ve-col md2 v-if="data.t3.s1r2.responsible">
					{{data.translations.tab3Sec1Op2[data.lang]}}
				</ve-col>
				<ve-col md2 v-if="data.t3.s1r2.responsible">{{data.translations.frequency[data.lang]}};&nbsp;
					<b v-if="data.t3.s1r2.frequency">{{data.translations[data.t3.s1r2.frequency][data.lang]}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t3.s1r2.responsible">{{data.translations.responsible[data.lang]}};&nbsp;
					<b v-if="data.t3.s1r2.responsible">{{data.translations[data.t3.s1r2.responsible][data.lang]}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t3.s1r2.responsible">{{data.translations.deliveryDates[data.lang]}};&nbsp;
					<b v-if="data.t3.s1r2.delivery">{{data.t3.s1r2.delivery}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t3.s1r2.responsible">{{data.translations.amount[data.lang]}};&nbsp;
					<b v-if="data.t3.s1r2.amount">{{data.t3.s1r2.amount}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t3.s1r2.responsible">{{data.translations.comments[data.lang]}};&nbsp;
					<b v-if="data.t3.s1r2.comments"> {{data.t3.s1r2.comments}}</b>
				</ve-col>

				<ve-col md2 v-if="data.t3.s1r3.responsible">
					{{data.translations.tab3Sec1Op3[data.lang]}}
				</ve-col>
				<ve-col md2 v-if="data.t3.s1r3.responsible">{{data.translations.frequency[data.lang]}};&nbsp;
					<b v-if="data.t3.s1r3.frequency">{{data.translations[data.t3.s1r3.frequency][data.lang]}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t3.s1r3.responsible">{{data.translations.responsible[data.lang]}};&nbsp;
					<b v-if="data.t3.s1r3.responsible">{{data.translations[data.t3.s1r3.responsible][data.lang]}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t3.s1r3.responsible">{{data.translations.deliveryDates[data.lang]}};&nbsp;
					<b v-if="data.t3.s1r3.delivery">{{data.t3.s1r3.delivery}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t3.s1r3.responsible">{{data.translations.amount[data.lang]}};&nbsp;
					<b v-if="data.t3.s1r3.amount">{{data.t3.s1r3.amount}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t3.s1r3.responsible">{{data.translations.comments[data.lang]}};&nbsp;
					<b v-if="data.t3.s1r3.comments"> {{data.t3.s1r3.comments}}</b>
				</ve-col>

				<ve-col md2 v-if="data.t3.s1r4.responsible">
					{{data.translations.tab3Sec1Op4[data.lang]}}
				</ve-col>
				<ve-col md2 v-if="data.t3.s1r4.responsible">{{data.translations.frequency[data.lang]}};&nbsp;
					<b v-if="data.t3.s1r4.frequency">{{data.translations[data.t3.s1r4.frequency][data.lang]}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t3.s1r4.responsible">{{data.translations.responsible[data.lang]}};&nbsp;
					<b v-if="data.t3.s1r4.responsible">{{data.translations[data.t3.s1r4.responsible][data.lang]}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t3.s1r4.responsible">{{data.translations.deliveryDates[data.lang]}};&nbsp;
					<b v-if="data.t3.s1r4.delivery">{{data.t3.s1r4.delivery}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t3.s1r4.responsible">{{data.translations.amount[data.lang]}};&nbsp;
					<b v-if="data.t3.s1r4.amount">{{data.t3.s1r4.amount}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t3.s1r4.responsible">{{data.translations.comments[data.lang]}};&nbsp;
					<b v-if="data.t3.s1r4.comments"> {{data.t3.s1r4.comments}}</b>
				</ve-col>

				<ve-col md2 v-if="data.t3.s1r5.responsible">
					{{data.translations.tab3Sec1Op5[data.lang]}}
				</ve-col>
				<ve-col md2 v-if="data.t3.s1r5.responsible">{{data.translations.frequency[data.lang]}};&nbsp;
					<b v-if="data.t3.s1r5.frequency">{{data.translations[data.t3.s1r5.frequency][data.lang]}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t3.s1r5.responsible">{{data.translations.responsible[data.lang]}};&nbsp;
					<b v-if="data.t3.s1r5.responsible">{{data.translations[data.t3.s1r5.responsible][data.lang]}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t3.s1r5.responsible">{{data.translations.deliveryDates[data.lang]}};&nbsp;
					<b v-if="data.t3.s1r5.delivery">{{data.t3.s1r5.delivery}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t3.s1r5.responsible">{{data.translations.amount[data.lang]}};&nbsp;
					<b v-if="data.t3.s1r5.amount">{{data.t3.s1r5.amount}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t3.s1r5.responsible">{{data.translations.comments[data.lang]}};&nbsp;
					<b v-if="data.t3.s1r5.comments"> {{data.t3.s1r5.comments}}</b>
				</ve-col>
			</div>
            <div class="flex-columns" v-for="t3Oth in data.t3.s1Other">
                <ve-col md2 v-if="t3Oth.moment&&t3Oth.responsible">{{t3Oth.moment}}</ve-col>
                <ve-col md2 v-if="t3Oth.moment&&t3Oth.responsible">{{data.translations.frequency[data.lang]}};&nbsp;
                    <b v-if="t3Oth.frequency">{{data.translations[t3Oth.frequency][data.lang]}}</b>
                </ve-col>
                <ve-col md2 v-if="t3Oth.moment&&t3Oth.responsible">{{data.translations.responsible[data.lang]}};&nbsp;
                    <b v-if="t3Oth.responsible">{{data.translations[t3Oth.responsible][data.lang]}}</b>
                </ve-col>
                <ve-col md2 v-if="t3Oth.moment&&t3Oth.responsible">{{data.translations.deliveryDates[data.lang]}};&nbsp;
                    <b v-if="t3Oth.delivery">{{t3Oth.delivery}}</b>
                </ve-col>
                <ve-col md2 v-if="t3Oth.moment&&t3Oth.responsible">{{data.translations.amount[data.lang]}};&nbsp;
                    <b v-if="t3Oth.amount">{{t3Oth.amount}}</b>
                </ve-col>
                <ve-col md2 v-if="t3Oth.moment&&t3Oth.responsible">{{data.translations.comments[data.lang]}};&nbsp;
                    <b v-if="t3Oth.comments"> {{t3Oth.comments}}</b>
                </ve-col>
            </div>
		</div>
		<div class="no-page-break" v-if="data.missionType && data.missionType.accounting && data.t3">
			<div class="flex-columns">
				<ve-col md12 v-if="data.t3.s2r1.responsible||data.t3.s2r2.responsible||data.t3.s2r3.responsible||data.t3.s2Other[0].moment">
					<b>{{data.translations.tab3Sec2Head[data.lang]}}</b>
				</ve-col>

				<ve-col md2 v-if="data.t3.s2r1.responsible">
					{{data.translations.tab3Sec2Op1[data.lang]}}
				</ve-col>
				<ve-col md2 v-if="data.t3.s2r1.responsible">{{data.translations.frequency[data.lang]}};&nbsp;
					<b v-if="data.t3.s2r1.frequency">{{data.translations[data.t3.s2r1.frequency][data.lang]}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t3.s2r1.responsible">{{data.translations.responsible[data.lang]}};&nbsp;
					<b v-if="data.t3.s2r1.responsible">{{data.translations[data.t3.s2r1.responsible][data.lang]}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t3.s2r1.responsible">{{data.translations.deliveryDates[data.lang]}};&nbsp;
					<b v-if="data.t3.s2r1.delivery">{{data.t3.s2r1.delivery}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t3.s2r1.responsible">{{data.translations.amount[data.lang]}};&nbsp;
					<b v-if="data.t3.s2r1.amount">{{data.t3.s2r1.amount}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t3.s2r1.responsible">{{data.translations.comments[data.lang]}};&nbsp;
					<b v-if="data.t3.s2r1.comments"> {{data.t3.s2r1.comments}}</b>
				</ve-col>

				<ve-col md2 v-if="data.t3.s2r2.responsible">
					{{data.translations.tab3Sec2Op2[data.lang]}}
				</ve-col>
				<ve-col md2 v-if="data.t3.s2r2.responsible">{{data.translations.frequency[data.lang]}};&nbsp;
					<b v-if="data.t3.s2r2.frequency">{{data.translations[data.t3.s2r2.frequency][data.lang]}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t3.s2r2.responsible">{{data.translations.responsible[data.lang]}};&nbsp;
					<b v-if="data.t3.s2r2.responsible">{{data.translations[data.t3.s2r2.responsible][data.lang]}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t3.s2r2.responsible">{{data.translations.deliveryDates[data.lang]}};&nbsp;
					<b v-if="data.t3.s2r2.delivery">{{data.t3.s2r2.delivery}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t3.s2r2.responsible">{{data.translations.amount[data.lang]}};&nbsp;
					<b v-if="data.t3.s2r2.amount">{{data.t3.s2r2.amount}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t3.s2r2.responsible">{{data.translations.comments[data.lang]}};&nbsp;
					<b v-if="data.t3.s2r2.comments"> {{data.t3.s2r2.comments}}</b>
				</ve-col>

				<ve-col md2 v-if="data.t3.s2r3.responsible">
					{{data.translations.tab3Sec2Op3[data.lang]}}
				</ve-col>
				<ve-col md2 v-if="data.t3.s2r3.responsible">{{data.translations.frequency[data.lang]}};&nbsp;
					<b v-if="data.t3.s2r3.frequency">{{data.translations[data.t3.s2r3.frequency][data.lang]}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t3.s2r3.responsible">{{data.translations.responsible[data.lang]}};&nbsp;
					<b v-if="data.t3.s2r3.responsible">{{data.translations[data.t3.s2r3.responsible][data.lang]}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t3.s2r3.responsible">{{data.translations.deliveryDates[data.lang]}};&nbsp;
					<b v-if="data.t3.s2r3.delivery">{{data.t3.s2r3.delivery}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t3.s2r3.responsible">{{data.translations.amount[data.lang]}};&nbsp;
					<b v-if="data.t3.s2r3.amount">{{data.t3.s2r3.amount}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t3.s2r3.responsible">{{data.translations.comments[data.lang]}};&nbsp;
					<b v-if="data.t3.s2r3.comments"> {{data.t3.s2r3.comments}}</b>
				</ve-col>
			</div>
            <div class="flex-columns" v-for="t3Oths2 in data.t3.s2Other">
                <ve-col md2 v-if="t3Oths2.moment&&t3Oths2.responsible">{{t3Oths2.moment}}</ve-col>
                <ve-col md2 v-if="t3Oths2.moment&&t3Oths2.responsible">{{data.translations.frequency[data.lang]}};&nbsp;
                    <b v-if="t3Oths2.frequency">{{data.translations[t3Oths2.frequency][data.lang]}}</b>
                </ve-col>
                <ve-col md2 v-if="t3Oths2.moment&&t3Oths2.responsible">{{data.translations.responsible[data.lang]}};&nbsp;
                    <b v-if="t3Oths2.responsible">{{data.translations[t3Oths2.responsible][data.lang]}}</b>
                </ve-col>
                <ve-col md2 v-if="t3Oths2.moment&&t3Oths2.responsible">{{data.translations.deliveryDates[data.lang]}};&nbsp;
                    <b v-if="t3Oths2.delivery">{{t3Oths2.delivery}}</b>
                </ve-col>
                <ve-col md2 v-if="t3Oths2.moment&&t3Oths2.responsible">{{data.translations.amount[data.lang]}};&nbsp;
                    <b v-if="t3Oths2.amount">{{t3Oths2.amount}}</b>
                </ve-col>
                <ve-col md2 v-if="t3Oths2.moment&&t3Oths2.responsible">{{data.translations.comments[data.lang]}};&nbsp;
                    <b v-if="t3Oths2.comments"> {{t3Oths2.comments}}</b>
                </ve-col>
            </div>
		</div>
		<div class="no-page-break" v-if="data.missionType && data.missionType.accounting && data.t3">
			<div class="flex-columns">
				<ve-col md12 v-if="data.t3.s3r1.responsible||data.t3.s3r2.responsible||data.t3.s3r3.responsible||data.t3.s3r4.responsible||data.t3.s3r5.responsible||data.t3.s3r6.responsible||data.t3.s3r7.responsible||data.t3.s3Other[0].moment">
					<b>{{data.translations.tab3Sec3Head[data.lang]}}</b>
				</ve-col>

				<ve-col md2 v-if="data.t3.s3r1.responsible">
					{{data.translations.tab3Sec3Op1[data.lang]}}
				</ve-col>
				<ve-col md2 v-if="data.t3.s3r1.responsible">{{data.translations.frequency[data.lang]}};&nbsp;
					<b v-if="data.t3.s3r1.frequency">{{data.translations[data.t3.s3r1.frequency][data.lang]}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t3.s3r1.responsible">{{data.translations.responsible[data.lang]}};&nbsp;
					<b v-if="data.t3.s3r1.responsible">{{data.translations[data.t3.s3r1.responsible][data.lang]}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t3.s3r1.responsible">{{data.translations.deliveryDates[data.lang]}};&nbsp;
					<b v-if="data.t3.s3r1.delivery">{{data.t3.s3r1.delivery}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t3.s3r1.responsible">{{data.translations.amount[data.lang]}};&nbsp;
					<b v-if="data.t3.s3r1.amount">{{data.t3.s3r1.amount}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t3.s3r1.responsible">{{data.translations.comments[data.lang]}};&nbsp;
					<b v-if="data.t3.s3r1.comments"> {{data.t3.s3r1.comments}}</b>
				</ve-col>

				<ve-col md2 v-if="data.t3.s3r2.responsible">
					{{data.translations.tab3Sec3Op2[data.lang]}}
				</ve-col>
				<ve-col md2 v-if="data.t3.s3r2.responsible">{{data.translations.frequency[data.lang]}};&nbsp;
					<b v-if="data.t3.s3r2.frequency">{{data.translations[data.t3.s3r2.frequency][data.lang]}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t3.s3r2.responsible">{{data.translations.responsible[data.lang]}};&nbsp;
					<b v-if="data.t3.s3r2.responsible">{{data.translations[data.t3.s3r2.responsible][data.lang]}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t3.s3r2.responsible">{{data.translations.deliveryDates[data.lang]}};&nbsp;
					<b v-if="data.t3.s3r2.delivery">{{data.t3.s3r2.delivery}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t3.s3r2.responsible">{{data.translations.amount[data.lang]}};&nbsp;
					<b v-if="data.t3.s3r2.amount">{{data.t3.s3r2.amount}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t3.s3r2.responsible">{{data.translations.comments[data.lang]}};&nbsp;
					<b v-if="data.t3.s3r2.comments"> {{data.t3.s3r2.comments}}</b>
				</ve-col>

				<ve-col md2 v-if="data.t3.s3r3.responsible">
					{{data.translations.tab3Sec3Op3[data.lang]}}
				</ve-col>
				<ve-col md2 v-if="data.t3.s3r3.responsible">{{data.translations.frequency[data.lang]}};&nbsp;
					<b v-if="data.t3.s3r3.frequency">{{data.translations[data.t3.s3r3.frequency][data.lang]}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t3.s3r3.responsible">{{data.translations.responsible[data.lang]}};&nbsp;
					<b v-if="data.t3.s3r3.responsible">{{data.translations[data.t3.s3r3.responsible][data.lang]}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t3.s3r3.responsible">{{data.translations.deliveryDates[data.lang]}};&nbsp;
					<b v-if="data.t3.s3r3.delivery">{{data.t3.s3r3.delivery}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t3.s3r3.responsible">{{data.translations.amount[data.lang]}};&nbsp;
					<b v-if="data.t3.s3r3.amount">{{data.t3.s3r3.amount}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t3.s3r3.responsible">{{data.translations.comments[data.lang]}};&nbsp;
					<b v-if="data.t3.s3r3.comments"> {{data.t3.s3r3.comments}}</b>
				</ve-col>

				<ve-col md2 v-if="data.t3.s3r4.responsible">
					{{data.translations.tab3Sec3Op4[data.lang]}}
				</ve-col>
				<ve-col md2 v-if="data.t3.s3r4.responsible">{{data.translations.frequency[data.lang]}};&nbsp;
					<b v-if="data.t3.s3r4.frequency">{{data.translations[data.t3.s3r4.frequency][data.lang]}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t3.s3r4.responsible">{{data.translations.responsible[data.lang]}};&nbsp;
					<b v-if="data.t3.s3r4.responsible">{{data.translations[data.t3.s3r4.responsible][data.lang]}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t3.s3r4.responsible">{{data.translations.deliveryDates[data.lang]}};&nbsp;
					<b v-if="data.t3.s3r4.delivery">{{data.t3.s3r4.delivery}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t3.s3r4.responsible">{{data.translations.amount[data.lang]}};&nbsp;
					<b v-if="data.t3.s3r4.amount">{{data.t3.s3r4.amount}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t3.s3r4.responsible">{{data.translations.comments[data.lang]}};&nbsp;
					<b v-if="data.t3.s3r4.comments"> {{data.t3.s3r4.comments}}</b>
				</ve-col>

				<ve-col md2 v-if="data.t3.s3r5.responsible">
					{{data.translations.tab3Sec3Op5[data.lang]}}
				</ve-col>
				<ve-col md2 v-if="data.t3.s3r5.responsible">{{data.translations.frequency[data.lang]}};&nbsp;
					<b v-if="data.t3.s3r5.frequency">{{data.translations[data.t3.s3r5.frequency][data.lang]}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t3.s3r5.responsible">{{data.translations.responsible[data.lang]}};&nbsp;
					<b v-if="data.t3.s3r5.responsible">{{data.translations[data.t3.s3r5.responsible][data.lang]}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t3.s3r5.responsible">{{data.translations.deliveryDates[data.lang]}};&nbsp;
					<b v-if="data.t3.s3r5.delivery">{{data.t3.s3r5.delivery}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t3.s3r5.responsible">{{data.translations.amount[data.lang]}};&nbsp;
					<b v-if="data.t3.s3r5.amount">{{data.t3.s3r5.amount}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t3.s3r5.responsible">{{data.translations.comments[data.lang]}};&nbsp;
					<b v-if="data.t3.s3r5.comments"> {{data.t3.s3r5.comments}}</b>
				</ve-col>

				<ve-col md2 v-if="data.t3.s3r6.responsible">
					{{data.translations.tab3Sec3Op6[data.lang]}}
				</ve-col>
				<ve-col md2 v-if="data.t3.s3r6.responsible">{{data.translations.frequency[data.lang]}};&nbsp;
					<b v-if="data.t3.s3r6.frequency">{{data.translations[data.t3.s3r6.frequency][data.lang]}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t3.s3r6.responsible">{{data.translations.responsible[data.lang]}};&nbsp;
					<b v-if="data.t3.s3r6.responsible">{{data.translations[data.t3.s3r6.responsible][data.lang]}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t3.s3r6.responsible">{{data.translations.deliveryDates[data.lang]}};&nbsp;
					<b v-if="data.t3.s3r6.delivery">{{data.t3.s3r6.delivery}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t3.s3r6.responsible">{{data.translations.amount[data.lang]}};&nbsp;
					<b v-if="data.t3.s3r6.amount">{{data.t3.s3r6.amount}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t3.s3r6.responsible">{{data.translations.comments[data.lang]}};&nbsp;
					<b v-if="data.t3.s3r6.comments"> {{data.t3.s3r6.comments}}</b>
				</ve-col>

				<ve-col md2 v-if="data.t3.s3r7.responsible">
					{{data.translations.tab3Sec3Op7[data.lang]}}
				</ve-col>
				<ve-col md2 v-if="data.t3.s3r7.responsible">{{data.translations.frequency[data.lang]}};&nbsp;
					<b v-if="data.t3.s3r7.frequency">{{data.translations[data.t3.s3r7.frequency][data.lang]}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t3.s3r7.responsible">{{data.translations.responsible[data.lang]}};&nbsp;
					<b v-if="data.t3.s3r7.responsible">{{data.translations[data.t3.s3r7.responsible][data.lang]}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t3.s3r7.responsible">{{data.translations.deliveryDates[data.lang]}};&nbsp;
					<b v-if="data.t3.s3r7.delivery">{{data.t3.s3r7.delivery}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t3.s3r7.responsible">{{data.translations.amount[data.lang]}};&nbsp;
					<b v-if="data.t3.s3r7.amount">{{data.t3.s3r7.amount}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t3.s3r7.responsible">{{data.translations.comments[data.lang]}};&nbsp;
					<b v-if="data.t3.s3r7.comments"> {{data.t3.s3r7.comments}}</b>
				</ve-col>

				<ve-col md2 v-if="data.t3.s3r8.responsible">
					{{data.translations.tab3Sec3Op8[data.lang]}}
				</ve-col>
				<ve-col md2 v-if="data.t3.s3r8.responsible">{{data.translations.frequency[data.lang]}};&nbsp;
					<b v-if="data.t3.s3r8.frequency">{{data.translations[data.t3.s3r8.frequency][data.lang]}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t3.s3r8.responsible">{{data.translations.responsible[data.lang]}};&nbsp;
					<b v-if="data.t3.s3r8.responsible">{{data.translations[data.t3.s3r8.responsible][data.lang]}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t3.s3r8.responsible">{{data.translations.deliveryDates[data.lang]}};&nbsp;
					<b v-if="data.t3.s3r8.delivery">{{data.t3.s3r8.delivery}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t3.s3r8.responsible">{{data.translations.amount[data.lang]}};&nbsp;
					<b v-if="data.t3.s3r8.amount">{{data.t3.s3r8.amount}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t3.s3r8.responsible">{{data.translations.comments[data.lang]}};&nbsp;
					<b v-if="data.t3.s3r8.comments"> {{data.t3.s3r8.comments}}</b>
				</ve-col>
			</div>
            <div class="flex-columns" v-for="t3Oths3 in data.t3.s3Other">
                <ve-col md2 v-if="t3Oths3.moment&&t3Oths3.responsible">{{t3Oths3.moment}}</ve-col>
                <ve-col md2 v-if="t3Oths3.moment&&t3Oths3.responsible">{{data.translations.frequency[data.lang]}};&nbsp;
                    <b v-if="t3Oths3.frequency">{{data.translations[t3Oths3.frequency][data.lang]}}</b>
                </ve-col>
                <ve-col md2 v-if="t3Oths3.moment&&t3Oths3.responsible">{{data.translations.responsible[data.lang]}};&nbsp;
                    <b v-if="t3Oths3.responsible">{{data.translations[t3Oths3.responsible][data.lang]}}</b>
                </ve-col>
                <ve-col md2 v-if="t3Oths3.moment&&t3Oths3.responsible">{{data.translations.deliveryDates[data.lang]}};&nbsp;
                    <b v-if="t3Oths3.delivery">{{t3Oths3.delivery}}</b>
                </ve-col>
                <ve-col md2 v-if="t3Oths3.moment&&t3Oths3.responsible">{{data.translations.amount[data.lang]}};&nbsp;
                    <b v-if="t3Oths3.amount">{{t3Oths3.amount}}</b>
                </ve-col>
                <ve-col md2 v-if="t3Oths3.moment&&t3Oths3.responsible">{{data.translations.comments[data.lang]}};&nbsp;
                    <b v-if="t3Oths3.comments"> {{t3Oths3.comments}}</b>
                </ve-col>
            </div>
		</div>
		<div class="no-page-break" v-if="data.missionType && data.missionType.accounting && data.t3">
			<div class="flex-columns">
				<ve-col md12 v-if="data.t3.s4r1.responsible||data.t3.s4r2.responsible||data.t3.s4r3.responsible||data.t3.s4r4.responsible||data.t3.s4r5.responsible||data.t3.s4r6.responsible||data.t3.s4r7.responsible||data.t3.s4r8.responsible||data.t3.s4r9.responsible||data.t3.s4r10.responsible||data.t3.s4Other[0].moment">
					<b>{{data.translations.tab3Sec4Head[data.lang]}}</b>
				</ve-col>

				<ve-col md2 v-if="data.t3.s4r1.responsible">
					{{data.translations.tab3Sec4Op1[data.lang]}}
				</ve-col>
				<ve-col md2 v-if="data.t3.s4r1.responsible">{{data.translations.frequency[data.lang]}};&nbsp;
					<b v-if="data.t3.s4r1.frequency">{{data.translations[data.t3.s4r1.frequency][data.lang]}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t3.s4r1.responsible">{{data.translations.responsible[data.lang]}};&nbsp;
					<b v-if="data.t3.s4r1.responsible">{{data.translations[data.t3.s4r1.responsible][data.lang]}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t3.s4r1.responsible">{{data.translations.deliveryDates[data.lang]}};&nbsp;
					<b v-if="data.t3.s4r1.delivery">{{data.t3.s4r1.delivery}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t3.s4r1.responsible">{{data.translations.amount[data.lang]}};&nbsp;
					<b v-if="data.t3.s4r1.amount">{{data.t3.s4r1.amount}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t3.s4r1.responsible">{{data.translations.comments[data.lang]}};&nbsp;
					<b v-if="data.t3.s4r1.comments"> {{data.t3.s4r1.comments}}</b>
				</ve-col>

				<ve-col md2 v-if="data.t3.s4r2.responsible">
					{{data.translations.tab3Sec4Op2[data.lang]}}
				</ve-col>
				<ve-col md2 v-if="data.t3.s4r2.responsible">{{data.translations.frequency[data.lang]}};&nbsp;
					<b v-if="data.t3.s4r2.frequency">{{data.translations[data.t3.s4r2.frequency][data.lang]}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t3.s4r2.responsible">{{data.translations.responsible[data.lang]}};&nbsp;
					<b v-if="data.t3.s4r2.responsible">{{data.translations[data.t3.s4r2.responsible][data.lang]}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t3.s4r2.responsible">{{data.translations.deliveryDates[data.lang]}};&nbsp;
					<b v-if="data.t3.s4r2.delivery">{{data.t3.s4r2.delivery}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t3.s4r2.responsible">{{data.translations.amount[data.lang]}};&nbsp;
					<b v-if="data.t3.s4r2.amount">{{data.t3.s4r2.amount}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t3.s4r2.responsible">{{data.translations.comments[data.lang]}};&nbsp;
					<b v-if="data.t3.s4r2.comments"> {{data.t3.s4r2.comments}}</b>
				</ve-col>

				<ve-col md2 v-if="data.t3.s4r3.responsible">
					{{data.translations.tab3Sec4Op3[data.lang]}}
				</ve-col>
				<ve-col md2 v-if="data.t3.s4r3.responsible">{{data.translations.frequency[data.lang]}};&nbsp;
					<b v-if="data.t3.s4r3.frequency">{{data.translations[data.t3.s4r3.frequency][data.lang]}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t3.s4r3.responsible">{{data.translations.responsible[data.lang]}};&nbsp;
					<b v-if="data.t3.s4r3.responsible">{{data.translations[data.t3.s4r3.responsible][data.lang]}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t3.s4r3.responsible">{{data.translations.deliveryDates[data.lang]}};&nbsp;
					<b v-if="data.t3.s4r3.delivery">{{data.t3.s4r3.delivery}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t3.s4r3.responsible">{{data.translations.amount[data.lang]}};&nbsp;
					<b v-if="data.t3.s4r3.amount">{{data.t3.s4r3.amount}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t3.s4r3.responsible">{{data.translations.comments[data.lang]}};&nbsp;
					<b v-if="data.t3.s4r3.comments"> {{data.t3.s4r3.comments}}</b>
				</ve-col>

				<ve-col md2 v-if="data.t3.s4r4.responsible">
					{{data.translations.tab3Sec4Op4[data.lang]}}
				</ve-col>
				<ve-col md2 v-if="data.t3.s4r4.responsible">{{data.translations.frequency[data.lang]}};&nbsp;
					<b v-if="data.t3.s4r4.frequency">{{data.translations[data.t3.s4r4.frequency][data.lang]}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t3.s4r4.responsible">{{data.translations.responsible[data.lang]}};&nbsp;
					<b v-if="data.t3.s4r4.responsible">{{data.translations[data.t3.s4r4.responsible][data.lang]}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t3.s4r4.responsible">{{data.translations.deliveryDates[data.lang]}};&nbsp;
					<b v-if="data.t3.s4r4.delivery">{{data.t3.s4r4.delivery}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t3.s4r4.responsible">{{data.translations.amount[data.lang]}};&nbsp;
					<b v-if="data.t3.s4r4.amount">{{data.t3.s4r4.amount}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t3.s4r4.responsible">{{data.translations.comments[data.lang]}};&nbsp;
					<b v-if="data.t3.s4r4.comments"> {{data.t3.s4r4.comments}}</b>
				</ve-col>

				<ve-col md2 v-if="data.t3.s4r5.responsible">
					{{data.translations.tab3Sec4Op5[data.lang]}}
				</ve-col>
				<ve-col md2 v-if="data.t3.s4r5.responsible">{{data.translations.frequency[data.lang]}};&nbsp;
					<b v-if="data.t3.s4r5.frequency">{{data.translations[data.t3.s4r5.frequency][data.lang]}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t3.s4r5.responsible">{{data.translations.responsible[data.lang]}};&nbsp;
					<b v-if="data.t3.s4r5.responsible">{{data.translations[data.t3.s4r5.responsible][data.lang]}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t3.s4r5.responsible">{{data.translations.deliveryDates[data.lang]}};&nbsp;
					<b v-if="data.t3.s4r5.delivery">{{data.t3.s4r5.delivery}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t3.s4r5.responsible">{{data.translations.amount[data.lang]}};&nbsp;
					<b v-if="data.t3.s4r5.amount">{{data.t3.s4r5.amount}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t3.s4r5.responsible">{{data.translations.comments[data.lang]}};&nbsp;
					<b v-if="data.t3.s4r5.comments"> {{data.t3.s4r5.comments}}</b>
				</ve-col>

				<ve-col md2 v-if="data.t3.s4r6.responsible">
					{{data.translations.tab3Sec4Op6[data.lang]}}
				</ve-col>
				<ve-col md2 v-if="data.t3.s4r6.responsible">{{data.translations.frequency[data.lang]}};&nbsp;
					<b v-if="data.t3.s4r6.frequency">{{data.translations[data.t3.s4r6.frequency][data.lang]}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t3.s4r6.responsible">{{data.translations.responsible[data.lang]}};&nbsp;
					<b v-if="data.t3.s4r6.responsible">{{data.translations[data.t3.s4r6.responsible][data.lang]}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t3.s4r6.responsible">{{data.translations.deliveryDates[data.lang]}};&nbsp;
					<b v-if="data.t3.s4r6.delivery">{{data.t3.s4r6.delivery}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t3.s4r6.responsible">{{data.translations.amount[data.lang]}};&nbsp;
					<b v-if="data.t3.s4r6.amount">{{data.t3.s4r6.amount}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t3.s4r6.responsible">{{data.translations.comments[data.lang]}};&nbsp;
					<b v-if="data.t3.s4r6.comments"> {{data.t3.s4r6.comments}}</b>
				</ve-col>

				<ve-col md2 v-if="data.t3.s4r7.responsible">
					{{data.translations.tab3Sec4Op7[data.lang]}}
				</ve-col>
				<ve-col md2 v-if="data.t3.s4r7.responsible">{{data.translations.frequency[data.lang]}};&nbsp;
					<b v-if="data.t3.s4r7.frequency">{{data.translations[data.t3.s4r7.frequency][data.lang]}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t3.s4r7.responsible">{{data.translations.responsible[data.lang]}};&nbsp;
					<b v-if="data.t3.s4r7.responsible">{{data.translations[data.t3.s4r7.responsible][data.lang]}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t3.s4r7.responsible">{{data.translations.deliveryDates[data.lang]}};&nbsp;
					<b v-if="data.t3.s4r7.delivery">{{data.t3.s4r7.delivery}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t3.s4r7.responsible">{{data.translations.amount[data.lang]}};&nbsp;
					<b v-if="data.t3.s4r7.amount">{{data.t3.s4r7.amount}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t3.s4r7.responsible">{{data.translations.comments[data.lang]}};&nbsp;
					<b v-if="data.t3.s4r7.comments"> {{data.t3.s4r7.comments}}</b>
				</ve-col>

				<ve-col md2 v-if="data.t3.s4r8.responsible">
					{{data.translations.tab3Sec4Op8[data.lang]}}
				</ve-col>
				<ve-col md2 v-if="data.t3.s4r8.responsible">{{data.translations.frequency[data.lang]}};&nbsp;
					<b v-if="data.t3.s4r8.frequency">{{data.translations[data.t3.s4r8.frequency][data.lang]}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t3.s4r8.responsible">{{data.translations.responsible[data.lang]}};&nbsp;
					<b v-if="data.t3.s4r8.responsible">{{data.translations[data.t3.s4r8.responsible][data.lang]}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t3.s4r8.responsible">{{data.translations.deliveryDates[data.lang]}};&nbsp;
					<b v-if="data.t3.s4r8.delivery">{{data.t3.s4r8.delivery}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t3.s4r8.responsible">{{data.translations.amount[data.lang]}};&nbsp;
					<b v-if="data.t3.s4r8.amount">{{data.t3.s4r8.amount}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t3.s4r8.responsible">{{data.translations.comments[data.lang]}};&nbsp;
					<b v-if="data.t3.s4r8.comments"> {{data.t3.s4r8.comments}}</b>
				</ve-col>

				<ve-col md2 v-if="data.t3.s4r9.responsible">
					{{data.translations.tab3Sec4Op9[data.lang]}}
				</ve-col>
				<ve-col md2 v-if="data.t3.s4r9.responsible">{{data.translations.frequency[data.lang]}};&nbsp;
					<b v-if="data.t3.s4r9.frequency">{{data.translations[data.t3.s4r9.frequency][data.lang]}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t3.s4r9.responsible">{{data.translations.responsible[data.lang]}};&nbsp;
					<b v-if="data.t3.s4r9.responsible">{{data.translations[data.t3.s4r9.responsible][data.lang]}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t3.s4r9.responsible">{{data.translations.deliveryDates[data.lang]}};&nbsp;
					<b v-if="data.t3.s4r9.delivery">{{data.t3.s4r9.delivery}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t3.s4r9.responsible">{{data.translations.amount[data.lang]}};&nbsp;
					<b v-if="data.t3.s4r9.amount">{{data.t3.s4r9.amount}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t3.s4r9.responsible">{{data.translations.comments[data.lang]}};&nbsp;
					<b v-if="data.t3.s4r9.comments"> {{data.t3.s4r9.comments}}</b>
				</ve-col>

				<ve-col md2 v-if="data.t3.s4r10.responsible">
					{{data.translations.tab3Sec4Op10[data.lang]}}
				</ve-col>
				<ve-col md2 v-if="data.t3.s4r10.responsible">{{data.translations.frequency[data.lang]}};&nbsp;
					<b v-if="data.t3.s4r10.frequency">{{data.translations[data.t3.s4r10.frequency][data.lang]}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t3.s4r10.responsible">{{data.translations.responsible[data.lang]}};&nbsp;
					<b v-if="data.t3.s4r10.responsible">{{data.translations[data.t3.s4r10.responsible][data.lang]}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t3.s4r10.responsible">{{data.translations.deliveryDates[data.lang]}};&nbsp;
					<b v-if="data.t3.s4r10.delivery">{{data.t3.s4r10.delivery}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t3.s4r10.responsible">{{data.translations.amount[data.lang]}};&nbsp;
					<b v-if="data.t3.s4r10.amount">{{data.t3.s4r10.amount}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t3.s4r10.responsible">{{data.translations.comments[data.lang]}};&nbsp;
					<b v-if="data.t3.s4r10.comments"> {{data.t3.s4r10.comments}}</b>
				</ve-col>
			</div>
            <div class="flex-columns" v-for="t3Oths4 in data.t3.s4Other">
                <ve-col md2 v-if="t3Oths4.moment&&t3Oths4.responsible">{{t3Oths4.moment}}</ve-col>
                <ve-col md2 v-if="t3Oths4.moment&&t3Oths4.responsible">{{data.translations.frequency[data.lang]}};&nbsp;
                    <b v-if="t3Oths4.frequency">{{data.translations[t3Oths4.frequency][data.lang]}}</b>
                </ve-col>
                <ve-col md2 v-if="t3Oths4.moment&&t3Oths4.responsible">{{data.translations.responsible[data.lang]}};&nbsp;
                    <b v-if="t3Oths4.responsible">{{data.translations[t3Oths4.responsible][data.lang]}}</b>
                </ve-col>
                <ve-col md2 v-if="t3Oths4.moment&&t3Oths4.responsible">{{data.translations.deliveryDates[data.lang]}};&nbsp;
                    <b v-if="t3Oths4.delivery">{{t3Oths4.delivery}}</b>
                </ve-col>
                <ve-col md2 v-if="t3Oths4.moment&&t3Oths4.responsible">{{data.translations.amount[data.lang]}};&nbsp;
                    <b v-if="t3Oths4.amount">{{t3Oths4.amount}}</b>
                </ve-col>
                <ve-col md2 v-if="t3Oths4.moment&&t3Oths4.responsible">{{data.translations.comments[data.lang]}};&nbsp;
                    <b v-if="t3Oths4.comments"> {{t3Oths4.comments}}</b>
                </ve-col>
            </div>
		</div>
		<div class="no-page-break" v-if="data.missionType && data.missionType.accounting && data.t3">
			<div class="flex-columns">
				<ve-col md12 v-if="data.t3.s5r1.responsible||data.t3.s5r2.responsible||data.t3.s5r3.responsible||data.t3.s5r4.responsible||data.t3.s5r5.responsible||data.t3.s5Other[0].moment">
					<b>{{data.translations.tab3Sec5Head[data.lang]}}</b>
				</ve-col>

				<ve-col md2 v-if="data.t3.s5r1.responsible">
					{{data.translations.tab3Sec5Op1[data.lang]}}
				</ve-col>
				<ve-col md2 v-if="data.t3.s5r1.responsible">{{data.translations.frequency[data.lang]}};&nbsp;
					<b v-if="data.t3.s5r1.frequency">{{data.translations[data.t3.s5r1.frequency][data.lang]}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t3.s5r1.responsible">{{data.translations.responsible[data.lang]}};&nbsp;
					<b v-if="data.t3.s5r1.responsible">{{data.translations[data.t3.s5r1.responsible][data.lang]}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t3.s5r1.responsible">{{data.translations.deliveryDates[data.lang]}};&nbsp;
					<b v-if="data.t3.s5r1.delivery">{{data.t3.s5r1.delivery}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t3.s5r1.responsible">{{data.translations.amount[data.lang]}};&nbsp;
					<b v-if="data.t3.s5r1.amount">{{data.t3.s5r1.amount}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t3.s5r1.responsible">{{data.translations.comments[data.lang]}};&nbsp;
					<b v-if="data.t3.s5r1.comments"> {{data.t3.s5r1.comments}}</b>
				</ve-col>

				<ve-col md2 v-if="data.t3.s5r2.responsible">
					{{data.translations.tab3Sec5Op2[data.lang]}}
				</ve-col>
				<ve-col md2 v-if="data.t3.s5r2.responsible">{{data.translations.frequency[data.lang]}};&nbsp;
					<b v-if="data.t3.s5r2.frequency">{{data.translations[data.t3.s5r2.frequency][data.lang]}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t3.s5r2.responsible">{{data.translations.responsible[data.lang]}};&nbsp;
					<b v-if="data.t3.s5r2.responsible">{{data.translations[data.t3.s5r2.responsible][data.lang]}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t3.s5r2.responsible">{{data.translations.deliveryDates[data.lang]}};&nbsp;
					<b v-if="data.t3.s5r2.delivery">{{data.t3.s5r2.delivery}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t3.s5r2.responsible">{{data.translations.amount[data.lang]}};&nbsp;
					<b v-if="data.t3.s5r2.amount">{{data.t3.s5r2.amount}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t3.s5r2.responsible">{{data.translations.comments[data.lang]}};&nbsp;
					<b v-if="data.t3.s5r2.comments"> {{data.t3.s5r2.comments}}</b>
				</ve-col>

				<ve-col md2 v-if="data.t3.s5r3.responsible">
					{{data.translations.tab3Sec5Op3[data.lang]}}
				</ve-col>
				<ve-col md2 v-if="data.t3.s5r3.responsible">{{data.translations.frequency[data.lang]}};&nbsp;
					<b v-if="data.t3.s5r3.frequency">{{data.translations[data.t3.s5r3.frequency][data.lang]}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t3.s5r3.responsible">{{data.translations.responsible[data.lang]}};&nbsp;
					<b v-if="data.t3.s5r3.responsible">{{data.translations[data.t3.s5r3.responsible][data.lang]}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t3.s5r3.responsible">{{data.translations.deliveryDates[data.lang]}};&nbsp;
					<b v-if="data.t3.s5r3.delivery">{{data.t3.s5r3.delivery}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t3.s5r3.responsible">{{data.translations.amount[data.lang]}};&nbsp;
					<b v-if="data.t3.s5r3.amount">{{data.t3.s5r3.amount}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t3.s5r3.responsible">{{data.translations.comments[data.lang]}};&nbsp;
					<b v-if="data.t3.s5r3.comments"> {{data.t3.s5r3.comments}}</b>
				</ve-col>

				<ve-col md2 v-if="data.t3.s5r4.responsible">
					{{data.translations.tab3Sec5Op4[data.lang]}}
				</ve-col>
				<ve-col md2 v-if="data.t3.s5r4.responsible">{{data.translations.frequency[data.lang]}};&nbsp;
					<b v-if="data.t3.s5r4.frequency">{{data.translations[data.t3.s5r4.frequency][data.lang]}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t3.s5r4.responsible">{{data.translations.responsible[data.lang]}};&nbsp;
					<b v-if="data.t3.s5r4.responsible">{{data.translations[data.t3.s5r4.responsible][data.lang]}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t3.s5r4.responsible">{{data.translations.deliveryDates[data.lang]}};&nbsp;
					<b v-if="data.t3.s5r4.delivery">{{data.t3.s5r4.delivery}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t3.s5r4.responsible">{{data.translations.amount[data.lang]}};&nbsp;
					<b v-if="data.t3.s5r4.amount">{{data.t3.s5r4.amount}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t3.s5r4.responsible">{{data.translations.comments[data.lang]}};&nbsp;
					<b v-if="data.t3.s5r4.comments"> {{data.t3.s5r4.comments}}</b>
				</ve-col>

				<ve-col md2 v-if="data.t3.s5r5.responsible">
					{{data.translations.tab3Sec5Op5[data.lang]}}
				</ve-col>
				<ve-col md2 v-if="data.t3.s5r5.responsible">{{data.translations.frequency[data.lang]}};&nbsp;
					<b v-if="data.t3.s5r5.frequency">{{data.translations[data.t3.s5r5.frequency][data.lang]}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t3.s5r5.responsible">{{data.translations.responsible[data.lang]}};&nbsp;
					<b v-if="data.t3.s5r5.responsible">{{data.translations[data.t3.s5r5.responsible][data.lang]}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t3.s5r5.responsible">{{data.translations.deliveryDates[data.lang]}};&nbsp;
					<b v-if="data.t3.s5r5.delivery">{{data.t3.s5r5.delivery}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t3.s5r5.responsible">{{data.translations.amount[data.lang]}};&nbsp;
					<b v-if="data.t3.s5r5.amount">{{data.t3.s5r5.amount}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t3.s5r5.responsible">{{data.translations.comments[data.lang]}};&nbsp;
					<b v-if="data.t3.s5r5.comments"> {{data.t3.s5r5.comments}}</b>
				</ve-col>
			</div>
            <div class="flex-columns" v-for="t3Oths5 in data.t3.s5Other">
                <ve-col md2 v-if="t3Oths5.moment&&t3Oths5.responsible">{{t3Oths5.moment}}</ve-col>
                <ve-col md2 v-if="t3Oths5.moment&&t3Oths5.responsible">{{data.translations.frequency[data.lang]}};&nbsp;
                    <b v-if="t3Oths5.frequency">{{data.translations[t3Oths5.frequency][data.lang]}}</b>
                </ve-col>
                <ve-col md2 v-if="t3Oths5.moment&&t3Oths5.responsible">{{data.translations.responsible[data.lang]}};&nbsp;
                    <b v-if="t3Oths5.responsible">{{data.translations[t3Oths5.responsible][data.lang]}}</b>
                </ve-col>
                <ve-col md2 v-if="t3Oths5.moment&&t3Oths5.responsible">{{data.translations.deliveryDates[data.lang]}};&nbsp;
                    <b v-if="t3Oths5.delivery">{{t3Oths5.delivery}}</b>
                </ve-col>
                <ve-col md2 v-if="t3Oths5.moment&&t3Oths5.responsible">{{data.translations.amount[data.lang]}};&nbsp;
                    <b v-if="t3Oths5.amount">{{t3Oths5.amount}}</b>
                </ve-col>
                <ve-col md2 v-if="t3Oths5.moment&&t3Oths5.responsible">{{data.translations.comments[data.lang]}};&nbsp;
                    <b v-if="t3Oths5.comments"> {{t3Oths5.comments}}</b>
                </ve-col>
            </div>
		</div>
		<div class="no-page-break" v-if="data.missionType && data.missionType.accounting && data.t3">
			<div class="flex-columns">
				<ve-col md12 v-if="data.t3.s6r1.responsible||data.t3.s6r2.responsible||data.t3.s6r3.responsible||data.t3.s6r4.responsible||data.t3.s6r5.responsible||data.t3.s6r6.responsible||data.t3.s6r7.responsible||data.t3.s6r8.responsible||data.t3.s6Other[0].moment">
					<b>{{data.translations.tab3Sec6Head[data.lang]}}</b>
				</ve-col>

				<ve-col md2 v-if="data.t3.s6r1.responsible">
					{{data.translations.tab3Sec6Op1[data.lang]}}
				</ve-col>
				<ve-col md2 v-if="data.t3.s6r1.responsible">{{data.translations.frequency[data.lang]}};&nbsp;
					<b v-if="data.t3.s6r1.frequency">{{data.translations[data.t3.s6r1.frequency][data.lang]}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t3.s6r1.responsible">{{data.translations.responsible[data.lang]}};&nbsp;
					<b v-if="data.t3.s6r1.responsible">{{data.translations[data.t3.s6r1.responsible][data.lang]}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t3.s6r1.responsible">{{data.translations.deliveryDates[data.lang]}};&nbsp;
					<b v-if="data.t3.s6r1.delivery">{{data.t3.s6r1.delivery}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t3.s6r1.responsible">{{data.translations.amount[data.lang]}};&nbsp;
					<b v-if="data.t3.s6r1.amount">{{data.t3.s6r1.amount}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t3.s6r1.responsible">{{data.translations.comments[data.lang]}};&nbsp;
					<b v-if="data.t3.s6r1.comments"> {{data.t3.s6r1.comments}}</b>
				</ve-col>

				<ve-col md2 v-if="data.t3.s6r2.responsible">
					{{data.translations.tab3Sec6Op2[data.lang]}}
				</ve-col>
				<ve-col md2 v-if="data.t3.s6r2.responsible">{{data.translations.frequency[data.lang]}};&nbsp;
					<b v-if="data.t3.s6r2.frequency">{{data.translations[data.t3.s6r2.frequency][data.lang]}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t3.s6r2.responsible">{{data.translations.responsible[data.lang]}};&nbsp;
					<b v-if="data.t3.s6r2.responsible">{{data.translations[data.t3.s6r2.responsible][data.lang]}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t3.s6r2.responsible">{{data.translations.deliveryDates[data.lang]}};&nbsp;
					<b v-if="data.t3.s6r2.delivery">{{data.t3.s6r2.delivery}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t3.s6r2.responsible">{{data.translations.amount[data.lang]}};&nbsp;
					<b v-if="data.t3.s6r2.amount">{{data.t3.s6r2.amount}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t3.s6r2.responsible">{{data.translations.comments[data.lang]}};&nbsp;
					<b v-if="data.t3.s6r2.comments"> {{data.t3.s6r2.comments}}</b>
				</ve-col>

				<ve-col md2 v-if="data.t3.s6r3.responsible">
					{{data.translations.tab3Sec6Op3[data.lang]}}
				</ve-col>
				<ve-col md2 v-if="data.t3.s6r3.responsible">{{data.translations.frequency[data.lang]}};&nbsp;
					<b v-if="data.t3.s6r3.frequency">{{data.translations[data.t3.s6r3.frequency][data.lang]}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t3.s6r3.responsible">{{data.translations.responsible[data.lang]}};&nbsp;
					<b v-if="data.t3.s6r3.responsible">{{data.translations[data.t3.s6r3.responsible][data.lang]}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t3.s6r3.responsible">{{data.translations.deliveryDates[data.lang]}};&nbsp;
					<b v-if="data.t3.s6r3.delivery">{{data.t3.s6r3.delivery}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t3.s6r3.responsible">{{data.translations.amount[data.lang]}};&nbsp;
					<b v-if="data.t3.s6r3.amount">{{data.t3.s6r3.amount}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t3.s6r3.responsible">{{data.translations.comments[data.lang]}};&nbsp;
					<b v-if="data.t3.s6r3.comments"> {{data.t3.s6r3.comments}}</b>
				</ve-col>

				<ve-col md2 v-if="data.t3.s6r4.responsible">
					{{data.translations.tab3Sec6Op4[data.lang]}}
				</ve-col>
				<ve-col md2 v-if="data.t3.s6r4.responsible">{{data.translations.frequency[data.lang]}};&nbsp;
					<b v-if="data.t3.s6r4.frequency">{{data.translations[data.t3.s6r4.frequency][data.lang]}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t3.s6r4.responsible">{{data.translations.responsible[data.lang]}};&nbsp;
					<b v-if="data.t3.s6r4.responsible">{{data.translations[data.t3.s6r4.responsible][data.lang]}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t3.s6r4.responsible">{{data.translations.deliveryDates[data.lang]}};&nbsp;
					<b v-if="data.t3.s6r4.delivery">{{data.t3.s6r4.delivery}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t3.s6r4.responsible">{{data.translations.amount[data.lang]}};&nbsp;
					<b v-if="data.t3.s6r4.amount">{{data.t3.s6r4.amount}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t3.s6r4.responsible">{{data.translations.comments[data.lang]}};&nbsp;
					<b v-if="data.t3.s6r4.comments"> {{data.t3.s6r4.comments}}</b>
				</ve-col>

				<ve-col md2 v-if="data.t3.s6r5.responsible">
					{{data.translations.tab3Sec6Op5[data.lang]}}
				</ve-col>
				<ve-col md2 v-if="data.t3.s6r5.responsible">{{data.translations.frequency[data.lang]}};&nbsp;
					<b v-if="data.t3.s6r5.frequency">{{data.translations[data.t3.s6r5.frequency][data.lang]}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t3.s6r5.responsible">{{data.translations.responsible[data.lang]}};&nbsp;
					<b v-if="data.t3.s6r5.responsible">{{data.translations[data.t3.s6r5.responsible][data.lang]}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t3.s6r5.responsible">{{data.translations.deliveryDates[data.lang]}};&nbsp;
					<b v-if="data.t3.s6r5.delivery">{{data.t3.s6r5.delivery}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t3.s6r5.responsible">{{data.translations.amount[data.lang]}};&nbsp;
					<b v-if="data.t3.s6r5.amount">{{data.t3.s6r5.amount}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t3.s6r5.responsible">{{data.translations.comments[data.lang]}};&nbsp;
					<b v-if="data.t3.s6r5.comments"> {{data.t3.s6r5.comments}}</b>
				</ve-col>

				<ve-col md2 v-if="data.t3.s6r6.responsible">
					{{data.translations.tab3Sec6Op6[data.lang]}}
				</ve-col>
				<ve-col md2 v-if="data.t3.s6r6.responsible">{{data.translations.frequency[data.lang]}};&nbsp;
					<b v-if="data.t3.s6r6.frequency">{{data.translations[data.t3.s6r6.frequency][data.lang]}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t3.s6r6.responsible">{{data.translations.responsible[data.lang]}};&nbsp;
					<b v-if="data.t3.s6r6.responsible">{{data.translations[data.t3.s6r6.responsible][data.lang]}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t3.s6r6.responsible">{{data.translations.deliveryDates[data.lang]}};&nbsp;
					<b v-if="data.t3.s6r6.delivery">{{data.t3.s6r6.delivery}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t3.s6r6.responsible">{{data.translations.amount[data.lang]}};&nbsp;
					<b v-if="data.t3.s6r6.amount">{{data.t3.s6r6.amount}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t3.s6r6.responsible">{{data.translations.comments[data.lang]}};&nbsp;
					<b v-if="data.t3.s6r6.comments"> {{data.t3.s6r6.comments}}</b>
				</ve-col>

				<ve-col md2 v-if="data.t3.s6r7.responsible">
					{{data.translations.tab3Sec6Op7[data.lang]}}
				</ve-col>
				<ve-col md2 v-if="data.t3.s6r7.responsible">{{data.translations.frequency[data.lang]}};&nbsp;
					<b v-if="data.t3.s6r7.frequency">{{data.translations[data.t3.s6r7.frequency][data.lang]}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t3.s6r7.responsible">{{data.translations.responsible[data.lang]}};&nbsp;
					<b v-if="data.t3.s6r7.responsible">{{data.translations[data.t3.s6r7.responsible][data.lang]}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t3.s6r7.responsible">{{data.translations.deliveryDates[data.lang]}};&nbsp;
					<b v-if="data.t3.s6r7.delivery">{{data.t3.s6r7.delivery}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t3.s6r7.responsible">{{data.translations.amount[data.lang]}};&nbsp;
					<b v-if="data.t3.s6r7.amount">{{data.t3.s6r7.amount}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t3.s6r7.responsible">{{data.translations.comments[data.lang]}};&nbsp;
					<b v-if="data.t3.s6r7.comments"> {{data.t3.s6r7.comments}}</b>
				</ve-col>

				<ve-col md2 v-if="data.t3.s6r8.responsible">
					{{data.translations.tab3Sec6Op8[data.lang]}}
				</ve-col>
				<ve-col md2 v-if="data.t3.s6r8.responsible">{{data.translations.frequency[data.lang]}};&nbsp;
					<b v-if="data.t3.s6r8.frequency">{{data.translations[data.t3.s6r8.frequency][data.lang]}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t3.s6r8.responsible">{{data.translations.responsible[data.lang]}};&nbsp;
					<b v-if="data.t3.s6r8.responsible">{{data.translations[data.t3.s6r8.responsible][data.lang]}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t3.s6r8.responsible">{{data.translations.deliveryDates[data.lang]}};&nbsp;
					<b v-if="data.t3.s6r8.delivery">{{data.t3.s6r8.delivery}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t3.s6r8.responsible">{{data.translations.amount[data.lang]}};&nbsp;
					<b v-if="data.t3.s6r8.amount">{{data.t3.s6r8.amount}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t3.s6r8.responsible">{{data.translations.comments[data.lang]}};&nbsp;
					<b v-if="data.t3.s6r8.comments"> {{data.t3.s6r8.comments}}</b>
				</ve-col>
			</div>
            <div class="flex-columns" v-for="t3Oths6 in data.t3.s6Other">
                <ve-col md2 v-if="t3Oths6.moment&&t3Oths6.responsible">{{t3Oths6.moment}}</ve-col>
                <ve-col md2 v-if="t3Oths6.moment&&t3Oths6.responsible">{{data.translations.frequency[data.lang]}};&nbsp;
                    <b v-if="t3Oths6.frequency">{{data.translations[t3Oths6.frequency][data.lang]}}</b>
                </ve-col>
                <ve-col md2 v-if="t3Oths6.moment&&t3Oths6.responsible">{{data.translations.responsible[data.lang]}};&nbsp;
                    <b v-if="t3Oths6.responsible">{{data.translations[t3Oths6.responsible][data.lang]}}</b>
                </ve-col>
                <ve-col md2 v-if="t3Oths6.moment&&t3Oths6.responsible">{{data.translations.deliveryDates[data.lang]}};&nbsp;
                    <b v-if="t3Oths6.delivery">{{t3Oths6.delivery}}</b>
                </ve-col>
                <ve-col md2 v-if="t3Oths6.moment&&t3Oths6.responsible">{{data.translations.amount[data.lang]}};&nbsp;
                    <b v-if="t3Oths6.amount">{{t3Oths6.amount}}</b>
                </ve-col>
                <ve-col md2 v-if="t3Oths6.moment&&t3Oths6.responsible">{{data.translations.comments[data.lang]}};&nbsp;
                    <b v-if="t3Oths6.comments"> {{t3Oths6.comments}}</b>
                </ve-col>
            </div>
		</div>
		<div class="no-page-break" v-if="data.missionType && data.missionType.accounting && data.t3">
			<div class="flex-columns">
				<ve-col md12 v-if="data.t3.s7r1.responsible||data.t3.s7r2.responsible||data.t3.s7r3.responsible||data.t3.s7Other[0].moment">
					<b>{{data.translations.tab3Sec7Head[data.lang]}}</b>
				</ve-col>

				<ve-col md2 v-if="data.t3.s7r1.responsible">
					{{data.translations.tab3Sec7Op1[data.lang]}}
				</ve-col>
				<ve-col md2 v-if="data.t3.s7r1.responsible">{{data.translations.frequency[data.lang]}};&nbsp;
					<b v-if="data.t3.s7r1.frequency">{{data.translations[data.t3.s7r1.frequency][data.lang]}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t3.s7r1.responsible">{{data.translations.responsible[data.lang]}};&nbsp;
					<b v-if="data.t3.s7r1.responsible">{{data.translations[data.t3.s7r1.responsible][data.lang]}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t3.s7r1.responsible">{{data.translations.deliveryDates[data.lang]}};&nbsp;
					<b v-if="data.t3.s7r1.delivery">{{data.t3.s7r1.delivery}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t3.s7r1.responsible">{{data.translations.amount[data.lang]}};&nbsp;
					<b v-if="data.t3.s7r1.amount">{{data.t3.s7r1.amount}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t3.s7r1.responsible">{{data.translations.comments[data.lang]}};&nbsp;
					<b v-if="data.t3.s7r1.comments"> {{data.t3.s7r1.comments}}</b>
				</ve-col>

				<ve-col md2 v-if="data.t3.s7r2.responsible">
					{{data.translations.tab3Sec7Op2[data.lang]}}
				</ve-col>
				<ve-col md2 v-if="data.t3.s7r2.responsible">{{data.translations.frequency[data.lang]}};&nbsp;
					<b v-if="data.t3.s7r2.frequency">{{data.translations[data.t3.s7r2.frequency][data.lang]}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t3.s7r2.responsible">{{data.translations.responsible[data.lang]}};&nbsp;
					<b v-if="data.t3.s7r2.responsible">{{data.translations[data.t3.s7r2.responsible][data.lang]}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t3.s7r2.responsible">{{data.translations.deliveryDates[data.lang]}};&nbsp;
					<b v-if="data.t3.s7r2.delivery">{{data.t3.s7r2.delivery}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t3.s7r2.responsible">{{data.translations.amount[data.lang]}};&nbsp;
					<b v-if="data.t3.s7r2.amount">{{data.t3.s7r2.amount}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t3.s7r2.responsible">{{data.translations.comments[data.lang]}};&nbsp;
					<b v-if="data.t3.s7r2.comments"> {{data.t3.s7r2.comments}}</b>
				</ve-col>

				<ve-col md2 v-if="data.t3.s7r3.responsible">
					{{data.translations.tab3Sec7Op3[data.lang]}}
				</ve-col>
				<ve-col md2 v-if="data.t3.s7r3.responsible">{{data.translations.frequency[data.lang]}};&nbsp;
					<b v-if="data.t3.s7r3.frequency">{{data.translations[data.t3.s7r3.frequency][data.lang]}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t3.s7r3.responsible">{{data.translations.responsible[data.lang]}};&nbsp;
					<b v-if="data.t3.s7r3.responsible">{{data.translations[data.t3.s7r3.responsible][data.lang]}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t3.s7r3.responsible">{{data.translations.deliveryDates[data.lang]}};&nbsp;
					<b v-if="data.t3.s7r3.delivery">{{data.t3.s7r3.delivery}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t3.s7r3.responsible">{{data.translations.amount[data.lang]}};&nbsp;
					<b v-if="data.t3.s7r3.amount">{{data.t3.s7r3.amount}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t3.s7r3.responsible">{{data.translations.comments[data.lang]}};&nbsp;
					<b v-if="data.t3.s7r3.comments"> {{data.t3.s7r3.comments}}</b>
				</ve-col>
			</div>
            <div class="flex-columns" v-for="t3Oths7 in data.t3.s7Other">
                <ve-col md2 v-if="t3Oths7.moment&&t3Oths7.responsible">{{t3Oths7.moment}}</ve-col>
                <ve-col md2 v-if="t3Oths7.moment&&t3Oths7.responsible">{{data.translations.frequency[data.lang]}};&nbsp;
                    <b v-if="t3Oths7.frequency">{{data.translations[t3Oths7.frequency][data.lang]}}</b>
                </ve-col>
                <ve-col md2 v-if="t3Oths7.moment&&t3Oths7.responsible">{{data.translations.responsible[data.lang]}};&nbsp;
                    <b v-if="t3Oths7.responsible">{{data.translations[t3Oths7.responsible][data.lang]}}</b>
                </ve-col>
                <ve-col md2 v-if="t3Oths7.moment&&t3Oths7.responsible">{{data.translations.deliveryDates[data.lang]}};&nbsp;
                    <b v-if="t3Oths7.delivery">{{t3Oths7.delivery}}</b>
                </ve-col>
                <ve-col md2 v-if="t3Oths7.moment&&t3Oths7.responsible">{{data.translations.amount[data.lang]}};&nbsp;
                    <b v-if="t3Oths7.amount">{{t3Oths7.amount}}</b>
                </ve-col>
                <ve-col md2 v-if="t3Oths7.moment&&t3Oths7.responsible">{{data.translations.comments[data.lang]}};&nbsp;
                    <b v-if="t3Oths7.comments"> {{t3Oths7.comments}}</b>
                </ve-col>
            </div>
		</div>
		<div class="no-page-break" v-if="data.missionType && data.missionType.accounting && data.t3">
			<div class="flex-columns">
				<ve-col md12 v-if="data.t3.s8Other[0].moment">
					<b>{{data.translations.tab3Sec8Head[data.lang]}}</b>
				</ve-col>
			</div>
            <div class="flex-columns" v-for="t3Oths8 in data.t3.s8Other">
                <ve-col md2 v-if="t3Oths8.moment&&t3Oths8.responsible">{{t3Oths8.moment}}</ve-col>
                <ve-col md2 v-if="t3Oths8.moment&&t3Oths8.responsible">{{data.translations.frequency[data.lang]}};&nbsp;
                    <b v-if="t3Oths8.frequency">{{data.translations[t3Oths8.frequency][data.lang]}}</b>
                </ve-col>
                <ve-col md2 v-if="t3Oths8.moment&&t3Oths8.responsible">{{data.translations.responsible[data.lang]}};&nbsp;
                    <b v-if="t3Oths8.responsible">{{data.translations[t3Oths8.responsible][data.lang]}}</b>
                </ve-col>
                <ve-col md2 v-if="t3Oths8.moment&&t3Oths8.responsible">{{data.translations.deliveryDates[data.lang]}};&nbsp;
                    <b v-if="t3Oths8.delivery">{{t3Oths8.delivery}}</b>
                </ve-col>
                <ve-col md2 v-if="t3Oths8.moment&&t3Oths8.responsible">{{data.translations.amount[data.lang]}};&nbsp;
                    <b v-if="t3Oths8.amount">{{t3Oths8.amount}}</b>
                </ve-col>
                <ve-col md2 v-if="t3Oths8.moment&&t3Oths8.responsible">{{data.translations.comments[data.lang]}};&nbsp;
                    <b v-if="t3Oths8.comments"> {{t3Oths8.comments}}</b>
                </ve-col>
            </div>
		</div>
		<div class="no-page-break" v-if="data.missionType && data.missionType.accounting && data.t3">
			<div class="flex-columns">
				<ve-col md12 v-if="data.t3.s9r1.responsible||data.t3.s9r2.responsible||data.t3.s9r3.responsible||data.t3.s9r4.responsible||data.t3.s9r5.responsible||data.t3.s9r6.responsible||data.t3.s9r7.responsible||data.t3.s9r8.responsible||data.t3.s9r9.responsible||data.t3.s9Other[0].moment">
					<b>{{data.translations.tab3Sec9Head[data.lang]}}</b>
				</ve-col>

				<ve-col md2 v-if="data.t3.s9r1.responsible">
					{{data.translations.tab3Sec9Op1[data.lang]}}
				</ve-col>
				<ve-col md2 v-if="data.t3.s9r1.responsible">{{data.translations.frequency[data.lang]}};&nbsp;
					<b v-if="data.t3.s9r1.frequency">{{data.translations[data.t3.s9r1.frequency][data.lang]}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t3.s9r1.responsible">{{data.translations.responsible[data.lang]}};&nbsp;
					<b v-if="data.t3.s9r1.responsible">{{data.translations[data.t3.s9r1.responsible][data.lang]}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t3.s9r1.responsible">{{data.translations.deliveryDates[data.lang]}};&nbsp;
					<b v-if="data.t3.s9r1.delivery">{{data.t3.s9r1.delivery}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t3.s9r1.responsible">{{data.translations.amount[data.lang]}};&nbsp;
					<b v-if="data.t3.s9r1.amount">{{data.t3.s9r1.amount}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t3.s9r1.responsible">{{data.translations.comments[data.lang]}};&nbsp;
					<b v-if="data.t3.s9r1.comments"> {{data.t3.s9r1.comments}}</b>
				</ve-col>

				<ve-col md2 v-if="data.t3.s9r2.responsible">
					{{data.translations.tab3Sec9Op2[data.lang]}}
				</ve-col>
				<ve-col md2 v-if="data.t3.s9r2.responsible">{{data.translations.frequency[data.lang]}};&nbsp;
					<b v-if="data.t3.s9r2.frequency">{{data.translations[data.t3.s9r2.frequency][data.lang]}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t3.s9r2.responsible">{{data.translations.responsible[data.lang]}};&nbsp;
					<b v-if="data.t3.s9r2.responsible">{{data.translations[data.t3.s9r2.responsible][data.lang]}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t3.s9r2.responsible">{{data.translations.deliveryDates[data.lang]}};&nbsp;
					<b v-if="data.t3.s9r2.delivery">{{data.t3.s9r2.delivery}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t3.s9r2.responsible">{{data.translations.amount[data.lang]}};&nbsp;
					<b v-if="data.t3.s9r2.amount">{{data.t3.s9r2.amount}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t3.s9r2.responsible">{{data.translations.comments[data.lang]}};&nbsp;
					<b v-if="data.t3.s9r2.comments"> {{data.t3.s9r2.comments}}</b>
				</ve-col>

				<ve-col md2 v-if="data.t3.s9r3.responsible">
					{{data.translations.tab3Sec9Op3[data.lang]}}
				</ve-col>
				<ve-col md2 v-if="data.t3.s9r3.responsible">{{data.translations.frequency[data.lang]}};&nbsp;
					<b v-if="data.t3.s9r3.frequency">{{data.translations[data.t3.s9r3.frequency][data.lang]}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t3.s9r3.responsible">{{data.translations.responsible[data.lang]}};&nbsp;
					<b v-if="data.t3.s9r3.responsible">{{data.translations[data.t3.s9r3.responsible][data.lang]}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t3.s9r3.responsible">{{data.translations.deliveryDates[data.lang]}};&nbsp;
					<b v-if="data.t3.s9r3.delivery">{{data.t3.s9r3.delivery}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t3.s9r3.responsible">{{data.translations.amount[data.lang]}};&nbsp;
					<b v-if="data.t3.s9r3.amount">{{data.t3.s9r3.amount}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t3.s9r3.responsible">{{data.translations.comments[data.lang]}};&nbsp;
					<b v-if="data.t3.s9r3.comments"> {{data.t3.s9r3.comments}}</b>
				</ve-col>

				<ve-col md2 v-if="data.t3.s9r4.responsible">
					{{data.translations.tab3Sec9Op4[data.lang]}}
				</ve-col>
				<ve-col md2 v-if="data.t3.s9r4.responsible">{{data.translations.frequency[data.lang]}};&nbsp;
					<b v-if="data.t3.s9r4.frequency">{{data.translations[data.t3.s9r4.frequency][data.lang]}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t3.s9r4.responsible">{{data.translations.responsible[data.lang]}};&nbsp;
					<b v-if="data.t3.s9r4.responsible">{{data.translations[data.t3.s9r4.responsible][data.lang]}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t3.s9r4.responsible">{{data.translations.deliveryDates[data.lang]}};&nbsp;
					<b v-if="data.t3.s9r4.delivery">{{data.t3.s9r4.delivery}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t3.s9r4.responsible">{{data.translations.amount[data.lang]}};&nbsp;
					<b v-if="data.t3.s9r4.amount">{{data.t3.s9r4.amount}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t3.s9r4.responsible">{{data.translations.comments[data.lang]}};&nbsp;
					<b v-if="data.t3.s9r4.comments"> {{data.t3.s9r4.comments}}</b>
				</ve-col>

				<ve-col md2 v-if="data.t3.s9r5.responsible">
					{{data.translations.tab3Sec9Op5[data.lang]}}
				</ve-col>
				<ve-col md2 v-if="data.t3.s9r5.responsible">{{data.translations.frequency[data.lang]}};&nbsp;
					<b v-if="data.t3.s9r5.frequency">{{data.translations[data.t3.s9r5.frequency][data.lang]}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t3.s9r5.responsible">{{data.translations.responsible[data.lang]}};&nbsp;
					<b v-if="data.t3.s9r5.responsible">{{data.translations[data.t3.s9r5.responsible][data.lang]}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t3.s9r5.responsible">{{data.translations.deliveryDates[data.lang]}};&nbsp;
					<b v-if="data.t3.s9r5.delivery">{{data.t3.s9r5.delivery}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t3.s9r5.responsible">{{data.translations.amount[data.lang]}};&nbsp;
					<b v-if="data.t3.s9r5.amount">{{data.t3.s9r5.amount}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t3.s9r5.responsible">{{data.translations.comments[data.lang]}};&nbsp;
					<b v-if="data.t3.s9r5.comments"> {{data.t3.s9r5.comments}}</b>
				</ve-col>

				<ve-col md2 v-if="data.t3.s9r6.responsible">
					{{data.translations.tab3Sec9Op6[data.lang]}}
				</ve-col>
				<ve-col md2 v-if="data.t3.s9r6.responsible">{{data.translations.frequency[data.lang]}};&nbsp;
					<b v-if="data.t3.s9r6.frequency">{{data.translations[data.t3.s9r6.frequency][data.lang]}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t3.s9r6.responsible">{{data.translations.responsible[data.lang]}};&nbsp;
					<b v-if="data.t3.s9r6.responsible">{{data.translations[data.t3.s9r6.responsible][data.lang]}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t3.s9r6.responsible">{{data.translations.deliveryDates[data.lang]}};&nbsp;
					<b v-if="data.t3.s9r6.delivery">{{data.t3.s9r6.delivery}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t3.s9r6.responsible">{{data.translations.amount[data.lang]}};&nbsp;
					<b v-if="data.t3.s9r6.amount">{{data.t3.s9r6.amount}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t3.s9r6.responsible">{{data.translations.comments[data.lang]}};&nbsp;
					<b v-if="data.t3.s9r6.comments"> {{data.t3.s9r6.comments}}</b>
				</ve-col>

				<ve-col md2 v-if="data.t3.s9r7.responsible">
					{{data.translations.tab3Sec9Op7[data.lang]}}
				</ve-col>
				<ve-col md2 v-if="data.t3.s9r7.responsible">{{data.translations.frequency[data.lang]}};&nbsp;
					<b v-if="data.t3.s9r7.frequency">{{data.translations[data.t3.s9r7.frequency][data.lang]}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t3.s9r7.responsible">{{data.translations.responsible[data.lang]}};&nbsp;
					<b v-if="data.t3.s9r7.responsible">{{data.translations[data.t3.s9r7.responsible][data.lang]}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t3.s9r7.responsible">{{data.translations.deliveryDates[data.lang]}};&nbsp;
					<b v-if="data.t3.s9r7.delivery">{{data.t3.s9r7.delivery}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t3.s9r7.responsible">{{data.translations.amount[data.lang]}};&nbsp;
					<b v-if="data.t3.s9r7.amount">{{data.t3.s9r7.amount}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t3.s9r7.responsible">{{data.translations.comments[data.lang]}};&nbsp;
					<b v-if="data.t3.s9r7.comments"> {{data.t3.s9r7.comments}}</b>
				</ve-col>

				<ve-col md2 v-if="data.t3.s9r8.responsible">
					{{data.translations.tab3Sec9Op8[data.lang]}}
				</ve-col>
				<ve-col md2 v-if="data.t3.s9r8.responsible">{{data.translations.frequency[data.lang]}};&nbsp;
					<b v-if="data.t3.s9r8.frequency">{{data.translations[data.t3.s9r8.frequency][data.lang]}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t3.s9r8.responsible">{{data.translations.responsible[data.lang]}};&nbsp;
					<b v-if="data.t3.s9r8.responsible">{{data.translations[data.t3.s9r8.responsible][data.lang]}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t3.s9r8.responsible">{{data.translations.deliveryDates[data.lang]}};&nbsp;
					<b v-if="data.t3.s9r8.delivery">{{data.t3.s9r8.delivery}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t3.s9r8.responsible">{{data.translations.amount[data.lang]}};&nbsp;
					<b v-if="data.t3.s9r8.amount">{{data.t3.s9r8.amount}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t3.s9r8.responsible">{{data.translations.comments[data.lang]}};&nbsp;
					<b v-if="data.t3.s9r8.comments"> {{data.t3.s9r8.comments}}</b>
				</ve-col>

				<ve-col md2 v-if="data.t3.s9r9.responsible">
					{{data.translations.tab3Sec9Op9[data.lang]}}
				</ve-col>
				<ve-col md2 v-if="data.t3.s9r9.responsible">{{data.translations.frequency[data.lang]}};&nbsp;
					<b v-if="data.t3.s9r9.frequency">{{data.translations[data.t3.s9r9.frequency][data.lang]}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t3.s9r9.responsible">{{data.translations.responsible[data.lang]}};&nbsp;
					<b v-if="data.t3.s9r9.responsible">{{data.translations[data.t3.s9r9.responsible][data.lang]}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t3.s9r9.responsible">{{data.translations.deliveryDates[data.lang]}};&nbsp;
					<b v-if="data.t3.s9r9.delivery">{{data.t3.s9r9.delivery}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t3.s9r9.responsible">{{data.translations.amount[data.lang]}};&nbsp;
					<b v-if="data.t3.s9r9.amount">{{data.t3.s9r9.amount}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t3.s9r9.responsible">{{data.translations.comments[data.lang]}};&nbsp;
					<b v-if="data.t3.s9r9.comments"> {{data.t3.s9r9.comments}}</b>
				</ve-col>
				
				
			</div>
            <div class="flex-columns" v-for="t3Oths9 in data.t3.s9Other">
					<ve-col md2 v-if="t3Oths9.moment&&t3Oths9.responsible">{{t3Oths9.moment}}</ve-col>
					<ve-col md2 v-if="t3Oths9.moment&&t3Oths9.responsible">{{data.translations.frequency[data.lang]}};&nbsp;
						<b v-if="t3Oths9.frequency">{{data.translations[t3Oths9.frequency][data.lang]}}</b>
					</ve-col>
					<ve-col md2 v-if="t3Oths9.moment&&t3Oths9.responsible">{{data.translations.responsible[data.lang]}};&nbsp;
						<b v-if="t3Oths9.responsible">{{data.translations[t3Oths9.responsible][data.lang]}}</b>
					</ve-col>
					<ve-col md2 v-if="t3Oths9.moment&&t3Oths9.responsible">{{data.translations.deliveryDates[data.lang]}};&nbsp;
						<b v-if="t3Oths9.delivery">{{t3Oths9.delivery}}</b>
					</ve-col>
					<ve-col md2 v-if="t3Oths9.moment&&t3Oths9.responsible">{{data.translations.amount[data.lang]}};&nbsp;
						<b v-if="t3Oths9.amount">{{t3Oths9.amount}}</b>
					</ve-col>
					<ve-col md2 v-if="t3Oths9.moment&&t3Oths9.responsible">{{data.translations.comments[data.lang]}};&nbsp;
						<b v-if="t3Oths9.comments"> {{t3Oths9.comments}}</b>
					</ve-col>
			</div>
		</div>
		<div class="no-page-break" v-if="data.missionType && data.missionType.accounting && data.t3">
			<div class="flex-columns">
				<ve-col md12 v-if="data.t3.s10r1.responsible||data.t3.s10Other[0].moment">
					<b>{{data.translations.tab3Sec10Head[data.lang]}}</b>
				</ve-col>

				<ve-col md2 v-if="data.t3.s10r1.responsible">
					{{data.translations.tab3Sec10Op1[data.lang]}}
				</ve-col>
				<ve-col md2 v-if="data.t3.s10r1.responsible">{{data.translations.frequency[data.lang]}};&nbsp;
					<b v-if="data.t3.s10r1.frequency">{{data.translations[data.t3.s10r1.frequency][data.lang]}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t3.s10r1.responsible">{{data.translations.responsible[data.lang]}};&nbsp;
					<b v-if="data.t3.s10r1.responsible">{{data.translations[data.t3.s10r1.responsible][data.lang]}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t3.s10r1.responsible">{{data.translations.deliveryDates[data.lang]}};&nbsp;
					<b v-if="data.t3.s10r1.delivery">{{data.t3.s10r1.delivery}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t3.s10r1.responsible">{{data.translations.amount[data.lang]}};&nbsp;
					<b v-if="data.t3.s10r1.amount">{{data.t3.s10r1.amount}}</b>
				</ve-col>
				<ve-col md2 v-if="data.t3.s10r1.responsible">{{data.translations.comments[data.lang]}};&nbsp;
					<b v-if="data.t3.s10r1.comments"> {{data.t3.s10r1.comments}}</b>
				</ve-col>
			</div>
            <div class="flex-columns" v-for="t3Oths10 in data.t3.s10Other">
                <ve-col md2 v-if="t3Oths10.moment&&t3Oths10.responsible">{{t3Oths10.moment}}</ve-col>
                <ve-col md2 v-if="t3Oths10.moment&&t3Oths10.responsible">{{data.translations.frequency[data.lang]}};&nbsp;
                    <b v-if="t3Oths10.frequency">{{data.translations[t3Oths10.frequency][data.lang]}}</b>
                </ve-col>
                <ve-col md2 v-if="t3Oths10.moment&&t3Oths10.responsible">{{data.translations.responsible[data.lang]}};&nbsp;
                    <b v-if="t3Oths10.responsible">{{data.translations[t3Oths10.responsible][data.lang]}}</b>
                </ve-col>
                <ve-col md2 v-if="t3Oths10.moment&&t3Oths10.responsible">{{data.translations.deliveryDates[data.lang]}};&nbsp;
                    <b v-if="t3Oths10.delivery">{{t3Oths10.delivery}}</b>
                </ve-col>
                <ve-col md2 v-if="t3Oths10.moment&&t3Oths10.responsible">{{data.translations.amount[data.lang]}};&nbsp;
                    <b v-if="t3Oths10.amount">{{t3Oths10.amount}}</b>
                </ve-col>
                <ve-col md2 v-if="t3Oths10.moment&&t3Oths10.responsible">{{data.translations.comments[data.lang]}};&nbsp;
                    <b v-if="t3Oths10.comments"> {{t3Oths10.comments}}</b>
                </ve-col>
            </div>
		</div>
		<!--<div class="no-page-break" v-if="data.missionType && data.missionType.accounting && data.t3">
			<div class="flex-columns">
				<ve-col md4 v-if="data.t3.assignments">
					<span>{{data.translations.assignments[data.lang]}};<b>{{data.t3.assignments}}</b></span>
				</ve-col>
				<ve-col md4 v-if="data.t3.schedule">
					<span>{{data.translations.schedule[data.lang]}};<b>{{data.t3.schedule}}</b></span>
				</ve-col>
				<ve-col md4 v-if="data.t3.serviceProvider">
					<span>{{data.translations.serviceProvider[data.lang]}};<b>{{data.t3.serviceProvider}}</b></span>
				</ve-col>
			</div>
		</div>
		<div class="no-page-break" v-if="data.missionType && data.missionType.accounting && data.t3">
			<div class="flex-columns">
				<ve-col md12>
					<span>{{data.translations.filling[data.lang]}};
						<b v-if="data.t3.filling.alternative1">{{data.translations.alternative1[data.lang]}}</b>
						<b v-if="data.t3.filling.alternative2">{{data.translations.alternative2[data.lang]}}</b>
					</span>
					<p v-if="data.t3.filling.alternative1">
						{{data.translations.alternative1Info[data.lang]}}
					</p>
				</ve-col>
				<ve-col md12 v-if="data.t3.server">
					<span>{{data.translations.server[data.lang]}};   </span>
					<div class="custom-control custom-control-inline custom-checkbox mb-1">
						<input type="checkbox" class="custom-control-input" :checked=(data.t3.server.own) disabled/>
						<label class="custom-control-label custom-checkbox-label" for="">{{data.translations.own[data.lang]}}</label>&nbsp
					</div>
					<div class="custom-control custom-control-inline custom-checkbox mb-1">
						<input type="checkbox" class="custom-control-input" :checked=(data.t3.server.provider) disabled/>
						<label class="custom-control-label custom-checkbox-label" for="">{{data.translations.provider[data.lang]}}</label>
					</div>
				</ve-col>
			</div>
		</div>
		<div class="no-page-break" v-if="data.missionType && data.missionType.accounting && data.t3">
			<div class="flex-columns">
				<ve-col md12>
					<h3>{{data.translations.fee[data.lang]}}</h3>
					<span>
						<b v-if="data.t3.priceType.priceOp1">{{data.translations.priceOp1[data.lang]}}</b>
						<b v-if="data.t3.priceType.priceOp2">{{data.translations.priceOp2[data.lang]}}</b>
						<b v-if="data.t3.priceType.priceOp3">{{data.translations.priceOp3[data.lang]}}</b>
					</span>
					<p v-if="data.t3.priceType.priceOp3">
						{{data.t3.priceOp3}}
					</p>
				</ve-col>
				<ve-col md6 v-if="data.t3.priceType.priceOp1&&data.t3.sek">
					<span>{{data.translations.sek[data.lang]}}; <b>{{data.t3.sek}}</b></span>
				</ve-col>
				<ve-col md6 v-if="data.t3.priceType.priceOp1&&data.t3.priceFrequency">
					<span>{{data.translations.frequency[data.lang]}}; <b>{{data.translations[data.t3.priceFrequency][data.lang]}}</b></span>
				</ve-col>
			</div>
		</div>
		<div class="no-page-break" v-if="data.missionType && data.missionType.accounting && data.t3">
			<div class="flex-columns">
				<ve-col md6 v-if="data.t3.priceType.priceOp2&&data.t3.sekforSalary">
					<span>{{data.translations.sekforSalary[data.lang]}}; <b>{{data.t3.sekforSalary}}</b></span>
				</ve-col>
				<ve-col md6 v-if="data.t3.priceType.priceOp2&&data.t3.sekArray.length>0">
					<span>{{data.translations.sekforOther[data.lang]}}:</span><br>
					<div v-for="sek in data.t3.sekArray">
					 	<span v-if="sek.type"><b>{{sek.type}} : {{sek.value}}</b></span><br>
					</div>
				</ve-col>
				<ve-col md6 v-if="data.t3.priceType.priceOp2&&data.t3.assignmentTime">
					<span>{{data.translations.assignmentTime[data.lang]}}; <b>{{data.t3.assignmentTime}}</b></span>
				</ve-col>
				<ve-col md6 v-if="data.t3.priceType.priceOp2&&data.t3.timeFrequency">
					<span>{{data.translations.frequency[data.lang]}}; <b>{{data.translations[data.t3.timeFrequency][data.lang]}}</b></span>
				</ve-col>
				<ve-col md6 v-if="(data.t3.priceType.priceOp2||data.t3.priceType.priceOp1)&&data.t3.fee.index">
					<span>{{data.translations.feeIndex[data.lang]}}; <b>{{data.t3.fee.index}}</b></span>
				</ve-col>
				<ve-col md6 v-if="data.t3.priceType.priceOp2&&data.t3.fee.startDate">
					<span>{{data.translations.feeStartDate[data.lang]}}; <b>{{data.t3.fee.startDate}}</b></span>
				</ve-col>
			</div>
		</div>
		<div class="no-page-break" v-if="data.missionType && data.missionType.accounting && data.t3">
			<div class="flex-columns">
				<ve-col md12 v-if="data.t3.assignmentPeriod">
					<span>{{data.translations.assignmentPeriod[data.lang]}}; <b>{{data.t3.assignmentPeriod}}</b></span>
				</ve-col>
				<ve-col md12 v-if="data.t3.noticePeriod">
					<span>{{data.translations.noticePeriod[data.lang]}}; <b>{{data.t3.noticePeriod}}</b></span>
				</ve-col>
				<ve-col md12 v-if="data.t3.treatment&&(data.t3.treatment.t3TreatmetOp1||data.t3.treatment.t3TreatmetOp2||data.t3.treatment.t3TreatmetOp3||data.t3.treatment.t3TreatmetOp4)">
					<span><b>{{data.translations.treatment[data.lang]}}; </b><br>
						<span v-if="data.t3.treatment.t3TreatmetOp1">- {{data.translations.t3TreatmetOp1[data.lang]}}<br></span>
						<span v-if="data.t3.treatment.t3TreatmetOp2">- {{data.translations.t3TreatmetOp2[data.lang]}}<br></span>
						<span v-if="data.t3.treatment.t3TreatmetOp3">- {{data.translations.t3TreatmetOp3[data.lang]}}<br></span>
						<span v-if="data.t3.treatment.t3TreatmetOp4">- {{data.translations.t3TreatmetOp4[data.lang]}}<br></span>
					</span><br>
					<p v-if="data.t3.otherTreatment">{{data.t3.otherTreatment}}</p>
				</ve-col>
			</div>
		</div>
		<div class="no-page-break" v-if="data.missionType && data.missionType.accounting && data.t3">
			<div class="flex-columns">
				<ve-col md12 v-if="data.t3.purpose">
					<b>{{data.translations.purposeHead[data.lang]}}; </b>
					<p>{{data.t3.purpose}}</p>
				</ve-col>
				<ve-col md12 v-if="data.t3.categories&&(data.t3.categories.t3CategoriesOp1||data.t3.categories.t3CategoriesOp2||data.t3.categories.t3CategoriesOp3||data.t3.categories.t3CategoriesOp4||data.t3.categories.t3CategoriesOp5||data.t3.categories.t3CategoriesOp6||data.t3.categories.t3CategoriesOp7||data.t3.categories.t3CategoriesOp8||data.t3.categories.t3CategoriesOp9)">
					<span><b>{{data.translations.categories[data.lang]}};</b> <br>
						<span v-if="data.t3.categories.t3CategoriesOp1">- {{data.translations.t3CategoriesOp1[data.lang]}}<br></span>
						<span v-if="data.t3.categories.t3CategoriesOp2">- {{data.translations.t3CategoriesOp2[data.lang]}}<br></span>
						<span v-if="data.t3.categories.t3CategoriesOp3">- {{data.translations.t3CategoriesOp3[data.lang]}}<br></span>
						<span v-if="data.t3.categories.t3CategoriesOp4">- {{data.translations.t3CategoriesOp4[data.lang]}}<br></span>
						<span v-if="data.t3.categories.t3CategoriesOp5">- {{data.translations.t3CategoriesOp5[data.lang]}}<br></span>
						<span v-if="data.t3.categories.t3CategoriesOp6">- {{data.translations.t3CategoriesOp6[data.lang]}}<br></span>
						<span v-if="data.t3.categories.t3CategoriesOp7">- {{data.translations.t3CategoriesOp7[data.lang]}}<br></span>
						<span v-if="data.t3.categories.t3CategoriesOp8">- {{data.translations.t3CategoriesOp8[data.lang]}}<br></span>
						<span v-if="data.t3.categories.t3CategoriesOp9">- {{data.translations.t3CategoriesOp9[data.lang]}}<br></span>
					
					</span><br>
					<p v-if="data.t3.otherCategories">{{data.t3.otherCategories}}</p>
				</ve-col>
			</div>
		</div>
		<div class="no-page-break" v-if="data.missionType && data.missionType.accounting && data.t3">
			<div class="flex-columns">
				<ve-col md12 v-if="data.t3.registrants">
					<b>{{data.translations.registrantsHead[data.lang]}}; </b>
					<p>{{data.t3.registrants}}</p>
				</ve-col>
				<ve-col md12 v-if="data.t3.recPersonel">
					<b>{{data.translations.recPersonel[data.lang]}}; </b>
					<p>{{data.t3.recPersonel}}</p>
				</ve-col>
				<ve-col md12 v-if="data.t3.technical">
					<b>{{data.translations.technical[data.lang]}}; </b>
					<p>{{data.t3.technical}}</p>
				</ve-col>
			</div>
		</div>-->
		<br>
    </div>
</template>

<script>

export default {
    name: "ve-preview-accounting",
    props: {
        data : {
            type: Object,
            default: () => { return {} }
        },
    },
    data() {
        return {
            validation_error: false
        }
    },
    methods: {
    },
    created() {
    }
}
</script>

<style lang="scss" scoped>
.flex-columns{display:-webkit-box;display:-moz-box;display:-ms-flexbox;display:-webkit-flex;display:flex;-webkit-box-direction:normal;-moz-box-direction:normal;-webkit-box-orient:horizontal;-moz-box-orient:horizontal;-webkit-flex-direction:row;-ms-flex-direction:row;flex-direction:row;-webkit-box-pack:stretch;-moz-box-pack:stretch;-webkit-justify-content:stretch;-ms-flex-pack:stretch;justify-content:stretch;-webkit-flex-wrap:wrap;-moz-flex-wrap:wrap;-ms-flex-wrap:wrap;flex-wrap:wrap;-webkit-flex-wrap:wrap;-moz-flex-wrap:wrap;-ms-flex-wrap:wrap;flex-wrap:wrap}.flex-columns .col{flex-grow:1;-webkit-flex-grow:1;-moz-flex-grow:1;-ms-flex-grow:1;background-color:#f3f3f3;padding:8px;border:1px solid #fff;box-sizing:border-box}.flex-columns .col-1{width:100%}.flex-columns .col-2{width:50%}.flex-columns .col-3{width:33.3%}.flex-columns .col-4{width:25%}.flex-columns .col-5{width:20%}.flex-columns .col-6{width:16.6%}.flex-columns .col-7{width:14.28%}.flex-columns .col-8{width:12.5%}.flex-columns .col-9{width:11.1%}.flex-columns .col-10{width:10%}.flex-columns.transparent .col{background-color:transparent;border-color:transparent}table{border-collapse:collapse}table,table td{border:1px solid #eee;background:transparent}table td{padding:8px 12px}table.stripped,table.stripped td{border:0}table.stripped td{border-bottom:1px solid #eee}.flex-columns{margin-left:1px}.flex-columns .col{border:1px solid #eee;background:transparent;padding:8px 12px;margin:-1px 0 0 -1px}.flex-columns.stripped .col{border-left:0;border-right:0}.flex-columns.transparent .col{border:0}
b{font-weight:bold!important;}
.myModal-content, .myModal-content label{
    font-size:12px!important;
}
</style>
